import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IFavoriteDict } from '@models/favorites/IFavoriteDict';
import { IFavoriteDoc } from '@models/favorites/IFavoriteDoc';

export class FavoritesService {
    static async documents(): Promise<AxiosResponse<IFavoriteDoc[]>> {
        return $api.get<IFavoriteDoc[]>(`/favorites/documents`);
    }

    static async dictionaries(): Promise<AxiosResponse<IFavoriteDict[]>> {
        return $api.get<IFavoriteDict[]>(`/favorites/dicts`);
    }

    static async checkIsFavoriteDocument(docId: string): Promise<AxiosResponse<boolean>> {
        return $api.get<boolean>(`/favorites/documents/${docId}`);
    }

    static async addDocumentToFavorites(docId: string): Promise<AxiosResponse<boolean>> {
        return $api.post(`/favorites/documents/${docId}`);
    }

    static async removeDocumentFromFavorites(docId: string): Promise<AxiosResponse<boolean>> {
        return $api.delete(`/favorites/documents/${docId}`);
    }

    static async checkIsFavoriteDictionary(dictId: string): Promise<AxiosResponse<boolean>> {
        return $api.get<boolean>(`/favorites/dicts/${dictId}`);
    }

    static async checkIsFavoriteFormDataDictionary(template: string): Promise<AxiosResponse<boolean>> {
        return $api.get<boolean>(`/favorites/formDataDicts/${template}`);
    }

    static async addDictionaryToFavorites(dictId: string): Promise<AxiosResponse<boolean>> {
        return $api.post(`/favorites/dicts/${dictId}`);
    }

    static async addFormDataDictionaryToFavorites(template: string): Promise<AxiosResponse<boolean>> {
        return $api.post(`/favorites/formDataDicts/${template}`);
    }

    static async removeDictionaryFromFavorites(dictId: string): Promise<AxiosResponse<boolean>> {
        return $api.delete(`/favorites/dicts/${dictId}`);
    }

    static async removeFormDataDictionaryFromFavorites(template: string): Promise<AxiosResponse<boolean>> {
        return $api.delete(`/favorites/formDataDicts/${template}`);
    }
}
