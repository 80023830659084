import React, { useEffect, useState } from 'react';

import './TableData.scss';
import { IDocumentTable } from '@models/Forms/IForms';
import { FormulaManager } from '@/utils/FormulaManager';
import { IFieldElem } from '@/models/IFormData';

export interface RowDeleteButtonProps {
    table: IDocumentTable;
    rowData: any;
    onDelete: (rowData: any) => void;
    docId?: string;
}

const RowDeleteButton: React.FC<RowDeleteButtonProps> = ({ table, rowData, onDelete, docId }: RowDeleteButtonProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(true);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const calcVisibliRules = async () => {
            if (table.singleRowDeleteButtonRules) {
                let formulaMgr = new FormulaManager(table.singleRowDeleteButtonRules);
                let formulaData: Record<string, IFieldElem> = {} as Record<string, IFieldElem>;
                for (let key in rowData) {
                    formulaData[key] = {
                        index: 0,
                        name: '',
                        value: rowData[key],
                    };
                }
                formulaMgr.Init(formulaData);
                let vis = await formulaMgr.EvalFormulaValues(true, false);
                setIsVisible(vis);
            }
        };
        calcVisibliRules();
    }, []);

    const onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onDelete(rowData);
    };

    return !isVisible ? (
        <></>
    ) : (
        <a
            href="#"
            className="dx-link dx-link-delete dx-icon-trash dx-link-icon"
            onClick={onClick}
            title="Удалить строку"
            aria-label="Удалить строку"
        />
    );
};

export default RowDeleteButton;
