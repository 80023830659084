import { WidgetsService } from '@services/WidgetsService';
import { WidgetsSlice } from '@/store/reducers/WidgetsReducer';
import { Layouts } from 'react-grid-layout';
import { IWidgetConfiguration } from '@models/widgets/widgetBuilders/IWidgetConfiguration';
import { WidgetBuilder } from '@models/widgets/widgetBuilders/WidgetBuilder';
import { IWidgetConf } from '@models/widgets/IWidgetConf';
import { IWidgetsData } from '@models/widgets/IWidgetsData';
import { sendErrorMsg } from '@molecules/Errors';
import { AppDispatch } from '@/store';

export const WidgetsActionCreators = {
    loadDashboard: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(WidgetsSlice.actions.setIsLoading(true));
            const response = await WidgetsService.getData();
            dispatch(WidgetsSlice.actions.successLoadWidgetsData(response.data));
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
            dispatch(WidgetsSlice.actions.setIsLoading(false));
        }
    },
    saveDashboard: (data: IWidgetsData) => async (dispatch: AppDispatch) => {
        try {
            dispatch(WidgetsSlice.actions.setIsLoading(true));
            const response = await WidgetsService.setData(data);
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
            dispatch(WidgetsSlice.actions.setIsLoading(false));
        }
    },

    setLayouts: (layouts: Layouts) => async (dispatch: AppDispatch) => {
        dispatch(WidgetsSlice.actions.setlayouts(layouts));
    },
    setDashboardIsEdit: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(WidgetsSlice.actions.setIsEdit(flag));
    },
    addNewWidget: (builderClassId: string, name: string) => async (dispatch: AppDispatch) => {
        const newWidgetId = WidgetBuilder.generateWidgetId(builderClassId);
        const conf = {
            widgetId: newWidgetId,
            configuration: { name: name } as IWidgetConfiguration,
        } as IWidgetConf;
        dispatch(WidgetsSlice.actions.addWidgets(conf));
    },
    removeWidget: (widgetId: string) => async (dispatch: AppDispatch) => {
        dispatch(WidgetsSlice.actions.removeWidgets(widgetId));
    },
    setWidgetConfiguration:
        (widgetId: string, widgetConfiguration: Record<string, any>) => async (dispatch: AppDispatch) => {
            const conf = {
                widgetId: widgetId,
                configuration: widgetConfiguration,
            } as IWidgetConf;
            dispatch(WidgetsSlice.actions.setWidgetConfiguration(conf));
        },
};
