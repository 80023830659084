import React, { useEffect, useState } from 'react';

interface IProps {
    data: any;
    column: any;
    editCellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
}

const EditCellRenderSwitcher: React.FC<IProps> = ({ data, column, editCellRenderSwitcher }) => {
    const activated = React.useRef(false);
    const [comp, setComp] = useState<React.ReactNode>(undefined);

    useEffect(() => {
        activated.current = true;

        editCellRenderSwitcher(data, column).then((value) => {
            if (activated.current) {
                setComp(value);
            }
        });

        return () => {
            activated.current = false;
        };
    }, []);

    return comp ?? <></>;
};

export default EditCellRenderSwitcher;
