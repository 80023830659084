import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IMainLayoutState {
    isMenuOpen: boolean;
}

const initialState: IMainLayoutState = {
    isMenuOpen: true,
};

export const MainLayoutSlice = createSlice({
    name: 'mainLayout',
    initialState,
    reducers: {
        toggleMenu: (state, action: PayloadAction<boolean>) => {
            state.isMenuOpen = action.payload;
        },
    },
});

export const { toggleMenu } = MainLayoutSlice.actions;

export default MainLayoutSlice.reducer;
