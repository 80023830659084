import { IDSAutoComplete } from '@models/Forms/IForms';
import { DictionariesService } from '@services/DictionariesService';
import { IOption } from '@/types';

export const getOptions = async (ds: IDSAutoComplete, text: string) => {
    let result: IOption[] = [];
    let params = '?skip=0&take=50';
    params += text != undefined && text != '' ? '&filter=["fields[0].value","contains","' + text + '"]' : '';

    let response = await DictionariesService.getGridItems(ds.dictionary.name!, params).then((response) => {
        return response.data.data;
    });
    let coll: string[] = [];

    response.forEach((item) => {
        let name =
            item.fields.find((x) => x.name === ds.dictionary.column) === undefined
                ? item.code
                : item.fields.find((x) => x.name === ds.dictionary.column)?.value;
        if (coll.indexOf(name as string) == -1) {
            result.push({
                label: name,
                value: name,
            } as IOption);
            coll.push(name as string);
        }
    });
    return result;
};
