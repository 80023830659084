import React, { forwardRef, ReactNode } from 'react';

import '../styles/iproc-scheme.css';

import DataGrid, { IDataGridOptions } from 'devextreme-react/data-grid';
import { initMessages } from '@utils/devextreme-react/dataGridUtils';

export interface IDevExpressDataGridOptions extends IDataGridOptions {
    children?: ReactNode;
}

const DevExpressDataGrid = forwardRef<DataGrid, IDevExpressDataGridOptions>(
    (
        {
            children,
            showBorders = true,
            showRowLines = false,
            rowAlternationEnabled = true,
            ...props
        }: IDevExpressDataGridOptions,
        ref: React.Ref<DataGrid>,
    ) => {
        initMessages();

        return (
            <DataGrid
                showBorders={showBorders}
                showRowLines={showRowLines}
                rowAlternationEnabled={rowAlternationEnabled}
                {...props}
                ref={ref}
            >
                {children}
            </DataGrid>
        );
    },
);

export default DevExpressDataGrid;
