import React, { useEffect, useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';
import { ISendToEtpModel } from '@models/document/actionsExecution/ISendToEtpModel';
import './SendToEtpExecutor.scss';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { useForm } from 'react-hook-form';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import InputControl from '@controls/InputControl/InputControl';
import { ModalSize } from '@atoms/Modal/Modal';

export class SendToEtpExecutor extends DocBaseActionExecutor {
    private _modalProps?: ISendToEtpExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            message: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <SendToEtpExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ISendToEtpExecutorProps {
    docId: string;
    actionKey: string;
    message: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const SendToEtpExecutorModal: React.FC<ISendToEtpExecutorProps> = (props: ISendToEtpExecutorProps) => {
    const [loading, setLoading] = useState<boolean>();
    const [success, setSuccess] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>();
    const [successText, setSuccessText] = useState<string>();
    const [etpOrgCodeRequired, setEtpOrgCodeRequired] = useState<boolean>();
    const [authRequired, setAuthRequired] = useState<boolean>();
    const formState = useForm<ISendToEtpFormData>({
        mode: 'onBlur', // "onChange"
    });

    const service = new DocumentExecutionService(props.docId);

    useEffect(() => {
        // Модель данных
        setLoading(true);
        service
            .getSendToEtpModel(props.actionKey)
            .then((result) => {
                if (result.data) {
                    setEtpOrgCodeRequired(result.data.etpOrgCodeRequired);
                    setAuthRequired(result.data.authRequired);
                    formState.reset({
                        login: result.data.login,
                        password: result.data.password,
                        etpOrgCode: result.data.etpOrgCode,
                    } as ISendToEtpFormData);
                }
            })
            .catch((error) => setErrorText(error))
            .finally(() => setLoading(false));
    }, []);

    const onSubmit = (form: ISendToEtpFormData) => {
        // Отправить данные
        setLoading(true);
        setErrorText('');
        setSuccessText('');
        let sendToEtpData: ISendToEtpModel = {
            login: form.login,
            password: form.password,
            etpOrgCode: form.etpOrgCode,
        };

        return service
            .sendToEtp(props.actionKey, sendToEtpData)
            .then((dto) => {
                setSuccessText(dto.data);
                setSuccess(true);
                //props.completeHandler && props.completeHandler(true);
            })
            .catch((error) => setErrorText(error))
            .finally(() => setLoading(false));
    };

    const modalContainerProps: IModalContainerProps = {
        header: props.message,
        size: props.modalSize,
        showOkButton: !success,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(success);
        },
        errorText: errorText,
        successText: successText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
        isBusy: loading,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            {!success && etpOrgCodeRequired && (
                <InputControl
                    label="Код организации"
                    name="etpOrgCode"
                    required={etpOrgCodeRequired}
                    formState={formState.formState}
                    control={formState.control}
                />
            )}

            {!success && authRequired && (
                <InputControl
                    label="Логин"
                    name="login"
                    autoComplete="off"
                    required={authRequired}
                    formState={formState.formState}
                    control={formState.control}
                />
            )}

            {!success && authRequired && (
                <InputControl
                    label="Пароль"
                    name="password"
                    autoComplete="off"
                    type="password"
                    required={authRequired}
                    formState={formState.formState}
                    control={formState.control}
                />
            )}
        </ModalContainer>
    );
};

interface ISendToEtpFormData {
    login: string;
    password: string;
    etpOrgCode: string;
}
