import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Layouts } from 'react-grid-layout';
import { IWidgetsData } from '@models/widgets/IWidgetsData';
import { IWidgetConf } from '@models/widgets/IWidgetConf';

export interface IWidgetsState {
    isEdit: boolean;
    isLoading: boolean;
    data: IWidgetsData;
}

const initialState: IWidgetsState = {
    isEdit: false,
    isLoading: true,
    data: {
        widgetIds: [] as string[],
        layouts: {},
        widgetConfigurations: {},
    } as IWidgetsData,
};

export const WidgetsSlice = createSlice({
    name: 'widgets',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsEdit: (state, action: PayloadAction<boolean>) => {
            state.isEdit = action.payload;
        },
        successLoadWidgetsData: (state, action: PayloadAction<IWidgetsData>) => {
            state.data = action.payload;
        },
        setlayouts: (state, action: PayloadAction<Layouts>) => {
            state.data.layouts = action.payload;
        },
        addWidgets: (state, action: PayloadAction<IWidgetConf>) => {
            state.data.widgetIds.push(action.payload.widgetId);
            state.data.widgetConfigurations[action.payload.widgetId] = action.payload.configuration;
        },
        removeWidgets: (state, action: PayloadAction<string>) => {
            state.data.widgetIds = state.data.widgetIds.filter((widgetId) => widgetId !== action.payload);
            delete state.data.widgetConfigurations[action.payload];
        },
        setWidgetConfiguration: (state, action: PayloadAction<IWidgetConf>) => {
            state.data.widgetConfigurations[action.payload.widgetId] = action.payload.configuration;
        },
    },
});

export const {
    setIsLoading,
    setIsEdit,
    setlayouts,
    addWidgets,
    successLoadWidgetsData,
    removeWidgets,
    setWidgetConfiguration,
} = WidgetsSlice.actions;

export default WidgetsSlice.reducer;
