import './PDFIFrameRenderer.scss';
import React from 'react';
import { DocRenderer } from '@cyntler/react-doc-viewer';
import FatalError from '@molecules/FatalError';
import FatalErrorIcon from '@molecules/FatalError/FatalErrorIcon';

const PDFIFrameRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
    if (!currentDocument)
        return (
            <FatalError
                icon={<FatalErrorIcon variant="server" />}
                title="Не удалось загрузить PDF файл на просмотр"
                description={''}
            />
        );

    return (
        <div className="pdf-renderer-iframe">
            <iframe src={currentDocument.uri} title={currentDocument.fileName} width="100%" height="100%"></iframe>
        </div>
    );
};

PDFIFrameRenderer.fileTypes = ['pdf', 'application/pdf'];
PDFIFrameRenderer.weight = 1;

export default PDFIFrameRenderer;
