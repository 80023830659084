import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IResetPasswordRequestData } from '@organisms/ResetPasswordRequestForm/ResetPasswordRequestForm';
import { IResetPasswordConfirmData } from '@organisms/ResetPasswordConfirmForm/ResetPasswordConfirmForm';
import { ISignUpData } from '@organisms/SignUpForm/SignUpForm';
import { ISignUpConfirmData } from '@organisms/SignUpConfirmForm/SignUpConfirmForm';
import { IAuthResponse } from '@models/response/IAuthResponse';
import { IChangeEmailConfirmData } from '@organisms/ChangeEmailConfirmForm/ChangeEmailConfirmForm';
import { ISignUpSettings } from '@pages/signUp/SignUp';

export class AuthService {
    static async login(username: string, password: string): Promise<AxiosResponse<IAuthResponse>> {
        return $api.post<IAuthResponse>(`/Account/SignIn`, { username: username, password: password });
    }

    static async loginByHeader(): Promise<AxiosResponse<IAuthResponse>> {
        return $api.post<IAuthResponse>(`/Account/SignInByHeader`);
    }

    static async refreshToken(refreshToken?: string | null): Promise<AxiosResponse<IAuthResponse>> {
        return $api.post<IAuthResponse>(`/Account/RefreshToken`, { refreshToken: refreshToken });
    }

    static async logout(): Promise<void> {
        return $api.post(`/Account/SignOut`);
    }

    static async requestPasswordReset(formData: IResetPasswordRequestData): Promise<AxiosResponse<boolean>> {
        return $api.post(`/Account/RequestPasswordReset`, formData);
    }

    static async confirmPasswordReset(formData: IResetPasswordConfirmData): Promise<AxiosResponse> {
        return $api.post(`/Account/ConfirmPasswordReset`, formData);
    }

    static async confirmSignUp(formData: ISignUpConfirmData): Promise<AxiosResponse<string>> {
        return $api.post(`/Account/ConfirmSignUp`, formData);
    }

    static async confirmChangeEmail(formData: IChangeEmailConfirmData): Promise<AxiosResponse<string>> {
        return $api.post(`/Account/ConfirmChangeEmail`, formData);
    }

    static async fetchSignUpSettings(controller: AbortController): Promise<AxiosResponse<ISignUpSettings>> {
        return $api.get(`/Account/SignUpSettings`, { signal: controller.signal });
    }

    static async signUp(formData: Omit<ISignUpData, 'organization' | 'foreignOrganization'>): Promise<AxiosResponse> {
        return $api.post(`/Account/SignUp`, formData);
    }

    static async checkOrganizationExists(inn?: string, kpp?: string): Promise<AxiosResponse<boolean>> {
        return $api.post(`/Account/OrganizationExists`, { inn: inn, kpp: kpp });
    }

    static async checkForeignOrganizationExists(tin?: string): Promise<AxiosResponse<boolean>> {
        return $api.post(`/Account/ForeignOrganizationExists`, { tin: tin });
    }

    static async checkResetPasswordConfirmLink(actionKey?: string, login?: string): Promise<AxiosResponse<boolean>> {
        return $api.post(`/Account/CheckResetPasswordConfirmLink`, { actionKey: actionKey, login: login });
    }

    static async checkSignUpConfirmLink(actionKey?: string, login?: string): Promise<AxiosResponse<boolean>> {
        return $api.post(`/Account/CheckSignUpConfirmLink`, { actionKey: actionKey, login: login });
    }

    static async checkChangeEmailConfirmLink(actionKey?: string, login?: string): Promise<AxiosResponse<boolean>> {
        return $api.post(`/Account/CheckChangeEmailConfirmLink`, { actionKey: actionKey, login: login });
    }
}
