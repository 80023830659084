import './CustomComponent.scss';
import { UseFormReturn } from 'react-hook-form';
import { ICustomComponent } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import React, { useEffect } from 'react';
import { CustomComponentTypes } from '@models/Forms/CustomComponentTypes';
import WinnerSelectionTableDemand from './components/WinnerSelectionTableDemand/WinnerSelectionTableDemand';
import WinnerSelectionTablePositions from './components/WinnerSelectionTablePositions/WinnerSelectionTablePositions';
import WinnerSelectionTableKO from './components/WinnerSelectionTableKO/WinnerSelectionTableKO';

export interface ICustomComponentProps<TFieldValues extends object = object> {
    component: ICustomComponent;
    formMethods: UseFormReturn<TFieldValues>;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    docId: string;
}

const CustomComponent = <TFieldValues extends object = object>({ ...props }: ICustomComponentProps<TFieldValues>) => {
    const activated = React.useRef(false);

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    const renderComponent = () => {
        switch (props.component.type) {
            case CustomComponentTypes.Unknown:
                return 'Неизвестный тип компонента';
            case CustomComponentTypes.WinnerSelectionTableDemand:
                return <WinnerSelectionTableDemand {...props} />;
            case CustomComponentTypes.WinnerSelectionTableLot:
                return 'Кастомное представление выбора победителя. Полотовое представление';
            case CustomComponentTypes.WinnerSelectionTablePositions:
                return <WinnerSelectionTablePositions {...props} />;
            case CustomComponentTypes.WinnerSelectionTableMixed:
                return 'Кастомное представление выбора победителя. Смешанное представление';
            case CustomComponentTypes.WinnerSelectionTableKO:
                return <WinnerSelectionTableKO {...props} />;
            default:
                return 'Неизвестный тип компонента';
        }
    };

    return (
        <div
            className={'custom-component'}
            data-testid={props.component.id ? `custom-component-${props.component.id}` : undefined}
        >
            {renderComponent()}
        </div>
    );
};

export default CustomComponent;
