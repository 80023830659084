import React, { forwardRef, ReactNode } from 'react';

import '../styles/iproc-scheme.css';

import TreeList, { ITreeListOptions } from 'devextreme-react/tree-list';
import { initMessages } from '@utils/devextreme-react/dataGridUtils';

export interface IDevExpressTreeListOptions extends ITreeListOptions {
    children?: ReactNode;
}
const DevExpressTreeList = forwardRef<TreeList, IDevExpressTreeListOptions>(
    ({ children, rowAlternationEnabled = true, ...props }: IDevExpressTreeListOptions, ref: React.Ref<TreeList>) => {
        initMessages();

        return (
            <TreeList rowAlternationEnabled={rowAlternationEnabled} {...props} ref={ref}>
                {children}
            </TreeList>
        );
    },
);

export default DevExpressTreeList;
