import React, { ReactNode } from 'react';
import { IControlProps, IPartialFormGroup } from '@controls/types';
import FormControl from '../FormControl';
import InputNumber, { IInputNumberProps } from '@atoms/InputNumber/InputNumber';
import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';

export type IInputNumberControlProps = IInputNumberProps &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        tooltip?: ReactNode;
        required?: boolean;
    };

const InputNumberControl = ({
    name,
    rules,
    defaultValue,
    control,
    formState,
    shouldUnregister,
    label,
    tooltip,
    required,
    formGroupProps,
    ...props
}: IInputNumberControlProps) => {
    const Controller = useHookFormController();

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ...fieldProps } }) => (
                <FormControl
                    name={name}
                    label={label}
                    tooltip={tooltip}
                    required={required}
                    formState={formState}
                    {...formGroupProps}
                >
                    <InputNumber
                        {...fieldProps}
                        {...props}
                        defaultValue={value}
                        onInputChange={(val) => {
                            if (
                                value === undefined ||
                                value === null ||
                                value === 0 ||
                                value === '0' ||
                                value.toString() != (+val.replace(/\s/g, '')).toString()
                            ) {
                                let valResult = val == '' ? null : +val.replace(/\s/g, '');
                                onChange(valResult);
                                if (props.onValueChange) {
                                    props.onValueChange(valResult);
                                }
                            }
                        }}
                    />
                </FormControl>
            )}
        />
    );
};

export default InputNumberControl;
