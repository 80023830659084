import './ColoredLine.scss';
import { HTMLProps } from 'react';

export interface IColoredLineProps extends HTMLProps<HTMLElement> {
    color?: string;
    backgroundColor?: string;
    height?: number | string;
}

const ColoredLine: React.FC<IColoredLineProps> = ({ color = '#eee', height = '1px', ...props }: IColoredLineProps) => {
    return (
        <hr
            className={props.className}
            style={{
                color: color,
                backgroundColor: color,
                height: height,
                border: 0,
                width: '100%',
            }}
        />
    );
};

export default ColoredLine;
