import React, { useEffect, useState } from 'react';
import Select, { ISelectProps } from '@atoms/Select/Select';
import { IOption } from '@/types';

export type IAutoCompletePickerProps = Omit<ISelectProps, 'onChange' | 'values' | 'options'> & {
    onInputValueChanged?: (value: string) => void;
    findOptions: (value: string) => Promise<IOption[]>;
    value?: string;
};

const AutoCompletePicker = ({ onInputValueChanged, findOptions, value, ...props }: IAutoCompletePickerProps) => {
    const activated = React.useRef(false);
    const defVal = value ? value : '';
    const [options, setOptions] = useState<IOption[]>([]);

    const loadOptions = async (text: string) => {
        let opts = await findOptions(text);
        if (activated.current) {
            if (opts.length > 0 && opts[0].label !== null) {
                setOptions(opts);
            }
        }
    };

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    return (
        <Select
            {...props}
            options={options}
            defInputValue={defVal}
            debounceTime={300}
            clearOnSelect={false}
            debouncedInput={(val: string) => {
                if (value === undefined || value === null || value.toString() != val.toString()) {
                    loadOptions(val);
                }
            }}
            onInputValueChanged={onInputValueChanged}
        />
    );
};

export default AutoCompletePicker;
