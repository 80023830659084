import { Column, FilterRow, Paging, Scrolling, Selection } from 'devextreme-react/data-grid';
import React, { FC, RefObject, useImperativeHandle, useMemo, useRef } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import { AddressBookService } from '@services/AddressBookService';
import { IAbookTab } from '@models/Forms/IForms';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import DevExpressTreeList from '@atoms/DevExpress/TreeList/DevExpressTreeList';
import { RemoteOperations, TreeList } from 'devextreme-react/tree-list';
import {
    EditorPreparedEvent,
    EditorPreparingEvent,
    InitializedEvent as TreeInitializedEvent
} from 'devextreme/ui/tree_list';
import { simulateMouseClick } from '@utils/helpers';
import { IAbookSettings } from '../AbookpickerModal';
import { IAbookpickerRefActions } from '@atoms/Abookpicker/Abookpicker';
import { onCellHoverChanged } from '@utils/dataGridUtils';

export interface ITreeModeControlProp extends Omit<IAbookSettings, 'showChips'> {
    currentTab: IAbookTab;
    onSelectedRowItems: (value: IAddressBookData[]) => void;
    onSelectedKeys: (value: number[]) => void;
    getParams: (options: any) => string;
    controlRef: RefObject<IAbookpickerRefActions>;
}

const TreeModeControl: FC<ITreeModeControlProp> = (p: ITreeModeControlProp) => {
    const treeRef = useRef<TreeList>(null);

    useImperativeHandle(p.controlRef, () => ({
        reset: () => {
            treeRef.current?.instance.refresh();
        },
        unSelect: (item: IAddressBookData) => {
            const keys = treeRef.current?.instance.getSelectedRowKeys();
            if (keys) {
                const index = keys.indexOf(item.key);
                if (index > -1) {
                    keys.splice(index, 1);
                }
                treeRef.current?.instance.selectRows(keys, false);
            }
        },
    }));

    const resetTreeControlState = () => {
        treeRef?.current?.instance.state({});
    };

    const resetControlsState = () => {
        resetTreeControlState();
    };

    const treeStore = useMemo(() => {
        resetControlsState();
        return new CustomStore({
            key: 'id',
            load(options: any) {
                return AddressBookService.fetchTreeData(
                    p.currentTab.cache,
                    options.parentIds ?? 0,
                    p.selected,
                    p.getParams(options),
                ).then((response) => {
                    return response.data;
                });
            },
        });
    }, [p.currentTab]);

    const checkIsRowSelectable = (data?: IAddressBookData) => {
        return /* !data?.hasItems && */ data?.canBeSelected;
    };

    const onTreeInitialized = (e: TreeInitializedEvent<IAddressBookData, string>) => {
        if (p.selected) {
            e.component?.selectRows(
                p.selected.map((key: number) => key.toString()),
                true,
            );
        }
    };

    const onTreeEditorPreparing = (e: EditorPreparingEvent<IAddressBookData, string>) => {
        if (e.parentType === 'filterRow') {
            e.editorOptions.onEnterKey = function () {
                // применение фильтра по нажатию Enter
                simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
            };
        }
        if (e.parentType === 'dataRow') {
            e.editorOptions.disabled = !checkIsRowSelectable(e.row?.data);;
        }
    };

    const onTreeEditorPrepared = (e: EditorPreparedEvent<IAddressBookData, string>) => {
        if (e.parentType === 'dataRow') {
            let keys = e.component.getSelectedRowKeys();
            if (keys.some((key) => key == e.row?.data.key.toString()) && !e.component.isRowSelected(e.row?.data.id!)) {
                e.component.selectRows([e.row?.data.id!], true);
                e.component.deselectRows([e.row?.data.key.toString()!]);
            }
        }
    };

    const onSelectionChanged = (e: any) => {
        if (!p.isMultiple) {
            if (e.currentSelectedRowKeys.length > 0) {
                let data = e.selectedRowsData.pop();
                let key = e.selectedRowKeys.pop();
                e.component.deselectRows(e.selectedRowKeys);
                p.onSelectedKeys([key]);
                p.onSelectedRowItems([data]);
            } else if (e.selectedRowKeys.length == 0) {
                p.onSelectedKeys([]);
                p.onSelectedRowItems([]);
            }
        } else {
            p.onSelectedKeys(e.selectedRowKeys!);
            p.onSelectedRowItems(e.selectedRowsData);
        }
    };

    const onRowClick = (e: any) => {
        if (checkIsRowSelectable(e.data)) {
            if (e.component.getSelectedRowKeys().indexOf(e.key) > -1) {
                e.component.deselectRows([e.key]);
            } else {
                e.component.selectRows([e.key], true);
            }
        }
    };

    const treeListControl = (
        <DevExpressTreeList
            key="abookTree"
            dataSource={treeStore}
            columnResizingMode="widget"
            columnAutoWidth={true}
            columnMinWidth={30}
            noDataText={'Нет строк'}
            ref={treeRef}
            onInitialized={onTreeInitialized}
            onEditorPreparing={onTreeEditorPreparing}
            onEditorPrepared={onTreeEditorPrepared}
            onSelectionChanged={onSelectionChanged}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            parentIdExpr="parentId"
            hasItemsExpr="hasItems"
            keyExpr="id"
            rootValue="0"
            onRowClick={onRowClick}
            onCellHoverChanged={onCellHoverChanged}
        >
            <Selection mode="multiple" allowSelectAll={p.isMultiple} selectAllMode="page" />

            {p.currentTab.columns &&
                p.currentTab.columns.map((column, index) => {
                    return (
                        <Column
                            caption={column.name}
                            key={index}
                            dataField={column.key}
                            width={column.width}
                            allowFiltering={column.name === 'Наименование'}
                        />
                    );
                })}

            <FilterRow showOperationChooser={false} visible={false} applyFilter={'onClick'} />
            <RemoteOperations filtering={true} />
            <Scrolling mode="virtual" />
            <Paging enabled={true} defaultPageSize={10} />
        </DevExpressTreeList>
    );

    return treeListControl;
};

export default TreeModeControl;
