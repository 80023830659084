import React from 'react';
import { useTypedSelector } from '@hooks/useTypedSelector';
import Avatar from '@atoms/Avatar';
import './UserPanel.scss';
import { MdEdit } from 'react-icons/md';

interface IUserPanelProps {
    onOrgChange: () => void;
}

const UserPanel: React.FC<IUserPanelProps> = (props: IUserPanelProps) => {
    const user = useTypedSelector((state) => state.auth.user);
    const userAvatar = useTypedSelector((state) => state.userAvatar);

    return (
        <div className="user-info">
            <div className="user-info-main">
                {userAvatar.photoContent ? (
                    <div className="user-photo">
                        <Avatar photo={`data:image/png;base64,${userAvatar.photoContent}`} size="xl" />
                    </div>
                ) : null}
                <div className="user-fio">
                    {`${user.firstName}` + (user.middleName ? ` ${user.middleName}` : '') + ` ${user.lastName}`}
                </div>
            </div>

            <div className="user-info-container">
                <ul className="dottet-ul">
                    <li className="user-info-item">
                        {user.canUserDepartmentView && user.departmentId && (
                            <div className="user-department">
                                <div className="user-dep-name" title={user.department}>
                                    {user.department}
                                </div>
                                {user.canUserDepartmentEdit && (
                                    <div className="user-dep-edit" title="Сменить организацию">
                                        <MdEdit
                                            className="edit-dep-btn"
                                            onClick={async () => {
                                                props.onOrgChange();
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </li>
                    {user.phone && <li className="user-info-item">{user.phone}</li>}
                    {user.email && <li className="user-info-item">{user.email}</li>}
                    {user.subdivision && <li className="user-info-item">{user.subdivision}</li>}
                    {user.position && <li className="user-info-item">{user.position}</li>}
                </ul>
            </div>
        </div>
    );
};

export default UserPanel;
