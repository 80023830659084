import { UseFormReturn, useWatch } from 'react-hook-form';
import { IDocumentAbook, ISetValue } from '@models/Forms/IForms';
import { handlerFieldDisplayWatch, handlerFieldWatch, parseDisplayFormat } from '@utils/documentUtils';
import { AbookpickerControl } from '@controls/index';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import './Abook.scss';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import { ChangesFieldValue, clearTables, GetValueForSetValue } from '@utils/ChangesManager';
import ToolTipTitle from '@atoms/ToolTipTitle/ToolTipTitle';
import { IPartialFormGroup } from '@controls/types';
import AbookChipItem from '@molecules/formbuilder/controls/Abook/AbookChipItem/AbookChipItem';

export interface IAbookProps<TFieldValues extends object = object> {
    abook?: IDocumentAbook;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: string[]) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
}

const Abook = <TFieldValues extends object = object>({
    abook,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    includedFields,
    ...props
}: IAbookProps<TFieldValues>) => {
    let visibilityRules = abook?.visibilityRules;
    let readOnlyRules = abook?.readonly;

    let requiredRules = abook?.required;
    const visibilityMng = new FormulaManager(visibilityRules!);
    const readOnlyMng = new FormulaManager(readOnlyRules!);
    const requiredMng = new FormulaManager(requiredRules!);
    const displayFormatMng = new FormulaManager(abook?.displayFormat!);
    visibilityMng.Init(fields, formMethods);
    readOnlyMng.Init(fields, formMethods);
    requiredMng.Init(fields, formMethods);
    displayFormatMng.Init(fields, formMethods);
    const activated = React.useRef(false);
    const watchDataDisplayFormat = React.useRef<any[]>([]);
    const [visibility, setVisibility] = useState<boolean>(false);
    const [required, setRequired] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [displayVal, setDisplayVal] = useState<string>('');
    const InitFormulas = async () => {
        let vis = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(vis);
        }

        if (requiredRules) {
            let req = await requiredMng.EvalFormulaValues(isEdit, isNew);
            if (activated.current) {
                setRequired(req);
            }
        }

        if (readOnlyRules) {
            let readonly = await readOnlyMng.EvalFormulaValues(isEdit, isNew);
            if (activated.current) {
                setReadOnly(readonly);
            }
        }

        if (isNew && abook?.invokeChangesOnNew && vis) {
            let curValue = fields[abook.key];
            await ChangesFieldValue(
                abook?.changes!,
                curValue.value,
                abook?.key!,
                fields,
                isEdit,
                isNew,
                onSaveField,
                setError,
                undefined,
                undefined,
                formMethods,
            );
        }
    };

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    useEffect(() => {
        ResetDisplayValue();
    }, [isNew, isEdit]);

    const ResetDisplayValue = async () => {
        let display = abook?.displayFormat;
        if (display?.indexOf(abook?.key!)) {
            if (abook?.source?.table) {
                let columnName = display
                    .match(/\[.*?\]/g)
                    ?.values()
                    ?.next()?.value as string;
                columnName = columnName.replace('[', '').replace(']', '');
                let fname = ('fields.[' + idField + '].value') as any;
                let val = formMethods.getValues(fname);
                display = (val as any[]).map((item) => item[columnName]).join(', ');
            } else {
                let fname = ('fields.[' + idField + '].value') as any;
                let val = formMethods.getValues(fname);
                let fieldVal = '{' + abook?.key + '}';
                var source = new RegExp(fieldVal.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
                display = display.replace(source, val as any);
            }
        }
        display = parseDisplayFormat(display, fields).text;

        if (activated.current) {
            setDisplayVal(display ? display : '');
        }
    };

    const watchVisibility = useWatch({
        name: visibilityMng.GetWatchFields(),
    });
    const watchReadOnly = useWatch({
        name: readOnlyMng.GetWatchFields(),
    });
    const watchRequired = useWatch({
        name: requiredMng.GetWatchFields(),
    });
    const watchDisplayFormat = useWatch({
        name: displayFormatMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watchVisibility, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watchVisibility, isEdit, isNew]);
    useEffect(() => {
        if (readOnlyRules) {
            handlerFieldWatch(watchReadOnly, isEdit, isNew, readOnlyMng, setReadOnly, activated);
        }
    }, [watchReadOnly, isEdit, isNew]);

    useEffect(() => {
        if (requiredRules) {
            handlerFieldWatch(watchRequired, isEdit, isNew, requiredMng, setRequired, activated);
        }
    }, [watchRequired, isEdit, isNew]);

    const handlerDisplayVal = async (watch: any[]) => {
        let dataDisplay = await handlerFieldDisplayWatch(watch, isEdit, isNew, displayFormatMng);
        if (activated.current && watchDataDisplayFormat.current == dataDisplay?.watch) {
            setDisplayVal(dataDisplay?.result);
        }
    };
    useEffect(() => {
        watchDataDisplayFormat.current = watchDisplayFormat;
        handlerDisplayVal(watchDisplayFormat);
    }, [watchDisplayFormat]);

    const dataField = fields[abook?.key!];
    const idField = dataField?.index;
    includedFields.push(idField);
    useEffect(() => {
        if (
            !required &&
            formMethods.formState.errors &&
            (formMethods.formState.errors as any).fields &&
            (formMethods.formState.errors as any).fields[idField]
        ) {
            formMethods.clearErrors(dataField?.name as any);
        }
    }, [required]);
    const onSetValues = (value: IAddressBookData[]) => {
        //TODO убрать всё это и сделать чтобы работало по основной логике через ченджМэнеджер
        let display = abook?.displayFormat;
        abook?.setValues.sets.forEach((item) => {
            let field = fields[item.key];
            let val = getValues(value, item.attr, item.separator ? item.separator : '|') as any;
            if (display?.indexOf(item.key)) {
                let fieldVal = '{' + item.key + '}';
                var source = new RegExp(fieldVal.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
                display = display.replace(source, val);
            }
            let fname = ('fields.[' + field.index + '].value') as any;
            formMethods.setValue(fname, val, { shouldDirty: true });
        });
        clearTables(abook?.setValues?.clearTables!, formMethods, fields);
        if (display?.indexOf(abook?.key!)) {
            if (abook?.source?.table) {
                let columnName = display
                    .match(/\[.*?\]/g)
                    ?.values()
                    ?.next()?.value as string;
                columnName = columnName.replace('[', '').replace(']', '');
                let fname = ('fields.[' + idField + '].value') as any;
                let val = formMethods.getValues(fname);
                display = (val as any[]).map((item) => item[columnName]).join(', ');
            } else {
                let fname = ('fields.[' + idField + '].value') as any;
                let val = formMethods.getValues(fname);
                let fieldVal = '{' + abook?.key + '}';
                var source = new RegExp(fieldVal.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
                display = display.replace(source, val as any);
            }
        }
        display = parseDisplayFormat(display, fields).text;
        setDisplayVal(display!);
    };

    const getValues = (values: IAddressBookData[], attr: string, separator: string = '|') => {
        let result = [] as string[];

        values.forEach((item) => {
            type ObjectKey = keyof typeof item;
            const attrNAme = attr as ObjectKey;
            let elem = item[attrNAme];
            // TODO Check null
            result.push(elem?.toString()); //elem?.value?.toString()!);
        });
        return result.join(separator);
    };

    const getFormValues = () => {
        let formdataParams = abook?.formValues;
        const map = {} as Record<string, any>;
        if (formdataParams && formdataParams != null) {
            for (const ele of formdataParams?.formValue!) {
                let field = fields[ele.attr];
                let fname = ('fields.[' + field.index + '].value') as any;
                let val = formMethods.getValues(fname);
                if (ele.column) {
                    if (ele.function === '{join}') {
                        map[ele.key] = (val as [])?.map((item: any) => `'${item[ele.column]}'`).join(',') ?? val;
                    } else {
                        map[ele.key] = (val as [])?.map((item: any) => item[ele.column]) ?? val;
                    }
                } else {
                    map[ele.key] = val;
                }
            }
        }
        return JSON.stringify(map);
    };

    /**
     * Возвращает сепаратор для склеивания элементов
     * @param multilineDisplay Флаг отображения элементов в новой строке
     * @returns
     */
    const getJoinSeparator = (multilineDisplay?: boolean) => {
        return multilineDisplay ? '\r\n' : ', ';
    };

    const onSaveField = async (item: ISetValue, rowData?: any) => {
        let val = await GetValueForSetValue(item, undefined, fields, rowData, formMethods);
        let field = fields[item.key];
        formMethods.setValue(field.name as any, val, { shouldDirty: true });
    };

    const onChangeDictValue = async (value?: any) => {
        await ChangesFieldValue(
            abook?.changes!,
            value,
            abook?.key!,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            undefined,
            undefined,
            formMethods,
        );
    };

    return abook && abook.key && visibility && idField != undefined ? (
        <div className="form-field" data-testid={abook.id ? `abook-${abook.id}` : undefined}>
            {isEdit || isNew ? (
                <AbookpickerControl
                    label={abook?.name}
                    tooltip={<ToolTipTitle title={abook?.title} />}
                    formGroupProps={{ verticalBottomAlign: abook?.verticalBottomAlign } as IPartialFormGroup}
                    isMultiple={abook?.isMultiple}
                    setValues={abook?.setValues}
                    externalSearch={abook?.externalSearch}
                    formValues={getFormValues()}
                    getFormValues={getFormValues}
                    title={abook?.name}
                    required={required}
                    rules={{
                        required: {
                            value: required ? required : false,
                            message: 'Обязательное поле',
                        },
                    }}
                    readOnly={readOnly}
                    diplayValue={displayVal}
                    gridAttribute={abook?.gridAttribute}
                    name={`fields.[${idField}].value`}
                    formState={formMethods.formState}
                    onSetValues={onSetValues}
                    onAbookValueChanged={onChangeDictValue}
                    control={formMethods.control}
                    tabsSettings={abook?.tabs}
                    source={abook?.source}
                    showChips={abook?.showChips}
                    placeholder={abook?.placeholder ? abook.placeholder : undefined}
                />
            ) : (
                <FieldWrapper
                    inLineFormat={abook.isValue}
                    lable={abook.name}
                    wrapNewLines={abook?.multilineDisplayOnView}
                    title={abook.viewTitle}
                >
                    {abook.chipsView ? (
                        <div className="abook-chips-items">
                            {parseDisplayFormat(
                                abook?.displayFormat,
                                fields,
                                getJoinSeparator(abook?.multilineDisplayOnView),
                            ).values.map((item: string, index: number) => (
                                <AbookChipItem
                                    key={index}
                                    item={item}
                                    showRemoveIcon={false}
                                    /*onRemove={() => {}}*/
                                />
                            ))}
                        </div>
                    ) : (
                        parseDisplayFormat(
                            abook?.displayFormat,
                            fields,
                            getJoinSeparator(abook?.multilineDisplayOnView),
                        ).text
                    )}
                </FieldWrapper>
            )}
        </div>
    ) : (
        <></>
    );
};

export default Abook;
