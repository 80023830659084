import './OfferCustomForm.scss';
import React, { useEffect, useState } from 'react';
import { IOffer, IOfferAction } from '@models/winnerSelection/ITableDemand';
import ModalFormBuilder, { IModalFormBuilderProps } from '@molecules/ModalFormBuilder/ModalFormBuilder';
import Preloader from '@atoms/Preloader';
import { IForms } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { useForm } from 'react-hook-form';
import { IDocumentData } from '@models/document/IDocumentData';
import { convertListToMap } from '@utils/documentUtils';
import { DocumentService } from '@services/DocumentService';
import { WinnerSelectionService } from '@services/WinnerSelectionService';

export interface IOfferCustomFormProps {
    tableKey: string;
    value: IOffer;
    action: IOfferAction;
    demandId: string;
    onClose?: () => void;
}

const OfferCustomForm = ({ tableKey, demandId, value, action, onClose, ...props }: IOfferCustomFormProps) => {
    const [loading, setLoading] = useState<boolean>();
    const [errorText, setErrorText] = useState<string>();
    const [form, setForm] = useState<IForms>();
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();

    const methods = useForm<IDocumentData>({
        mode: 'onBlur', // "onChange"
    });

    useEffect(() => {
        setLoading(true);
        // Получение схемы формы
        const schemeResult = WinnerSelectionService.fetchCustomFormScheme(tableKey, action.key)
            .then((result) => {
                let form = result.data;
                setForm(form);
            })
            .catch((error) => setErrorText(error));

        // Получение данных документа
        const dataResult = DocumentService.getData(value.key)
            .then((result) => {
                if (result.data) {
                    let f = result.data.fields;
                    let fields = convertListToMap(f);
                    setFieldsData(fields);
                    methods.reset(result.data);
                }
            })
            .catch((error) => setErrorText(error));

        Promise.all([schemeResult, dataResult]).then((res) => {
            setLoading(false);
        });
    }, []);

    const messageBoxProp: IModalFormBuilderProps = {
        header: action.title,
        showOkButton: false,
        showCancelButton: false,
        cancelClick: onClose,
        size: action.modalSize ?? 'xl',
        isBusy: loading,
        errorText: errorText,
        formMethods: methods,
        fields: fieldsData!,
        rows: form?.view,
        docId: value.key,
        isEdit: false,
        isNew: false,
    };

    return fieldsData ? <ModalFormBuilder {...messageBoxProp} /> : <Preloader size="l" />;
};

export default OfferCustomForm;
