import React, { ReactElement, useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Location } from 'history';
import { ActionTypes } from '@models/actions/ActionTypes';
import { IBaseAction } from '@models/actions/IBaseAction';
import { ActivityActionsService } from '@services/actions/ActivityActionsService';
import { ProcessActionsService } from '@services/actions/ProcessActionsService';
import { DocActionsExecFactory } from './DocActionsExecFactory';
import { IActionExecutor } from './IActionExecutor';
import { RouteMapActionsService } from '@services/actions/RouteMapActionsService';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import { ModalSize } from '@atoms/Modal/Modal';

export type IActionExecutorModelProps = {
    type: ActionTypes;
    objId: string;
    action: IBaseAction;
    completeHandler?: (isSucceed: boolean) => void;
    navigateFunc?: NavigateFunction;
    location?: Location;
    fields?: Record<string, IFieldElem>;
};

export const ActionExecutorModel: React.FC<IActionExecutorModelProps> = (props: IActionExecutorModelProps) => {
    const [element, setElement] = useState<ReactElement>();
    const activated = React.useRef(false);

    const InitExecutor = async (type: ActionTypes, objId: string, act: IBaseAction) => {
        let executor: IActionExecutor;
        switch (type) {
            case ActionTypes.Document:
                executor = DocActionsExecFactory.GetActionExecutor(act);
                break;
            case ActionTypes.Activity:
                executor = new ActivityActionsService().getActionExecutor(act);
                break;
            case ActionTypes.Process:
                executor = new ProcessActionsService().getActionExecutor(act);
                break;
            case ActionTypes.RouteMap:
                executor = new RouteMapActionsService().getActionExecutor(act);
                break;
            default:
                throw new Error(`Не обнаружен executor для действия ${act.type}`);
        }

        executor.navigate = props.navigateFunc;
        executor.location = props.location;

        // -------------------------------------------------------------------------------------------------------------------
        //  Вычисление размера модального окна по формуле
        // -------------------------------------------------------------------------------------------------------------------
        let modalSize: ModalSize | null = null;
        if (props.action.options?.modalSizeFormula && props.fields) {
            let modalSizeFormulaMng = new FormulaManager(props.action.options?.modalSizeFormula);
            modalSizeFormulaMng.Init(props.fields);
            modalSize = (await modalSizeFormulaMng.EvalFormulaValues(false, false)) as ModalSize;
        }
        // -------------------------------------------------------------------------------------------------------------------

        await executor.run(
            objId,
            undefined,
            act,
            undefined,
            (succeed) => {
                props.completeHandler && props.completeHandler(succeed);
            },
            undefined,
            modalSize,
        );

        if (activated.current) {
            setElement(executor?.visualElement && executor?.visualElement());
        }
    };

    useEffect(() => {
        activated.current = true;
        InitExecutor(props.type, props.objId, props.action);
        return () => {
            activated.current = false;
        };
    }, []);

    return <> {element} </>;
};
