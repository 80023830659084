import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotificationSettings } from '@models/notificationSettings/INotificationSettings';

export interface INotificationSettingsState {
    data?: INotificationSettings[];
    isLoading: boolean;
    error: string;
}

const initialState: INotificationSettingsState = {
    isLoading: true,
    error: '',
    data: [],
};

export const NotificationSettingsSlice = createSlice({
    name: 'notificationSettings',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        successLoadData: (state, action: PayloadAction<INotificationSettings[]>) => {
            state.data = action.payload;
        },
    },
});

export const { successLoadData, setIsLoading, setError } = NotificationSettingsSlice.actions;

export default NotificationSettingsSlice.reducer;
