import { UseFormReturn } from 'react-hook-form';
import { IDocumentAttachmentsTable } from '@models/Forms/IForms';
import './AttachmentsTable.scss';
import { IFieldElem } from '@models/IFormData';
import React, { useEffect, useState } from 'react';
import { TableTab } from '@models/document/IDocumentScheme';
import DocumentAttachTable from '@organisms/document/DocumentTabBuilder/tabs/DocumentTabAttach/DocumentAttachTable';
import { AttachFilesBlockControl } from '@/components/controls';
import { IAttachesCreateModel } from '@/models/attaches/IAttachesCreateModel';
import { AttachActionsService } from '@/services/actions/AttachActionsService';
import { ISelectedFiles } from '@/components/atoms/AttachFilesBlock/AttachFilesBlock';
import { useTypedSelector } from '@/hooks/useTypedSelector';

export interface IAttachmentsTableProps<TFieldValues extends object = object> {
    table: IDocumentAttachmentsTable;
    tables: TableTab[];
    formMethods: UseFormReturn<TFieldValues>;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    docId?: string;
}

const AttachmentsTable = <TFieldValues extends object = object>({
    table,
    tables,
    formMethods,
    isEdit,
    isNew,
    fields,
    docId,
    ...props
}: IAttachmentsTableProps<TFieldValues>) => {
    const activated = React.useRef(false);
    const loadedData = React.useRef(false);
    const [attachModel, setAttachModel] = useState<IAttachesCreateModel>();
    const data = useTypedSelector((state) => state.document.data);

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    useEffect(() => {
        if (isNew || isEdit) initData();
    }, [data]);

    const initData = () => {
        let id = data?.id;
        let flowKey = data?.flowKey;
        if (id && flowKey) {
            let service = new AttachActionsService(id, flowKey);
            if (table.addButtonKey) {
                service.getAttachInfo(table.addButtonKey).then((res) => {
                    if (activated.current) setAttachModel(res.data);

                    if (isEdit && !loadedData.current) {
                        let props = res.data.properties.map((x) => {
                            return x.key;
                        });
                        service.getAttachmentsProperties(props).then((response) => {
                            let keyColl = Object.keys(response.data);
                            let rows: any[] = [];
                            for (let index = 0; index < keyColl.length; index++) {
                                const attachKey = keyColl[index];
                                let attachPropColl = (response.data as any)[attachKey];
                                let propKeys = Object.keys(attachPropColl);

                                let row: any = {
                                    _fileId: attachKey,
                                    _actType: 'edit',
                                };
                                for (let y = 0; y < propKeys.length; y++) {
                                    const propKey = propKeys[y];
                                    let prop = (attachPropColl as any)[propKey];
                                    row[propKey] = prop.value;
                                }

                                rows.push(row);
                            }
                            let data: ISelectedFiles = {
                                files: [],
                                attachProperties: rows,
                            };

                            formMethods.setValue('files' as any, data as any, { shouldDirty: false });
                            loadedData.current = true;
                        });
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (!isNew && !isEdit) loadedData.current = false; // Нажата кнопка "Отмена"
        if (isNew || isEdit) initData();
    }, [isEdit, isNew]);

    const attachmentTable = tables.find((t) => t.key === table.key);

    return table && table.key && docId && attachmentTable && !isEdit && !isNew ? (
        <DocumentAttachTable id={table.id} attachTable={attachmentTable} docId={docId} />
    ) : (
        <AttachFilesBlockControl id={table.id} attachModel={attachModel!} formMethods={formMethods} name={'files'} />
    );
};

export default AttachmentsTable;
