import React, { FC, ReactNode, useState } from 'react';
import './Box.scss';
import { MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';
import { classnames } from '@utils/classnames';
import { ConfigService } from '@/configuration/services/configService';

export interface IBoxProps {
    /** Элемент */
    children: ReactNode;
    /** Заголовок */
    header?: ReactNode;
    /** ИД */
    id?: string;
    /** Во всю ширину */
    fullWidth?: boolean;
    /** Класс */
    className?: string;
    /** Свернут по умолчанию */
    defaultCollapsed?: boolean;
    /** Возможность сворачивания */
    collapseEnabledOverride?: boolean;
}

const Box: FC<IBoxProps> = ({
    children,
    header = undefined,
    id = undefined,
    fullWidth = false,
    className = '',
    defaultCollapsed = false,
    collapseEnabledOverride,
    ...props
}: IBoxProps) => {
    const [collapsed, setCollapsed] = useState<boolean>(defaultCollapsed);

    const width100 = fullWidth ? 'box-container-width100' : '';

    const config = ConfigService.get();
    const collapseEnabled =
        collapseEnabledOverride !== undefined ? collapseEnabledOverride : config.application.boxCollapseEnabled;

    return (
        <div
            className={classnames(
                `box-container`,
                !!className && className,
                !!width100 && width100,
                collapseEnabled && collapsed && 'box-container--collapsed',
            )}
            data-testid={id ? `block-${id}` : undefined}
            {...props}
        >
            {header !== undefined && (
                <div
                    className="box-title"
                    onClick={() => {
                        if (!collapseEnabled) return;
                        setCollapsed(!collapsed);
                    }}
                >
                    {header}
                    {collapseEnabled && (
                        <button className="box-title__icon" type="button" tabIndex={-1}>
                            {collapsed ? <MdOutlineExpandMore size="20" /> : <MdOutlineExpandLess size="20" />}
                        </button>
                    )}
                </div>
            )}
            <div className={classnames('box-content')}>{children}</div>
        </div>
    );
};

export default Box;
