import React, { FC, useEffect, useRef, useState } from 'react';
import './DocumentTabItem.scss';
import FormBuilder from '@molecules/formbuilder/FormBuilder';
import { IDocumentData } from '@models/document/IDocumentData';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { IDocumentBlock } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import { handlerFieldWatch } from '@utils/documentUtils';
import { IDocumentScheme } from '@models/document/IDocumentScheme';
import { DropdownContainerContext } from '@/context/Contexts';

export interface IDocumentTabItem {
    tab: IDocumentBlock;
    scheme: IDocumentScheme;
    isNew: boolean;
    isEdit: boolean;
    formMethods: UseFormReturn<IDocumentData>;
    setError: (errors?: string[]) => void;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
    docId: string;
}

const DocumentTabItem: FC<IDocumentTabItem> = ({
    tab,
    scheme,
    isNew,
    isEdit,
    formMethods,
    setError,
    fields,
    includedFields,
    docId,
    ...props
}: IDocumentTabItem) => {
    let visibilityRules = tab?.visibilityRules;
    const visibilityMng = new FormulaManager(visibilityRules!);
    const activated = React.useRef(false);
    const documentTabRef = useRef<HTMLDivElement>(null);
    const [visibility, setVisibility] = useState<boolean>(false);
    const InitFormulas = async () => {
        let result = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(result);
        }
    };
    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    const watchVisibility = useWatch({
        name: visibilityMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watchVisibility, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watchVisibility, isEdit, isNew]);

    return visibility ? (
        <DropdownContainerContext.Provider value={documentTabRef}>
            <div className="doc-tab" ref={documentTabRef} {...props}>
                <FormBuilder
                    block={tab}
                    docId={docId}
                    setError={setError}
                    isEdit={isEdit}
                    isNew={isNew}
                    formMethods={formMethods}
                    fields={fields}
                    includedFields={includedFields}
                    uniqueKey={docId ? docId : '-1'}
                    docScheme={scheme}
                />
            </div>
        </DropdownContainerContext.Provider>
    ) : (
        <></>
    );
};

export default DocumentTabItem;
