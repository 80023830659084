import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from '@/App';
import { Provider } from 'react-redux';
import { setupStore } from './store';
import { apiStore } from './http/index';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.scss';
import { HelmetProvider } from 'react-helmet-async';

export const BASE_PATH: string = import.meta.env.VITE_BASE_PATH as string;

const store = setupStore();
apiStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <StrictMode>
        <HelmetProvider>
            <Provider store={store}>
                <BrowserRouter basename={BASE_PATH}>
                    <App />
                </BrowserRouter>
            </Provider>
        </HelmetProvider>
    </StrictMode>,
);
