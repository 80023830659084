import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IDocumentData } from '@models/document/IDocumentData';
import { IDocumentChange } from '@models/document/IDocumentHistory';
import { IDocumentResult } from '@models/document/IDocumentResult';
import { IDocumentScheme } from '@models/document/IDocumentScheme';
import { IDocumentStatusBar } from '@models/document/IDocumentStatusBar';
import { IForms } from '@models/Forms/IForms';
import { IFormData } from '@models/IFormData';

export class DocumentService {
    static async getData(
        docId: string,
        openDocumentGuid: string | undefined = undefined,
    ): Promise<AxiosResponse<IDocumentData>> {
        // return axios.get<ITreeOption[]>('./tasksMenu.json')
        return $api.get<IDocumentData>(`/document/data/${docId}`, {
            params: {
                openDocumentGuid: openDocumentGuid,
            },
        });
    }

    static async getFormData(docId: string, fields: string[]): Promise<AxiosResponse<IFormData>> {
        return $api.get<IFormData>(`/document/formData/${docId}?fields=${fields.join(',')}`);
    }
    static async getDataByAction(
        actId: string,
        docId?: string,
        templateId?: string,
        isFromActivity?: boolean,
        showCurrentValues?: boolean,
    ): Promise<AxiosResponse<IDocumentData>> {
        return $api.get<IDocumentData>(`/document/dataByAction/${actId}/${!docId || docId === '-1' ? '' : docId}`, {
            params: { templateId: templateId, showCurrentValues: showCurrentValues, isFromActivity: isFromActivity },
        });
    }
    static async getHistory(docId: string): Promise<AxiosResponse<IDocumentChange[]>> {
        return $api.get<IDocumentChange[]>(`/document/history/${docId}`);
    }

    static async getStatusBar(
        docId: string,
        openDocumentGuid: string | undefined,
    ): Promise<AxiosResponse<IDocumentStatusBar>> {
        return $api.get<IDocumentStatusBar>(`/document/statusBar/${docId}`, {
            params: {
                openDocumentGuid: openDocumentGuid,
            },
        });
    }

    static async getScheme(
        docId: string,
        formNameOverride?: string,
        openDocumentGuid: string | undefined = undefined,
    ): Promise<AxiosResponse<IDocumentScheme>> {
        let url = `/document/scheme/${docId}`;

        return $api.get<IDocumentScheme>(url, {
            params: {
                formNameOverride: formNameOverride,
                openDocumentGuid: openDocumentGuid,
            },
        });
    }
    static async getTableScheme(table: string): Promise<AxiosResponse<IForms>> {
        return $api.get<IForms>(`/document/tableScheme/${table}`);
    }
    static async getSchemeByAction(
        actId: string,
        baseDocId?: string,
        formNameOverride?: string,
    ): Promise<AxiosResponse<IDocumentScheme>> {
        let url =
            !baseDocId || baseDocId === '-1'
                ? `/document/schemeByAction/${actId}`
                : `/document/schemeByAction/${actId}/${baseDocId}`;

        return $api.get<IDocumentScheme>(url, {
            params: {
                formNameOverride: formNameOverride,
            },
        });
    }

    static async saveData(doc: IDocumentData, templateId?: string): Promise<AxiosResponse<IDocumentResult>> {
        const formData = new FormData();

        if (doc.files) {
            doc.files.attachProperties.map((prop) => formData.append('attachProps', JSON.stringify(prop)));
            doc.files.files.map((f) => formData.append('attachFiles', f));
            delete doc.files;
        }
        formData.append('doc', JSON.stringify(doc));
        formData.append('templateId', templateId ?? '');

        return $api.post(`/document/save`, formData);
    }
}
