import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAppDataState {
    version: string | null;
}

const initialState: IAppDataState = {
    version: null,
};

export const AppDataSlice = createSlice({
    name: 'appData',
    initialState,
    reducers: {
        setVersion: (state, action: PayloadAction<string>) => {
            state.version = action.payload;
        },
    },
});

export const { setVersion } = AppDataSlice.actions;

export default AppDataSlice.reducer;
