import { UseFormReturn, useWatch } from 'react-hook-form';
import { IDocumentCalc, ISetValue } from '@models/Forms/IForms';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import './Calc.scss';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import { InputNumberControl } from '@controls/index';
import { handlerFieldWatch } from '@utils/documentUtils';
import { ChangesFieldValue, GetValueForSetValue } from '@utils/ChangesManager';
import DisplayField from '@atoms/DisplayField/DisplayField';
import { ValueType } from '@/types/ValueType';
import ToolTipTitle from '@atoms/ToolTipTitle/ToolTipTitle';
import { IPartialFormGroup } from '@controls/types';
import { round } from 'mathjs';

export interface ICalcProps<TFieldValues extends object = object> {
    calc?: IDocumentCalc;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: string[]) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
}

const Calc = <TFieldValues extends object = object>({
    calc,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    includedFields,
    ...props
}: ICalcProps<TFieldValues>) => {
    let visibilityRules = calc?.visibilityRules;
    let readOnlyRules = calc?.readonly;
    let requiredRules = calc?.required;
    let formula = calc?.formula;
    const visibilityMng = new FormulaManager(visibilityRules!);
    const readOnlyMng = new FormulaManager(readOnlyRules!);
    const requiredMng = new FormulaManager(requiredRules!);
    const formulaMng = new FormulaManager(formula!);
    const dataField = fields[calc?.key!];
    const idField = dataField?.name;
    includedFields.push(dataField?.index);
    visibilityMng.Init(fields, formMethods);
    readOnlyMng.Init(fields, formMethods);
    requiredMng.Init(fields, formMethods);
    formulaMng.Init(fields, formMethods);

    const activated = React.useRef(false);
    const typeValue = calc?.valueType !== undefined ? calc?.valueType : ValueType.Integer;
    const [visibility, setVisibility] = useState<boolean>(false);
    const [required, setRequired] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);

    const InitFormulas = async () => {
        let vis = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(vis);
        }

        if (requiredRules) {
            let req = await requiredMng.EvalFormulaValues(isEdit, isNew);
            if (activated.current) {
                setRequired(req);
            }
        }

        if (readOnlyRules) {
            let readonly = await readOnlyMng.EvalFormulaValues(isEdit, isNew);
            if (activated.current) {
                setReadOnly(readonly);
            }
        }
    };

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    const watchVisibility = useWatch({
        name: visibilityMng.GetWatchFields(),
    });
    const watchReadOnly = useWatch({
        name: readOnlyMng.GetWatchFields(),
    });
    const watchRequired = useWatch({
        name: requiredMng.GetWatchFields(),
    });
    const watchFormula = useWatch({
        name: formulaMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watchVisibility, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watchVisibility, isEdit, isNew]);
    useEffect(() => {
        if (readOnlyRules) {
            handlerFieldWatch(watchReadOnly, isEdit, isNew, readOnlyMng, setReadOnly, activated);
        }
    }, [watchReadOnly, isEdit, isNew]);
    useEffect(() => {
        if (requiredRules) {
            handlerFieldWatch(watchRequired, isEdit, isNew, requiredMng, setRequired, activated);
        }
    }, [watchRequired, isEdit, isNew]);
    const calcFormula = async (watchFormula: any[], isEdit: boolean, isNew: boolean) => {
        let val = await formulaMng.EvalFormulaValues(isEdit, isNew, watchFormula);

        if (typeValue == ValueType.Text) {
            formMethods.setValue(idField as any, val, { shouldDirty: true });
            return;
        }

        let valForm = +val;
        if (calc?.floatPoints) {
            valForm = round(valForm, calc?.floatPoints);
        }
        formMethods.setValue(idField as any, valForm as any, { shouldDirty: true });
        //  await onInputNumberChange(val);
    };
    useEffect(() => {
        if (watchFormula.length != 0 && visibility) {
            if (!watchFormula.every((element) => element === undefined)) {
                calcFormula(watchFormula, isEdit, isNew);
            }
        }
    }, [watchFormula, isEdit, isNew]);

    const onSaveField = async (item: ISetValue, rowData?: any) => {
        let val = await GetValueForSetValue(item, undefined, fields, rowData, formMethods);
        let field = fields[item.key];
        formMethods.setValue(field.name as any, val, { shouldDirty: true });
    };

    const onInputNumberChange = async (value: number) => {
        await ChangesFieldValue(
            calc?.changes!,
            value,
            calc?.key!,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            undefined,
            undefined,
            formMethods,
        );
    };
    useEffect(() => {
        if (
            !required &&
            formMethods.formState.errors &&
            (formMethods.formState.errors as any).fields &&
            (formMethods.formState.errors as any).fields[dataField?.index]
        ) {
            formMethods.clearErrors(dataField?.name as any);
        }
    }, [required]);

    const fieldControlType = () => {
        switch (typeValue) {
            case ValueType.Double:
                return (
                    <InputNumberControl
                        label={calc?.name}
                        tooltip={<ToolTipTitle title={calc?.title} />}
                        formGroupProps={{ verticalBottomAlign: calc?.verticalBottomAlign } as IPartialFormGroup}
                        groupBy={0}
                        separator={''}
                        readOnly={readOnly}
                        required={required}
                        floatPoints={calc?.floatPoints ? calc?.floatPoints : 2}
                        type="number"
                        step={0.01}
                        rules={{
                            required: {
                                value: required ? required : false,
                                message: 'Обязательное поле',
                            },
                        }}
                        name={idField}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onValueChange={onInputNumberChange}
                        placeholder={calc?.placeholder ? calc.placeholder : undefined}
                    />
                );
            case ValueType.Money:
                return (
                    <InputNumberControl
                        label={calc?.name}
                        tooltip={<ToolTipTitle title={calc?.title} />}
                        formGroupProps={{ verticalBottomAlign: calc?.verticalBottomAlign } as IPartialFormGroup}
                        readOnly={readOnly}
                        required={required}
                        floatPoints={calc?.floatPoints ? calc?.floatPoints : 2}
                        step={0.01}
                        rules={{
                            required: {
                                value: required ? required : false,
                                message: 'Обязательное поле',
                            },
                        }}
                        name={idField}
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onValueChange={onInputNumberChange}
                        placeholder={calc?.placeholder ? calc.placeholder : undefined}
                    />
                );

            default:
                return (
                    <InputNumberControl
                        label={calc?.name}
                        tooltip={<ToolTipTitle title={calc?.title} />}
                        formGroupProps={{ verticalBottomAlign: calc?.verticalBottomAlign } as IPartialFormGroup}
                        groupBy={0}
                        separator={''}
                        rules={{
                            required: {
                                value: required ? required : false,
                                message: 'Обязательное поле',
                            },
                        }}
                        readOnly={readOnly}
                        required={required}
                        name={idField}
                        type="number"
                        formState={formMethods.formState}
                        control={formMethods.control}
                        onValueChange={onInputNumberChange}
                        placeholder={calc?.placeholder ? calc.placeholder : undefined}
                    />
                );
        }
    };

    return calc && calc.key && visibility && idField != undefined ? (
        <div className="form-field" data-testid={calc.id ? `calc-${calc.id}` : undefined}>
            {isEdit || isNew ? (
                fieldControlType()
            ) : (
                <FieldWrapper inLineFormat={calc.isValue} lable={calc.name} title={calc.viewTitle}>
                    <DisplayField type={typeValue} lable={calc.name} value={dataField.value} />
                </FieldWrapper>
            )}
        </div>
    ) : (
        <></>
    );
};

export default Calc;
