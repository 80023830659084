import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { ITableDemand } from '@models/winnerSelection/ITableDemand';
import { IOfferCritDetailsForm } from '@molecules/formbuilder/controls/CustomComponent/components/OfferCritDetails/OfferCritDetails';
import { IOfferRateForm } from '@molecules/formbuilder/controls/CustomComponent/components/OfferRate/OfferRate';
import { IOfferTechAnalysisForm } from '@molecules/formbuilder/controls/CustomComponent/components/OfferTechAnalysis/OfferTechAnalysis';
import { ITablePositions } from '@models/winnerSelection/ITablePositions';
import { IForms } from '@models/Forms/IForms';
import { IOfferApproveToStageTwoForm } from '@molecules/formbuilder/controls/CustomComponent/components/OfferApproveToStageTwo/OfferApproveToStageTwo';
import { ITableKO } from '@models/winnerSelection/ITableKO';
import { IOfferKOCritDetailsForm } from '@molecules/formbuilder/controls/CustomComponent/components/OfferKOCritDetails/OfferKOCritDetails';

export class WinnerSelectionService {
    static async fetchTableDemandData(docId: string, tableKey: string): Promise<AxiosResponse<ITableDemand>> {
        return $api.get<ITableDemand>(`/winnerSelection/tableDemand/${docId}/${tableKey}`);
    }

    static async saveTableDemandOfferCrit(
        offerId: string,
        demandId: string,
        tableKey: string,
        formData: IOfferCritDetailsForm,
    ): Promise<AxiosResponse> {
        return $api.post(`/winnerSelection/tableDemand/${offerId}/${demandId}/${tableKey}/crit`, formData);
    }

    static async saveTableDemandOfferRate(
        offerId: string,
        demandId: string,
        tableKey: string,
        formData: IOfferRateForm,
    ): Promise<AxiosResponse> {
        return $api.post(`/winnerSelection/tableDemand/${offerId}/${demandId}/${tableKey}/rate`, formData);
    }

    static async saveTableDemandOfferTechAnalysis(
        offerId: string,
        demandId: string,
        tableKey: string,
        formData: IOfferTechAnalysisForm,
    ): Promise<AxiosResponse> {
        return $api.post(`/winnerSelection/tableDemand/${offerId}/${demandId}/${tableKey}/techAnalysis`, formData);
    }

    static async saveTableDemandOfferApproveToStageTwo(
        offerId: string,
        demandId: string,
        tableKey: string,
        formData: IOfferApproveToStageTwoForm,
    ): Promise<AxiosResponse> {
        return $api.post(`/winnerSelection/tableDemand/${offerId}/${demandId}/${tableKey}/approveToStageTwo`, formData);
    }

    // -------------------------------------------------------------------------------------------------------------------

    static async fetchTablePositionsData(docId: string, tableKey: string): Promise<AxiosResponse<ITablePositions>> {
        return $api.get<ITablePositions>(`/winnerSelection/tablePositions/${docId}/${tableKey}`);
    }

    // -------------------------------------------------------------------------------------------------------------------

    // Возвращает модель для формы
    static async fetchCustomFormScheme(tableKey: string, actionKey: string): Promise<AxiosResponse<IForms>> {
        return $api.get<IForms>(`/winnerSelection/customFormScheme/${tableKey}/${actionKey}`);
    }

    // -------------------------------------------------------------------------------------------------------------------

    static async fetchTableKOData(docId: string, tableKey: string): Promise<AxiosResponse<ITableKO>> {
        return $api.get<ITableKO>(`/winnerSelection/tableKO/${docId}/${tableKey}`);
    }

    static async saveTableKOOfferCrit(
        offerId: string,
        demandId: string,
        tableKey: string,
        formData: IOfferKOCritDetailsForm,
    ): Promise<AxiosResponse> {
        return $api.post(`/winnerSelection/tableKO/${offerId}/${demandId}/${tableKey}/crit`, formData);
    }
}
