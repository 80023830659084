import React, { FC, ReactNode } from 'react';
import './FatalError.scss';
import FatalErrorIcon from './FatalErrorIcon';

export interface IFatalErrorProps {
    /** Компонент для изображения ошибки */
    icon?: ReactNode;
    /** Заголовок ошибки */
    title?: string;
    /** Описание ошибки */
    description?: string;
}

const FatalError: FC<IFatalErrorProps> = ({
    description = 'Что-то пошло не так. Пожалуйста, обновите страницу.',
    icon = <FatalErrorIcon />,
    title = 'Ошибка',
}: IFatalErrorProps) => {
    return (
        <div className="fatal-error">
            {icon}
            <span className="fatal-error__label">{title}</span>
            <span className="fatal-error__message">{description} </span>
        </div>
    );
};

export default FatalError;
