import { IOption } from '@/types';

export enum TransitionType {
    //Последующий
    Next,
    //Предыдущий
    Prev,
    //Параллельный
    Parallel,
}

const TransitionTypeOption: IOption[] = [
    {
        label: 'Последующий',
        value: TransitionType[TransitionType.Next],
    },
    {
        label: 'Предыдущий',
        value: TransitionType[TransitionType.Prev],
    },
    {
        label: 'Параллельный',
        value: TransitionType[TransitionType.Parallel],
    },
];

export default TransitionTypeOption;
