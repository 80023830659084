import { AxiosResponse } from 'axios';

import $api from '@/http/index';
import { IFile, IFileCatalog } from '@models/documentation/IDocumentation';

export class DocumentationService {
    static async getFileCatalogs(): Promise<AxiosResponse<IFileCatalog[]>> {
        return $api.get<IFileCatalog[]>(`/documentation/getFileCatalogs`);
    }

    static async getFiles(key: number): Promise<AxiosResponse<IFile[]>> {
        return $api.get<IFile[]>(`/documentation/getFiles?catalogKey=${key}`);
    }
}
