import React from 'react';

import './TableData.scss';
import { IDocumentTable } from '@models/Forms/IForms';

export interface RowCopyButtonProps {
    table: IDocumentTable;
    rowData: any;
    onCopy: (rowData: any) => void;
    docId?: string;
}

const RowCopyButton: React.FC<RowCopyButtonProps> = ({ table, rowData, onCopy, docId }: RowCopyButtonProps) => {
    const onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onCopy(rowData);
    };

    return (
        <a
            href="#"
            className="dx-link dx-link-delete dx-icon-copy dx-link-icon"
            onClick={onClick}
            title="Копировать строку"
            aria-label="Копировать строку"
        />
    );
};

export default RowCopyButton;
