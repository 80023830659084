import React, { useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { RouteMapActionsService } from '@services/actions/RouteMapActionsService';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { ModalSize } from '@atoms/Modal/Modal';

export class RemoveRouteMapActionExecutor implements IActionExecutor {
    private _modalProps?: IRemoveRouteMapActionProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            actionKey: action.key,
            activityKey: action.options?.activityKey,
            processKey: action.options?.processKey,
            documentKey: action.options?.documentKey,
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElement = () => {
        return this._modalProps ? <RemoveRouteMapActionModal {...this._modalProps} /> : <></>;
    };
}

interface IRemoveRouteMapActionProps {
    actionKey: string;
    activityKey: string;
    processKey: string;
    documentKey: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const RemoveRouteMapActionModal: React.FC<IRemoveRouteMapActionProps> = (props: IRemoveRouteMapActionProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const serv = new RouteMapActionsService();

    const onSubmit = () => {
        return serv
            .remove(props.actionKey, props.activityKey, props.processKey, props.documentKey)
            .then((res) => {
                setLoading(false);

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const modalContainerProps: IMessageDialogProps = {
        header: 'Удаление этапа',
        message: 'Вы действительно хотите удалить этап?',
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        okClick: onSubmit,
    };

    return <MessageDialog {...modalContainerProps}></MessageDialog>;
};
