import { ContextMenuPreparingEvent } from 'devextreme/ui/data_grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import { RefObject } from 'react';
import DataGrid from 'devextreme-react/data-grid';
import { formatMessage, loadMessages, locale } from 'devextreme/localization';
import ruMessages from 'devextreme/localization/messages/ru.json';
import { differenceInMinutes, isBefore, parseISO } from 'date-fns';

/** Блокировка контекстного меню */
const disableContextMenu: (e: ContextMenuPreparingEvent) => void = (e) => {
    e.items = [];
};

/** Выгрузка грида в эксель */
export const exportGridToExcel = (gridRef: RefObject<DataGrid>, fileName: string) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Лист 1');

    exportDataGrid({
        component: gridRef.current?.instance,
        worksheet,
        autoFilterEnabled: true,
        customizeCell(options) {
            const { gridCell, excelCell } = options;

            if (gridCell && gridCell.rowType === 'data' && gridCell.column?.dataType === 'boolean') {
                excelCell.value = formatMessage(gridCell.value);
            }
        },
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
        });
    });
};

export const initMessages = () => {
    loadMessages({
        ru: {
            true: 'Да',
            false: 'Нет',
        },
    });
    loadMessages(ruMessages);
    locale('ru');
};

export const getTaskDueTimeClass = (dueTimeStr: string): string => {
    if (!dueTimeStr) {
        // бессрочная задача
        return 'task-normal-row';
    } else {
        const currDate = new Date();
        const dueTimeDate = parseISO(dueTimeStr);
        if (isBefore(dueTimeDate, currDate)) {
            // просроченная задача
            return 'task-overdue-row';
        }

        const dateDiffInMinutes = differenceInMinutes(dueTimeDate, currDate);
        const DIFF_IN_MINUTES = 3 * 24 * 60;
        if (dateDiffInMinutes <= DIFF_IN_MINUTES) {
            // приближается к сроку исполнения
            return 'task-pre-overdue-row';
        } else {
            return 'task-normal-row';
        }
    }
};

export { disableContextMenu };
