import { AxiosResponse } from 'axios';
import { IGridResponse } from '@models/response/IGridResponse';
import { IDataGridDescriptionResponse } from '@models/response/IDataGridDescriptionResponse';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import { IFileInfo } from '@models/response/IFileInfo';
import { IDocumentResult } from '@/models/document/IDocumentResult';

export abstract class IDataGridService {
    abstract fetchScheme(data: any): Promise<AxiosResponse<IDataGridDescriptionResponse>>; // Mandatory
    abstract fetchData(data: any, loadOptions: any): Promise<AxiosResponse<IGridResponse>>; // Mandatory
    abstract putData(data: any): Promise<AxiosResponse<IDocumentResult, any>>;
    abstract fetchDetailData(data: any, detailData: any, loadOptions: any): Promise<AxiosResponse<IGridResponse>>; // Mandatory
    abstract exportData(
        template: string,
        options: any,
        columns: IGridExportSettings[],
    ): Promise<AxiosResponse<IFileInfo>>;
}
