import React, { useEffect, useRef, useState } from 'react';
import './Errors.scss';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createPortal } from 'react-dom';
import ErrorModal from '@molecules/ErrorModal/ErrorModal';

// ---------------------------------------------------------------------------------------------------------------------

/** Стэк сообщений */
let messages$$: BehaviorSubject<IErrorMessage[]> = new BehaviorSubject<IErrorMessage[]>([]);

/** Удалить сообщение */
export const removeErrorMsg = (id?: number) => {
    if (messages$$.closed || messages$$.isStopped) {
        return;
    }

    let tmp = [...messages$$.getValue()];

    if (tmp.length > 0) {
        if (id !== undefined) {
            tmp = tmp.filter((n: IErrorMessage) => n.id !== id);
        } else {
            tmp.shift();
        }

        messages$$.next(tmp);
    }
};

/** Добавить уведомление */
export const sendErrorMsg = (message: IErrorMessage) => {
    if (messages$$.closed || messages$$.isStopped) {
        return;
    }
    if (message.message.length > 0) {
        const tmp = [...messages$$.getValue()];

        tmp.push({
            ...message,
            id: message.id || Date.now(),
        });

        messages$$.next(tmp);
    }
    // setTimeout(() => {
    //   removeErrorMsg(message.id);
    // }, delay);
};

// ----Компонент--------------------------------------------------------------------------------------------------------

export interface IErrorMessage {
    /** Текст сообщения */
    message: string[];
    /** ID сообщения */
    id?: number;
    closeBtnTitle?: string;
    closeBtnClick?: () => void;
    buttons?: IErrorsButton[];
    type?: string;
    title?: string;
}

export interface IErrorsButton {
    title: string;
    onClick: () => void;
}

const Errors = () => {
    /** Флаг по которому остановить подписку */
    const obstacle = useRef<Subject<boolean>>(new Subject());

    const [sub, setSub] = useState<BehaviorSubject<IErrorMessage[]> | null>(null);

    useEffect(() => {
        if (messages$$.closed) {
            messages$$ = new BehaviorSubject<IErrorMessage[]>([]);
        }

        setSub(messages$$);
    }, []);

    /** Список уведомлений */
    const [messages, setMessages] = useState<IErrorMessage[]>([]);

    // -------------------------------------------------------------------------------------------------------------------

    /** Подписываемся на список уведомлений */
    useEffect(() => {
        if (!sub || sub.closed) {
            return;
        }

        const until = obstacle.current;

        sub.pipe(takeUntil(until)).subscribe((data: IErrorMessage[]) => {
            setMessages(data);
        });

        return () => {
            until.next(true);
        };
    }, [sub]);

    // -------------------------------------------------------------------------------------------------------------------
    /** Список уведомлений TSX */
    const list = messages.map((n: IErrorMessage, i: number) => (
        <ErrorModal key={n.id || i} item={n} remove={removeErrorMsg} />
    ));

    // -------------------------------------------------------------------------------------------------------------------

    if (!list.length) {
        return null;
    }

    return createPortal(<div>{list}</div>, document.body);
};

export default Errors;
