import React, { useEffect, useRef, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import { classnames } from '@utils/classnames';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';
import Preloader from '@atoms/Preloader';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { Column, DataGrid, Selection } from 'devextreme-react/data-grid';

import './CopyAttachmentsExecutor.scss';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import Hint from '@atoms/Hint';
import {
    IAttachmentToCopy,
    IAttachmentToCopyExtended,
    ICopyAttachmentsResult,
} from '@models/document/actionsExecution/ICopyAttachmentsModel';
import { ModalSize } from '@atoms/Modal/Modal';
import { onCellHoverChanged } from '@utils/dataGridUtils';

export class CopyAttachmentsExecutor extends DocBaseActionExecutor {
    private _modalProps?: ICopyAttachmentsExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <CopyAttachmentsExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ICopyAttachmentsExecutorProps {
    docId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const CopyAttachmentsExecutorModal: React.FC<ICopyAttachmentsExecutorProps> = (
    props: ICopyAttachmentsExecutorProps,
) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();

    const [attachmentsModel, setAttachmentsModel] = useState<IAttachmentToCopyExtended[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const service = new DocumentExecutionService(props.docId);
    const gridRef = useRef<DataGrid>(null);

    useEffect(() => {
        setIsBusy(true);
        service
            .getCopyAttachmentsModel(props.actionKey)
            .then((res) => {
                setAttachmentsModel(res.data.attachments);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    }, []);

    const onSelectionChanged = (e: any) => {
        setSelectedKeys(e.selectedRowKeys);
    };

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>{props.title}</div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {isBusy ? (
                <Preloader size="m" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    <DevExpressDataGrid
                        dataSource={attachmentsModel}
                        remoteOperations={true}
                        hoverStateEnabled={true}
                        columnHidingEnabled={false}
                        showColumnHeaders={true}
                        columnAutoWidth={true}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        noDataText={'Файлы отсутствуют'}
                        rowAlternationEnabled={true}
                        onSelectionChanged={onSelectionChanged}
                        ref={gridRef}
                        onCellHoverChanged={onCellHoverChanged}
                    >
                        <Selection
                            mode="multiple"
                            allowSelectAll={true}
                            selectAllMode={'allPages'}
                            showCheckBoxesMode={'always'}
                        />
                        <Column
                            key={`col_filename`}
                            caption={'Имя файла'}
                            dataField={'displayName'}
                            dataType={'string'}
                            allowSorting={false}
                            allowHeaderFiltering={false}
                        />
                        <Column
                            key={`col_category`}
                            caption={'Категория'}
                            dataField={'categoryName'}
                            dataType={'string'}
                            allowSorting={false}
                            allowHeaderFiltering={false}
                        />
                    </DevExpressDataGrid>

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                disabled={selectedKeys.length == 0}
                                size="s"
                                aria-label={props.okButtonText}
                                onClick={() => {
                                    setIsBusy(true);

                                    let selectedRows = gridRef?.current?.instance.getSelectedRowsData();
                                    let selectedAttachments =
                                        selectedRows?.map((row) => {
                                            return {
                                                docId: row.docId,
                                                attachId: row.attachId,
                                                categoryName: row.categoryName,
                                            } as IAttachmentToCopy;
                                        }) ?? [];

                                    let copyAttachmentsResult: ICopyAttachmentsResult = {
                                        attachments: selectedAttachments,
                                    };
                                    service
                                        .copyAttachments(props.actionKey, copyAttachmentsResult)
                                        .then(() => {
                                            setIsBusy(false);
                                            if (props.completeHandler) props.completeHandler(true);
                                        })
                                        .catch((error) => setErrorText(error))
                                        .finally(() => {
                                            setIsBusy(false);
                                        });
                                }}
                            >
                                {props.okButtonText}
                            </Button>

                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
