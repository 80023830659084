import { IBaseAction } from '@models/actions/IBaseAction';
import { IListElement } from '@/types';
import { NoActionsItem } from '@models/actions/ActionConstants';

export const convertActions = (baseActs: IBaseAction[], onActionClick: (action: IBaseAction) => void) => {
    if (baseActs === undefined || baseActs === null) return undefined;

    let acts = baseActs?.map<IListElement>((x, i) => ({
        value: i.toString(),
        label: x.displayName,
        handler: () => {
            setTimeout(() => onActionClick(x), 1);
        },
    }));

    if (acts == null || acts.length === 0) {
        acts = [NoActionsItem];
    }

    return acts;
};
