import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IMailReminder } from '@models/calendarWidget/IMailReminder';
import { IMailRemindersBlockModel } from '@models/mailRemindersBlock/IMailRemindersBlockModel';

export class RemindersService {
    static async addOrUpdateReminderFromCalendar(r: IMailReminder): Promise<AxiosResponse<IMailReminder>> {
        return $api.post<IMailReminder>('/mailReminder/calendarWidget/', r);
    }

    static async removeReminder(id: number): Promise<AxiosResponse> {
        return $api.delete(`/mailReminder/${id}`);
    }

    //получает модель для блока напоминаний в действиях по документу/активити
    static async getMailRemindersBlockModel(
        documentKey: string,
        actionKey: string,
        activityId?: string,
    ): Promise<AxiosResponse<IMailRemindersBlockModel>> {
        return $api.get<IMailRemindersBlockModel>(
            `mailReminder/getMailRemindersBlockModel/${documentKey}/${actionKey}/${activityId ?? ''}`,
        );
    }

    //Создает напоминание по документу
    static async createBlockMailReminders(
        documentKey: string,
        actionKey: string,
        message: string,
        targetDateTime: string,
        userIds: string,
        daysBefore: string,
        weekly: string,
        monthly: string,
        dayOfWeek: string,
    ): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('message', message);
        formData.append('targetDateTime', targetDateTime);
        formData.append('userIds', userIds);
        formData.append('daysBefore', daysBefore);
        formData.append('weekly', weekly);
        formData.append('monthly', monthly);
        formData.append('dayOfWeek', dayOfWeek);
        return $api.post(`mailReminder/createBlockMailReminders/${documentKey}/${actionKey}`, formData);
    }
}
