import React, { FC } from 'react';
import './DocumentTabRoutes.scss';

import DevExpressTreeListDynamic from '@atoms/DevExpress/TreeList/DevExpressTreeListDynamic';
import Box from '@atoms/Box';
import { DocumentRoutesService } from '@services/DocumentRoutesService';
import { useNavigate } from 'react-router-dom';
import { DataGridStateService } from '@services/DataGridStateService';
import { FilesService } from '@services/FilesService';
import { ProcessActionsService } from '@services/actions/ProcessActionsService';

export interface IDocumentTabRoutesProps {
    /** ИД документа */
    docId: string;
}

const DocumentTabRoutes: FC<IDocumentTabRoutesProps> = ({ docId }: IDocumentTabRoutesProps) => {
    const navigate = useNavigate();
    const serviceGrid = new DocumentRoutesService();
    const stateService = new DataGridStateService();
    const filesService = new FilesService();
    const actionsService = new ProcessActionsService();

    const onRowClick = (e: any) => {
        // if (e.rowType === "data") {
        //   if (e.data.key) {
        //     navigate("/document/" + e.data.key);
        //   }
        // }
    };

    return (
        <div className="routes-tab">
            <Box header="Маршруты">
                <div className="routes-table">
                    <DevExpressTreeListDynamic
                        key={`grid_routes`}
                        stateService={stateService}
                        data={docId}
                        onRowClick={onRowClick}
                        service={serviceGrid}
                        filesService={filesService}
                        actionService={actionsService}
                    />
                </div>
            </Box>
        </div>
    );
};

export default DocumentTabRoutes;
