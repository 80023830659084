import { RolesService } from '@services/RolesService';
import { RolesSlice } from '@/store/reducers/RolesReducer';
import { AppDispatch } from '@/store/index';

export const RolesActionCreators = {
    fetchRoles: () => async (dispatch: AppDispatch) => {
        try {
            const response = await RolesService.fetch();
            dispatch(RolesSlice.actions.successLoadData(response.data));
        } catch (e) {
            console.log(e);
            let error = e as Error;
            dispatch(RolesSlice.actions.setError(error.message));
        } finally {
            dispatch(RolesSlice.actions.setIsLoading(false));
        }
    },

    setDocumentIsLoading: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(RolesSlice.actions.setIsLoading(flag));
    },
};
