import React, { forwardRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CheckboxBitControl, InputControl, SelectControl } from '@controls/index';
import { IDocumentData } from '@models/document/IDocumentData';
import { classnames } from '@utils/classnames';

import './AddMailReminderBlock.scss';
import { IMailRemindersBlockModel } from '@models/mailRemindersBlock/IMailRemindersBlockModel';

export interface IAddMailReminderBlockProps {
    model: IMailRemindersBlockModel;
    formMethods: UseFormReturn<IDocumentData>;
}

export interface IMailRemindersOptions {
    addReminders: number;
    daysBefore: string;
    periodical: Periodical;
}

export interface Periodical {
    weekly: number;
    monthly: number;
    dayOfWeek: DayOfWeek[];
}

export interface DayOfWeek {
    label: string;
    value: string;
}

enum WeekDay {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}

export const AddMailReminderBlock = forwardRef((props: IAddMailReminderBlockProps, ref) => {
    const [showBlock, setShowBlock] = useState<boolean>(false);
    const [weekly, setWeekly] = useState<boolean>(false);

    const allDaysOfWeek = [
        {
            label: 'Понедельник',
            value: WeekDay[WeekDay.Monday],
        },
        {
            label: 'Вторник',
            value: WeekDay[WeekDay.Tuesday],
        },
        {
            label: 'Среда',
            value: WeekDay[WeekDay.Wednesday],
        },
        {
            label: 'Четверг',
            value: WeekDay[WeekDay.Thursday],
        },
        {
            label: 'Пятница',
            value: WeekDay[WeekDay.Friday],
        },
        {
            label: 'Суббота',
            value: WeekDay[WeekDay.Saturday],
        },
        {
            label: 'Воскресенье',
            value: WeekDay[WeekDay.Sunday],
        },
    ];

    return (
        <>
            <div className={classnames('mail-reminder')}>
                <CheckboxBitControl
                    halfChecked={false}
                    label="Добавить напоминания"
                    name="mailReminders.addReminders"
                    formState={props.formMethods.formState}
                    control={props.formMethods.control}
                    defaultChecked={false}
                    onChange={(e) => {
                        setShowBlock(e.target.checked);
                    }}
                />
                {showBlock ? (
                    <>
                        <div className={classnames('mail-reminder-days-before')}>
                            <div
                                className={classnames(
                                    'mail-reminder-days-before__element',
                                    'mail-reminder-days-before__prefix',
                                )}
                            >
                                Заранее за
                            </div>
                            <div
                                className={classnames(
                                    'mail-reminder-days-before__element',
                                    'mail-reminder-days-before__input',
                                )}
                            >
                                <InputControl
                                    name="mailReminders.daysBefore"
                                    formState={props.formMethods.formState}
                                    control={props.formMethods.control}
                                    placeholder={'Введите дни через запятую'}
                                />
                            </div>
                            <div
                                className={classnames(
                                    'mail-reminder-days-before__element',
                                    'mail-reminder-days-before__suffix',
                                )}
                            >
                                дней до контрольного срока.
                            </div>
                        </div>
                        <div className={classnames('mail-reminder-period-settings')}>
                            <div className={classnames('mail-reminder-period-settings__checkboxes')}>
                                <CheckboxBitControl
                                    halfChecked={false}
                                    label="Раз в неделю"
                                    name="mailReminders.periodical.weekly"
                                    formState={props.formMethods.formState}
                                    control={props.formMethods.control}
                                    checked={weekly}
                                    onChange={(event) => setWeekly(event.target.checked)}
                                />
                                <CheckboxBitControl
                                    halfChecked={false}
                                    label="Раз в месяц"
                                    name="mailReminders.periodical.monthly"
                                    formState={props.formMethods.formState}
                                    control={props.formMethods.control}
                                />
                            </div>
                            {weekly ? (
                                <div className={classnames('mail-reminder-period-settings__day-of-week-input')}>
                                    <SelectControl
                                        label="День недели"
                                        multiselect={false}
                                        readOnly
                                        required={true}
                                        name="mailReminders.periodical.dayOfWeek"
                                        options={allDaysOfWeek}
                                        formState={props.formMethods.formState}
                                        control={props.formMethods.control}
                                        rules={{ required: 'Обязательное поле' }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
});
