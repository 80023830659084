import { IOption } from '@/types';

export enum DurationSpanType {
    //Месяц
    Month,
    //Неделя
    Week,
    //День
    Day,
    //Час
    Hour,
    //Минута
    Minute,
    //Секунда
    Second,
    //Миллисекунда
    Millisecond,
}

const DurationSpanTypeOption: IOption[] = [
    {
        label: 'Месяц',
        value: DurationSpanType[DurationSpanType.Month],
    },
    {
        label: 'Неделя',
        value: DurationSpanType[DurationSpanType.Week],
    },
    {
        label: 'День',
        value: DurationSpanType[DurationSpanType.Day],
    },
    {
        label: 'Час',
        value: DurationSpanType[DurationSpanType.Hour],
    },
    {
        label: 'Минута',
        value: DurationSpanType[DurationSpanType.Minute],
    },
];

export default DurationSpanTypeOption;
