import React, { ReactNode } from 'react';
import { IControlProps, IPartialFormGroup } from '@controls/types';
import FormControl from '../FormControl';
import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';
import InputPassword, { IInputPasswordProps } from '@atoms/InputPassword/InputPassword';

export type IInputPasswordControlProps = IInputPasswordProps &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        tooltip?: ReactNode;
        required?: boolean;
    };

const InputPasswordControl = ({
    name,
    rules,
    defaultValue,
    control,
    formState,
    shouldUnregister,
    label,
    tooltip,
    required,
    formGroupProps,
    ...props
}: IInputPasswordControlProps) => {
    const Controller = useHookFormController();

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ...fieldProps } }) => (
                <FormControl
                    name={name}
                    label={label}
                    tooltip={tooltip}
                    required={required}
                    formState={formState}
                    {...formGroupProps}
                >
                    <InputPassword
                        {...fieldProps}
                        {...props}
                        defaultValue={value}
                        onValueChange={(value) => {
                            onChange(value);
                            props.onValueChange && props.onValueChange(value);
                        }}
                    />
                </FormControl>
            )}
        />
    );
};

export default InputPasswordControl;
