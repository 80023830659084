import { AppDispatch, RootState } from '@/store/index';
import { VersionService } from '@services/VersionService';
import { AppDataSlice } from '@/store/reducers/AppDataReducer';

export const AppDataActionCreators = {
    getVersion: () => async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const state = getState();
            const version = state.appData.version;

            if (version === null) {
                const response = await VersionService.getVersion();
                if (response) {
                    dispatch(AppDataSlice.actions.setVersion(response.data));
                }
            }
        } catch (e) {
            console.log(e);
        }
    },
};
