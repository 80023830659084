import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IGridStateSettings } from '@models/response/IGridStateSettings';
import { IDataGridStateService } from './IDataGridStateService';

export class DataGridStateService extends IDataGridStateService {
    async fetchState(data: any): Promise<AxiosResponse<IGridStateSettings[]>> {
        return $api.get<IGridStateSettings[]>(`settings/table/load/${data}`);
    }
    async saveState(stateKey: string, settings: IGridStateSettings[]): Promise<AxiosResponse<any>> {
        const formData = new FormData();
        formData.append('key', stateKey);
        formData.append('state', JSON.stringify(settings));

        return $api.post(`settings/table/save`, formData);
    }
}
