import React, { ReactNode } from 'react';
import { IControlProps, IPartialFormGroup } from '@controls/types';
import { IOption } from '@/types';
import FormControl from '../FormControl';

import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';
import AutoCompletePicker, { IAutoCompletePickerProps } from '@atoms/AutoCompletePicker/AutoCompletePicker';

export type IAutoCompleteControlProps = IAutoCompletePickerProps &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        tooltip?: ReactNode;
        required?: boolean;
        onChangeVal?: (value: string) => void;
        findOptions: (value: string) => Promise<IOption[]>;
    };

const AutoCompleteControl = ({
    name,
    rules,
    control,
    formState,
    shouldUnregister,
    formGroupProps,
    label,
    tooltip,
    required,
    onChangeVal,
    findOptions,
    ...props
}: IAutoCompleteControlProps) => {
    const Controller = useHookFormController();
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            render={({ field: { onChange, ref, value, ...fieldProps } }) => (
                <FormControl
                    name={name}
                    label={label}
                    tooltip={tooltip}
                    required={required}
                    formState={formState}
                    {...formGroupProps}
                >
                    <AutoCompletePicker
                        {...fieldProps}
                        {...props}
                        value={value}
                        debounceTime={500}
                        clearOnSelect={false}
                        findOptions={findOptions}
                        onInputValueChanged={(val: string) => {
                            if (value === undefined || value === null || value.toString() != val.toString()) {
                                onChange(val);
                                if (onChangeVal) {
                                    onChangeVal(val);
                                }
                            }
                        }}
                    />
                </FormControl>
            )}
        />
    );
};

export default AutoCompleteControl;
