import React, { ReactNode } from 'react';
import { IControlProps, IPartialFormGroup } from '@controls/types';
import { IOption } from '@/types';
import FormControl from '../FormControl';
import Select, { ISelectProps } from '@atoms/Select/Select';
import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';

export type IYearPickerControlProps = Omit<ISelectProps, 'onChange' | 'values'> &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        tooltip?: ReactNode;
        required?: boolean;
        min?: number;
        max?: number;
        onChangeVal?: (value: number) => void;
    };

const YearPickerControl = ({
    name,
    min,
    max,
    rules,
    defaultValue = [],
    control,
    formState,
    shouldUnregister,
    label,
    tooltip,
    required,
    formGroupProps,
    onChangeVal,
    ...props
}: IYearPickerControlProps) => {
    const Controller = useHookFormController();
    const options: IOption[] = [];
    let countItems = 100;
    let startYear = 1950;
    if (min && min > 1950) {
        startYear = min;
    }
    if (max && max) {
        countItems = max - startYear;
    }

    for (let index = 0; index <= countItems; index++) {
        let year = startYear + index;
        options.push({
            label: year.toString(),
            value: year.toString(),
        } as IOption);
    }
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            defaultValue={defaultValue}
            render={({ field: { onChange, ref, value, ...fieldProps } }) => (
                <FormControl
                    name={name}
                    label={label}
                    tooltip={tooltip}
                    required={required}
                    formState={formState}
                    {...formGroupProps}
                >
                    <Select
                        {...fieldProps}
                        {...props}
                        options={options}
                        onlySelect={true}
                        readOnly={true}
                        values={
                            value
                                ? [
                                      {
                                          value: value.toString(),
                                          label: value.toString(),
                                      } as IOption,
                                  ]
                                : undefined
                        }
                        onChange={(option: IOption[]) => {
                            if (option.length > 0) {
                                let val = option[0].value;
                                if (value === undefined || value === null || value.toString() != val.toString()) {
                                    onChange(+val);
                                    if (onChangeVal) {
                                        onChangeVal(+val);
                                    }
                                }
                            }
                        }}
                    />
                </FormControl>
            )}
        />
    );
};

export default YearPickerControl;
