export enum ActivityActionTypes {
    /// <summary>
    /// Взять в работу
    /// </summary>
    TakeToWork,
    /// <summary>
    /// Запросить дополнительное согласование
    /// </summary>
    RequestAdditionalApproval,
    /// <summary>
    /// Согласовать (с ЭЦП)
    /// </summary>
    ApproveWithSign,
    /// <summary>
    /// Согласовать с замечанием (с ЭЦП)
    /// </summary>
    ReworkWithSign,
    /// <summary>
    /// Не согласовать
    /// </summary>
    RejectWithSign,
    /// <summary>
    /// Согласовать
    /// </summary>
    Approve,
    /// <summary>
    /// Согласовать с замечанием
    /// </summary>
    Rework,
    /// <summary>
    /// Не согласовать
    /// </summary>
    Reject,
    /// <summary>
    /// Завершить
    /// </summary>
    Complete,
    /// <summary>
    /// Массовое редактирование реквизитов документов
    /// </summary>
    ActivityBatchEditing,
    /// <summary>
    /// Сменить исполнителя
    /// </summary>
    ChangePerformer,
    /// <summary>
    /// Откатить исполнителя
    /// </summary>
    RollbackPerformer,
    /// <summary>
    /// Делегировать
    /// </summary>
    Delegate,
    /// <summary>
    /// Отменить делегирование
    /// </summary>
    RollbackDelegate,
    /// <summary>
    /// Обработать исключение
    /// </summary>
    ResolveException,
}
