import React, { ReactNode, useEffect, useState } from 'react';
import {
    IColumn,
    IDocumentAbook,
    IDocumentCalc,
    IDocumentDict,
    IDocumentField,
    IDocumentTable,
    IRow,
    IRowArr,
    IRows,
} from '@models/Forms/IForms';
import './TableForm.scss';
import { MdClose } from 'react-icons/md';
import Modal from '../../Modal';
import Button from '@atoms/Button';
import FormBuilder from '@molecules/formbuilder/FormBuilder';
import { ITableForm } from '@models/Forms/ITableForm';
import { Controller, useForm } from 'react-hook-form';
import { IField, IFieldElem } from '@models/IFormData';
import { convertListToMap, getFieldKeysByScheme } from '@utils/documentUtils';
import { FormProviderControl, HookFormProvider } from '@controls/index';
import { DocumentService } from '@services/DocumentService';
import { sendErrorMsg } from '@/components/molecules/Errors';
import Pagination from '../Pagination';
import { v4 as uuidv4 } from 'uuid';

export interface TableFormProps {
    table: IDocumentTable;
    onClose: () => void;
    onSubmit?: (data: IField[], rowIndex: number) => void;
    getParentFields: () => IField[];
    title: string;
    isEdit: boolean;
    children?: ReactNode;
    docId?: string;
    submitButtonCaption?: string;
    rowIndex: number;
    rowArray: any;
    disablePagination?: boolean;
}

const TableForm: React.FC<TableFormProps> = ({
    table,
    onClose,
    onSubmit,
    getParentFields,
    title,
    isEdit,
    children,
    docId,
    submitButtonCaption,
    rowIndex,
    rowArray,
    disablePagination,
}: TableFormProps) => {
    const methods = useForm<ITableForm>({
        mode: 'onBlur', // "onChange"
    });
    const [fields, setFields] = useState<Record<string, IFieldElem>>();
    const [rows, setRows] = useState<IRows>();
    let fieldsName: number[] = [];

    const rowKey = rowIndex + 1;
    const [currentRow, setCurrentRow] = useState<any>(rowArray[rowIndex]);
    const [currentRowIndex, setCurrentRowIndex] = useState<number>(rowIndex);

    const [idForm, setIdForm] = useState(() => {
        return uuidv4();
    });

    const generateRows = () => {
        let collRows: IRow[] = [];
        for (let index = 0; index < table.tableColumn.length; index++) {
            const col = table.tableColumn[index];
            let obj = {
                columns: [
                    {
                        fields: [
                            {
                                fieldType: col.fieldType,
                                valueType: col.valueType,
                                key: col.key,
                                name: col.name,
                                changes: col.changes,
                                visibilityRules: col.visibilityRules,
                                isValue: col.isValue,
                                readonly: col.readonly,
                                required: col.required,
                            } as IDocumentField,
                        ],
                    } as IColumn,
                ],
            } as IRow;
            collRows[col.order] = obj;
        }
        for (let index = 0; index < table.tableColumnAbook.length; index++) {
            const col = table.tableColumnAbook[index];
            let obj = {
                columns: [
                    {
                        abooks: [
                            {
                                ...col,
                            } as IDocumentAbook,
                        ],
                    } as IColumn,
                ],
            } as IRow;
            collRows[col.order] = obj;
        }
        for (let index = 0; index < table.tableColumnCalc.length; index++) {
            const col = table.tableColumnCalc[index];
            let obj = {
                columns: [
                    {
                        calcs: [
                            {
                                ...col,
                            } as IDocumentCalc,
                        ],
                    } as IColumn,
                ],
            } as IRow;
            collRows[col.order] = obj;
        }
        for (let index = 0; index < table.tableColumnDict.length; index++) {
            const col = table.tableColumnDict[index];
            let obj = {
                columns: [
                    {
                        dicts: [
                            {
                                ...col,
                            } as IDocumentDict,
                        ],
                    } as IColumn,
                ],
            } as IRow;
            collRows[col.order] = obj;
        }
        for (let index = 0; index < table.tables.length; index++) {
            const col = table.tables[index];
            let obj = {
                columns: [
                    {
                        tables: [
                            {
                                ...col,
                            } as IDocumentTable,
                        ],
                    } as IColumn,
                ],
            } as IRow;

            collRows.push(obj);
        }
        for (let index = 0; index < table.tableColumnExDataSource.length; index++) {
            const col = table.tableColumnExDataSource[index];
            let obj = {
                columns: [
                    {
                        fields: [
                            {
                                fieldType: col.fieldType,
                                valueType: col.valueType,
                                key: col.key,
                                name: col.name,
                                changes: col.changes,
                                visibilityRules: col.visibilityRules,
                                isValue: col.isValue,
                                readonly: col.readonly,
                                required: col.required,
                            } as IDocumentField,
                        ],
                    } as IColumn,
                ],
            } as IRow;
            collRows[col.order] = obj;
        }
        let rows = { rows: { row: collRows } as IRowArr } as IRows;
        return rows;
    };

    const InitFormData = (rows: IRows) => {
        var keys = getFieldKeysByScheme(rows.rows.row);
        let keysColl = Object.keys(currentRow);
        keys = keys.concat(keysColl);
        var uniqueKeys = keys.filter((v, i, a) => v !== undefined && a.indexOf(v) === i);
        let formFields: IField[] = getParentFields();
        for (let index = 0; index < uniqueKeys.length; index++) {
            const key = uniqueKeys[index];
            if (
                key != undefined &&
                formFields.findIndex((x) => {
                    return x.name === key;
                }) === -1
            ) {
                if (currentRow[key] !== undefined) {
                    formFields.push({ name: key, value: currentRow[key] } as IField);
                } else {
                    formFields.push({ name: key, value: '' } as IField);
                }
            }
        }
        const item = {
            id: currentRow['|NUM'],
            fields: formFields,
        } as ITableForm;
        setRows(rows);
        methods.reset(item);
        if (item && item.fields) {
            setFields(convertListToMap(item?.fields));
        }
    };

    useEffect(() => {
        if (table.customForm) {
            DocumentService.getTableScheme(table.customForm).then((data) => {
                InitFormData(data.data.view);
            });
        } else {
            let rows = generateRows();
            InitFormData(rows);
        }
    }, [currentRow]);

    const ModalFooter = ({ onClose }: { onClose: () => void }) => (
        <div className={'dictModal_footer'}>
            <div className={'left'}>
                {isEdit && (
                    <Button
                        type="submit"
                        size="s"
                        disabled={methods.formState.isSubmitting || !methods.formState.isDirty}
                        form={idForm}
                    >
                        {submitButtonCaption ? submitButtonCaption : 'Сохранить'}
                    </Button>
                )}
                <Button onClick={onClose} buttonType="light" size="s">
                    {isEdit ? 'Отменить' : 'Ок'}
                </Button>
            </div>
        </div>
    );
    const dirtyValues = (dirtyFields: any, allValues: ITableForm) => {
        return Object.keys(dirtyFields.fields).map((key) => allValues.fields[+key]);
    };

    const onSubmitHandler = (data: ITableForm) => {
        let dirtyFields = methods.formState.dirtyFields;
        let dirtyData = dirtyValues(dirtyFields, data);
        onSubmit && onSubmit(dirtyData, currentRowIndex);
    };
    const getCurrentPage = (page: number) => {
        let index = page - 1;
        setCurrentRow(rowArray[index]);
        setCurrentRowIndex(index);
        setIdForm(uuidv4());
    };

    return (
        <Modal size="xl" custom={true}>
            <FormProviderControl
                id={idForm}
                formMethods={methods}
                className="doc-form"
                onSubmit={onSubmit ? onSubmitHandler : () => {}}
            >
                <HookFormProvider controller={Controller}>
                    <div className="rf-modal__wrapper">
                        <div className="rf-modal__header">
                            <div className="box">
                                {title}
                                <div className="left">
                                    <Button
                                        startAdornment={<MdClose size="24" />}
                                        buttonType="text"
                                        size="xs"
                                        onClick={onClose}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="rf-modal__content">
                            <div className={'view-form-modal-body'}>
                                {children}

                                <div className="table-form-content">
                                    <FormBuilder
                                        fields={fields!}
                                        docId={docId}
                                        setError={(errors?: string[]) => {
                                            if (errors) {
                                                sendErrorMsg({
                                                    message: errors,
                                                });
                                            }
                                        }}
                                        rows={rows}
                                        isEdit={isEdit}
                                        isNew={false}
                                        formMethods={methods}
                                        includedFields={fieldsName}
                                        uniqueKey={docId ? docId : '-1'}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={'rf-modal__footer'}>{<ModalFooter onClose={onClose} />}</div>
                    </div>
                </HookFormProvider>
            </FormProviderControl>
            {!disablePagination && (
                <Pagination
                    count={rowArray.length}
                    getCurrentPage={getCurrentPage}
                    startingPage={rowKey}
                    hidePageInput={true}
                    className={'view-form-modal__pagination'}
                />
            )}
        </Modal>
    );
};

export default TableForm;
