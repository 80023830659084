import $api from '@/http';
import { IAutoImportExecResult } from '@/models/autoTasks/IAutoImportExecResult';
import { IAutoImportToRun } from '@/models/autoTasks/IAutoImportToRun';
import { AxiosResponse } from 'axios';

export class AutoTaskService {
    async runAutoImport(data: IAutoImportToRun): Promise<AxiosResponse<IAutoImportExecResult>> {
        return $api.post(`/autoTask/runAutoImport/`, data);
    }
}
