import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { INotificationRequest, INotificationSettings } from '@models/notificationSettings/INotificationSettings';

export class NotificationSettingsService {
    static async fetch(): Promise<AxiosResponse<INotificationSettings[]>> {
        return $api.get<INotificationSettings[]>('/NotificationFiltering');
    }

    static async edit(request: INotificationRequest): Promise<AxiosResponse<void>> {
        return $api.post('/NotificationFiltering/edit', request);
    }
}
