import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { RouteMapActionTypes } from '@models/actions/RouteMapActionTypes';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { BaseActionsService } from './BaseActionsService';
import { IBaseAction } from '@models/actions/IBaseAction';
import { AddRouteMapActionExecutor } from '@utils/actions/routeMapExecutors/AddRouteMapActionExecutor';
import { EditRouteMapActionExecutor } from '@utils/actions/routeMapExecutors/EditRouteMapActionExecutor';
import { RemoveRouteMapActionExecutor } from '@utils/actions/routeMapExecutors/RemoveRouteMapActionExecutor';
import { IRouteMapActionResult } from '@models/document/routeMapActionsExecutors/IRouteMapActionModel';
import { IDocumentRouteMapAction } from '@models/document/IDocumentRouteMap';

export class RouteMapActionsService extends BaseActionsService {
    public allExecutors: { [actType: string]: () => IActionExecutor } = {
        [RouteMapActionTypes[RouteMapActionTypes.Add]]: () => new AddRouteMapActionExecutor(),
        [RouteMapActionTypes[RouteMapActionTypes.Edit]]: () => new EditRouteMapActionExecutor(),
        [RouteMapActionTypes[RouteMapActionTypes.Remove]]: () => new RemoveRouteMapActionExecutor(),
    };

    //Получает перечень доступных действий
    async fetchActions(docId: string): Promise<AxiosResponse<IBaseAction[]>> {
        return new Promise<AxiosResponse<IBaseAction[]>>(() => {});
    }

    //Получает перечень доступных групповых действий
    async fetchGroupActions(docIds: string): Promise<AxiosResponse<IBaseAction[]>> {
        // групповые действия не поддерживаются
        return new Promise<AxiosResponse<IBaseAction[]>>(() => {});
    }

    add(
        actionKey: string,
        activityKey: string,
        processKey: string,
        documentKey: string,
        result: IRouteMapActionResult,
    ): Promise<AxiosResponse> {
        return $api.post(`routeMapExecution/add/${actionKey}/${documentKey}/`, result, {
            params: { processKey, activityKey },
        });
    }

    edit(
        actionKey: string,
        activityKey: string,
        processKey: string,
        documentKey: string,
        result: IRouteMapActionResult,
    ): Promise<AxiosResponse> {
        return $api.post(`routeMapExecution/edit/${actionKey}/${documentKey}/${processKey}/${activityKey}`, result);
    }

    remove(actionKey: string, activityKey: string, processKey: string, documentKey: string): Promise<AxiosResponse> {
        return $api.post(`routeMapExecution/remove/${actionKey}/${documentKey}/${processKey}/${activityKey}`);
    }

    static async fetchActionConfig(
        documentKey: string,
        actionKey: string,
    ): Promise<AxiosResponse<IDocumentRouteMapAction>> {
        return $api.get<IDocumentRouteMapAction>(`routeMapExecution/config/${actionKey}/${documentKey}`);
    }
}
