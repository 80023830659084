import React, { FC } from 'react';
import './DocumentTabAttach.scss';

import { TableTab } from '@models/document/IDocumentScheme';
import { UseFormReturn } from 'react-hook-form';
import { IDocumentData } from '@models/document/IDocumentData';
import { IFieldElem } from '@models/IFormData';
import DocumentTabAttachTable from './DocumentTabAttachTable';

export interface IDocumentTabAttachProps {
    /** ИД документа */
    docId: string;
    /** Таблицы */
    tables: TableTab[];
    formMethods: UseFormReturn<IDocumentData>;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
}

const DocumentTabAttach: FC<IDocumentTabAttachProps> = ({
    docId,
    tables,
    formMethods,
    isEdit,
    isNew,
    fields,
}: IDocumentTabAttachProps) => {
    return (
        <div className="attach-tab">
            {tables.map((element) => {
                return (
                    <DocumentTabAttachTable
                        key={element.key}
                        attachTable={element}
                        formMethods={formMethods}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        docId={docId}
                    />
                );
            })}
        </div>
    );
};

export default DocumentTabAttach;
