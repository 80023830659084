import React, { useEffect, useRef, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import { classnames } from '@utils/classnames';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';
import Preloader from '@atoms/Preloader';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import { DataGrid } from 'devextreme-react/data-grid';

import './AttachFilesExecutor.scss';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import Hint from '@atoms/Hint';
import { ModalSize } from '@atoms/Modal/Modal';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { AttachFilesBlock, IAttachFilesBlockRefActions } from '@/components/atoms/AttachFilesBlock/AttachFilesBlock';

export class AttachFilesExecutor extends DocBaseActionExecutor {
    private _modalProps?: IAttachFilesExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            action: action,
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <AttachFilesExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface IAttachFilesExecutorProps {
    docId: string;
    action: IBaseAction;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const AttachFilesExecutorModal: React.FC<IAttachFilesExecutorProps> = (props: IAttachFilesExecutorProps) => {
    const [isBusy, setIsBusy] = useState<boolean>();
    const [errorText, setErrorText] = useState<string>();
    const [attachesCreateModel, setAttachesCreateModel] = useState<IAttachesCreateModel>();
    const childFilesBlock = useRef<IAttachFilesBlockRefActions | null>(null);

    useEffect(() => {
        setIsBusy(true);
        let service = new AttachActionsService(props.docId, '-1');
        service.getAttachInfo(props.action.key).then((res) => {
            setAttachesCreateModel(res.data);
            setIsBusy(false);
        });
    }, []);

    const gridRef = useRef<DataGrid>(null);
    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>Присоединить файлы</div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {isBusy ? (
                <Preloader size="m" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}
                    {attachesCreateModel ? (
                        <AttachFilesBlock ref={childFilesBlock} model={attachesCreateModel} />
                    ) : (
                        <></>
                    )}

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                size="s"
                                aria-label={props.okButtonText}
                                onClick={() => {
                                    if (!childFilesBlock.current?.isValid()) return;

                                    setIsBusy(true);
                                    let uploadingFiles = childFilesBlock.current.getData();

                                    let service = new DocumentExecutionService(props.docId);
                                    service
                                        .attachFiles(
                                            props.action.key,
                                            uploadingFiles.attachProperties,
                                            uploadingFiles.files,
                                        )
                                        .then(() => {
                                            if (props.completeHandler) props.completeHandler(true);
                                        })
                                        .catch((error) => setErrorText(error))
                                        .finally(() => {
                                            setIsBusy(false);
                                        });
                                }}
                            >
                                {props.okButtonText}
                            </Button>

                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
