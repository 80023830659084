import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { useWatch } from 'react-hook-form';
import { FormulaManager } from '@utils/FormulaManager';
import { handlerFieldWatch } from '@utils/documentUtils';
import { IDocumentTab } from '../DocumentTabLabel/DocumentTabLabel';

export interface IDocumentTabMenuContainer {
    tab: IDocumentTab;
    children: ReactNode;
}

const DocumentTabMenuContainer: FC<IDocumentTabMenuContainer> = ({ tab, children }: IDocumentTabMenuContainer) => {
    const document = useTypedSelector((state) => state.document);
    let visibilityRules = tab.visibilityRules;
    const visibilityMng = new FormulaManager(visibilityRules!);
    visibilityMng.Init(tab.fields);
    const activated = React.useRef(false);
    const [visibility, setVisibility] = useState<boolean>(false);
    const InitFormulas = async () => {
        let result = await visibilityMng.EvalFormulaValues(document.isEdit, document.isNew);
        if (activated.current) {
            setVisibility(result);
        }
    };
    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);
    const watchVisibility = useWatch({
        name: visibilityMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watchVisibility, document.isEdit, document.isNew, visibilityMng, setVisibility, activated);
    }, [watchVisibility, document.isEdit, document.isNew, document.isNew]);

    return visibility ? <>{children}</> : <></>;
};

export default DocumentTabMenuContainer;
