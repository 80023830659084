import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IHistorySearchItem } from '@models/search/IHistorySearchItem';
import { ISearchData } from '@models/search/ISearchData';
import { ISearchDictResults } from '@models/search/ISearchDictResultItem';
import { ISearchDocResults } from '@models/search/ISearchDocResultItem';
import { ISearchExtraFlowSetting } from '@models/search/ISearchExtraFilters';
import { ISearchReportResults } from '@models/search/ISearchReportResultItem';
import { DEFAULT_PAGE_SIZE } from '@/store/reducers/SearchReducer';
import { IForms } from '@/models/Forms/IForms';
import { ITabsVisibility } from '@/models/search/ITabsVisibility';
import { IResultTilesSetting } from '@/models/search/IResultTilesSetting';

export class SearchService {
    static async searchDocuments(searchData: ISearchData): Promise<AxiosResponse<ISearchDocResults>> {
        return $api.post<ISearchDocResults>('/search/searchDocs', {
            text: searchData?.text,
            skip: (searchData.pageIndex ?? 0) * (searchData.pageSize ?? DEFAULT_PAGE_SIZE),
            take: searchData.pageSize ?? DEFAULT_PAGE_SIZE,
            orderByName: searchData?.orderBy?.propName,
            orderByAsc: searchData?.orderBy?.asc,
            requireTotalCount: searchData.requireTotalCount ?? false,

            extraFilter: {
                // execDep: searchData.extraFilter?.execDep,
                // purchDep: searchData.extraFilter?.purchDep,
                // status: searchData.extraFilter?.status,
                // authorName: searchData.extraFilter?.authorName,
                // regDateFrom: searchData.extraFilter?.regDateFrom,
                // regDateTo: searchData.extraFilter?.regDateTo, 

                baseRequisites: searchData.extraFilter?.baseRequisites ?? {},

                selectedFlowName: searchData.extraFilter?.flowsFields?.selectedFlow?.key,
                fieldsValues: searchData.extraFilter?.flowsFields?.fieldsValues,
            },
        });
    }

    static async searchDicts(searchData: ISearchData): Promise<AxiosResponse<ISearchDictResults>> {
        return $api.get<ISearchDictResults>(
            `/search/searchDicts?text=${searchData?.text}&skip=${
                (searchData.pageIndex ?? 0) * (searchData.pageSize ?? 0)
            }&take=${searchData.pageSize ?? DEFAULT_PAGE_SIZE}&orderByName=${
                searchData.orderBy?.propName ?? ''
            }&orderByAsc=${searchData.orderBy?.asc ?? false}&requireTotalCount=${
                searchData.requireTotalCount ?? false
            }`,
        );
    }

    static async searchReports(searchData: ISearchData): Promise<AxiosResponse<ISearchReportResults>> {
        return $api.get<ISearchReportResults>(
            `/search/searchReports?text=${searchData?.text}&skip=${
                (searchData.pageIndex ?? 0) * (searchData.pageSize ?? 0)
            }&take=${searchData.pageSize ?? DEFAULT_PAGE_SIZE}&orderByName=${
                searchData.orderBy?.propName ?? ''
            }&orderByAsc=${searchData.orderBy?.asc ?? false}&requireTotalCount=${
                searchData.requireTotalCount ?? false
            }`,
        );
    }

    static async loadExtraFilterFlowsData(): Promise<AxiosResponse<ISearchExtraFlowSetting[]>> {
        return $api.get<ISearchExtraFlowSetting[]>(`/search/getFlowSearchReqs`);
    }

    static async loadHistorySearchItems(): Promise<AxiosResponse<IHistorySearchItem[]>> {
        return $api.get<IHistorySearchItem[]>(`/search/historySearchItems`);
    }

    static async removeHistorySearchItem(id: number): Promise<AxiosResponse<IHistorySearchItem[]>> {
        return $api.delete<IHistorySearchItem[]>(`/search/historySearchItems/${id}`);
    }

    static async getSearchPanelScheme(){
        return $api.get<IForms>(`/search/getSearchScheme`);
    }

    static async getTabsVisibility(){
        return $api.get<ITabsVisibility>(`/search/getTabsVisibility`);
    }

    static async getResultTilesSetting(){
        return $api.get<IResultTilesSetting>(`/search/getResultTilesSetting`);
    }
}
