import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IFileInfo } from '@models/response/IFileInfo';
import { ITreeListDescriptionResponse } from '@models/response/ITreeListDescriptionResponse';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import { IGridResponse } from '@models/response/IGridResponse';
import { getLoadOptionsQuery } from '@utils/dataGridUtils';
import { IDataTreeService } from './IDataTreeService';

export class DocumentRoutesService extends IDataTreeService {
    async fetchScheme(data: any): Promise<AxiosResponse<ITreeListDescriptionResponse, any>> {
        return $api.get<ITreeListDescriptionResponse>(`/document/routesScheme/${data}`);
    }
    async fetchData(data: any, parentData: any, loadOptions: any): Promise<AxiosResponse<IGridResponse, any>> {
        return $api.get<IGridResponse>(`/document/routesData/${data}/${parentData}${loadOptions}`);
    }

    static async autoConfirmLookFors(id: any, openDocumentGuid: string | undefined): Promise<AxiosResponse<any, any>> {
        return $api.get<any>(`/document/autoConfirmLookFors/${id}`, {
            params: {
                openDocumentGuid: openDocumentGuid,
            },
        });
    }

    async exportData(
        template: string,
        options: any,
        columns: IGridExportSettings[],
    ): Promise<AxiosResponse<IFileInfo>> {
        return $api.post<IFileInfo>(`/document/export?template=${template}&${getLoadOptionsQuery(options)}`, columns);
    }
}
