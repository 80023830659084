import { AxiosResponse } from 'axios';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { IActionService } from './IActionService';

export abstract class BaseActionsService implements IActionService<IBaseAction> {
    public abstract allExecutors: { [actType: string]: () => IActionExecutor };

    public getActionExecutor(act: IBaseAction): IActionExecutor {
        let createExecutor = this.allExecutors[act.type];
        if (!createExecutor) throw new Error(`Не обнаружен ActionExecutor для типа ${act.type}`);
        return createExecutor();
    }

    abstract fetchActions(objId: string): Promise<AxiosResponse<IBaseAction[], any>>;

    abstract fetchGroupActions(objIds: string): Promise<AxiosResponse<IBaseAction[], any>>;
}
