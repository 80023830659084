import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IReportData } from '@models/reports/IReportData';
import { IReportScheme } from '@models/reports/IReportScheme';
import { IReportFormats } from '@models/reports/IReportFormats';
import { IFileInfo } from '@models/response/IFileInfo';

export class ReportsService {
    static async getData(folder: string, name: string): Promise<AxiosResponse<IReportData>> {
        return $api.get(`reports/data/get?folder=${folder}&name=${name}`);
    }
    static async getScheme(formName: string): Promise<AxiosResponse<IReportScheme>> {
        return $api.get(`reports/scheme/get?name=${formName}`);
    }
    static async getFormats(folder: string, name: string): Promise<AxiosResponse<IReportFormats>> {
        return $api.get(`reports/formats/get?folder=${folder}&name=${name}`);
    }
    static async build(
        folder: string,
        name: string,
        format: string,
        data: IReportData,
    ): Promise<AxiosResponse<IFileInfo>> {
        return $api.post(`reports/build?folder=${folder}&name=${name}&format=${format}`, data);
    }
}
