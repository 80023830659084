import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useActions } from '@hooks/useActions';
import { useTypedSelector } from '@hooks/useTypedSelector';
import Button from '@atoms/Button';
import Box from '@atoms/Box';
import Hint from '@atoms/Hint';
import logo from '@images/logo.svg';
import './LoginForm.scss';
import { Link, useNavigate } from 'react-router-dom';
import HookFormProvider from '@controls/HookFormProvider';
import { Controller, useForm } from 'react-hook-form';
import { InputControl, InputPasswordControl } from '@controls/index';
import FormProviderControl from '@controls/FormProviderControl';
import { RouteNames } from '@/router';
import CheckboxControl from '@controls/CheckboxControl';
import Countdown from 'react-countdown';
import { CountdownRenderProps } from 'react-countdown/dist/Countdown';
import { TwoFactorAuthenticationService } from '@/services/TwoFactorAuthenticationService';
import { ConfigService } from '@/configuration/services/configService';
import OTPInput from 'react-otp-input';

export const CUSTOM_LOGIN_TYPE = 'CUSTOM';
export const SSO_LOGIN_TYPE = 'SSO';
export const SAML_LOGIN_TYPE = 'SAML';

interface ILoginFormData {
    login: string;
    password: string;
    rememberMe: boolean;
}

const LoginForm: FC = () => {
    const config = ConfigService.get();
    const authConfig = config.application.authentication;
    const twoFactorServ = useMemo(() => new TwoFactorAuthenticationService(), []);
    const error = useTypedSelector((state) => state.auth).error;
    const isLoggingIn = useTypedSelector((state) => state.auth.isLoggingIn);
    const { login, loginByHeader, loginTwoFactor } = useActions();
    const navigate = useNavigate();
    const [isActiveHiddenControls, setIsActiveHiddenControls] = useState<boolean>(false);

    // -------------------------------------------------------------------------------------------------------------------
    // OTP
    // -------------------------------------------------------------------------------------------------------------------
    const isSmsOtp = useTypedSelector((state) => state.auth.isSmsOtp);
    const [otp, setOtp] = useState<string>('');
    const [isSendingSmsOtp, setIsSendingSmsOtp] = useState<boolean>(false);
    const otpNumInputs = authConfig.otpNumInputs ?? 4;
    const otpInputType = authConfig.otpInputType ?? 'number';
    // -------------------------------------------------------------------------------------------------------------------

    const methods = useForm<ILoginFormData>({
        mode: 'onBlur', // "onChange"
    });

    useEffect(() => {
        if (authConfig.autoHeaderLoginHostname && authConfig.autoHeaderLoginHostname === window.location.hostname) {
            // автоматический логин по хидерам на указанном домене
            onLoginByHeader();
        }
    }, []);

    const onLoginByHeader = () => {
        loginByHeader();
    };

    const onLoginBySaml = async () => {
        navigate(RouteNames.ADFS_SAML_SSO_SIGN_IN_REDIRECT, {
            replace: true,
        });
    };

    const onActiveHiddenControls = async () => {
        setIsActiveHiddenControls(true);
    };

    const onSubmit = (form: ILoginFormData) => {
        if (authConfig.twoFactorAuthenticationEnabled) {
            loginTwoFactor(twoFactorServ, form.login, form.password, () =>
                login(form.login, form.password, form.rememberMe),
            );
        } else {
            login(form.login, form.password, form.rememberMe);
        }
    };

    const errorLabel = error !== '' && (
        <div className="rf-login__error">
            <Hint title={error} variant="red" />
        </div>
    );

    const countdownRenderer = ({ total }: CountdownRenderProps) => {
        let totalSeconds = total / 1000;
        return (
            <Hint
                icon="info"
                title={`Подтвердите вход на вашем мобильном устройстве. Осталось: ${totalSeconds} секунд`}
                variant="yellow"
                maxWidth="100%"
                className="rf-login__twoFactorCountdown"
            />
        );
    };

    const handleOTPChange = (otp: string) => {
        setOtp(otp);
    };

    const confirmOTP = () => {
        setIsSendingSmsOtp(true);
        twoFactorServ.sendSmsOtp(otp).finally(() => {
            setIsSendingSmsOtp(false);
        });
    };

    const checkLoginButtonVisibility = useCallback(
        (type: string) => {
            if (!authConfig.controls.enabled.includes(type)) return false;
            if (isActiveHiddenControls) return true;
            return !authConfig.controls.hidden.includes(type);
        },
        [isActiveHiddenControls],
    );

    const checkActiveHiddenControlsButton = useCallback(() => {
        return authConfig.showActiveHiddenControlsButton && !isActiveHiddenControls;
    }, [isActiveHiddenControls]);

    return (
        <div className="rf-login">
            <Box className="rf-login__box">
                <div className="rf-login__logoWrapper">
                    <img className="rf-login__logo" src={logo} alt={'Логотип'} />
                </div>
                {config.application.loginTitle && (
                    <div className="rf-login__title">{config.application.loginTitle}</div>
                )}
                <div className="rf-login__welcomeText">Вход в учётную запись</div>
                <div className="rf-login__form">
                    {errorLabel}
                    {checkLoginButtonVisibility(CUSTOM_LOGIN_TYPE) && (
                        <FormProviderControl
                            formMethods={methods}
                            onSubmit={onSubmit}
                            autoComplete={'on'}
                            ignoreEnterSubmit={false}
                        >
                            <HookFormProvider controller={Controller}>
                                <InputControl
                                    label="Имя пользователя"
                                    readOnly={false}
                                    required={true}
                                    rules={{ required: 'Обязательное поле' }}
                                    id="login"
                                    name="login"
                                    disabled={isLoggingIn}
                                    formState={methods.formState}
                                    control={methods.control}
                                    className={'rf-login__login'}
                                />
                                <InputPasswordControl
                                    label="Пароль"
                                    readOnly={false}
                                    required={true}
                                    rules={{ required: 'Обязательное поле' }}
                                    id="password"
                                    name="password"
                                    disabled={isLoggingIn}
                                    formState={methods.formState}
                                    control={methods.control}
                                    className={'rf-login__password'}
                                />

                                <div className="rf-login__forgotPasswordWrapper">
                                    {authConfig.rememberMeEnabled && (
                                        <div className="rf-login__rememberMeWrapper">
                                            <CheckboxControl
                                                label="Запомнить меня"
                                                id="rememberMe"
                                                name="rememberMe"
                                                disabled={isLoggingIn}
                                                formState={methods.formState}
                                                control={methods.control}
                                                className="rf-login__rememberMe"
                                            />
                                        </div>
                                    )}

                                    {config.application.selfRegistration.restorePasswordEnabled && (
                                        <Link
                                            to={RouteNames.RESET_PASSWORD_REQUEST}
                                            className="rf-login__forgotPassword"
                                        >
                                            Не помню пароль
                                        </Link>
                                    )}
                                </div>

                                <Button
                                    type="submit"
                                    preloader={isLoggingIn}
                                    className="rf-login__button rf-login__loginButton"
                                    disabled={isLoggingIn}
                                >
                                    Войти
                                </Button>
                                {authConfig.twoFactorAuthenticationEnabled && isLoggingIn ? (
                                    <Countdown
                                        key={+isLoggingIn}
                                        date={Date.now() + 120000}
                                        renderer={countdownRenderer}
                                    />
                                ) : null}
                                {authConfig.twoFactorAuthenticationEnabled && isLoggingIn && isSmsOtp ? (
                                    <div className="rf-login__otpWrapper">
                                        <div className="rf-login__otpTitle">Введите код из СМС</div>
                                        <div className="rf-login__otpInput">
                                            <OTPInput
                                                containerStyle="otpStyle"
                                                inputStyle="otpInputStyle"
                                                numInputs={otpNumInputs}
                                                onChange={handleOTPChange}
                                                value={otp}
                                                inputType={otpInputType}
                                                renderInput={(props) => <input {...props} disabled={isSendingSmsOtp} />}
                                                shouldAutoFocus
                                            />
                                        </div>
                                        <div className="rf-login__otpConfirm">
                                            <Button
                                                preloader={isSendingSmsOtp}
                                                onClick={confirmOTP}
                                                className={'rf-login__button rf-login__otpConfirmButton'}
                                                disabled={isSendingSmsOtp || otp.length < otpNumInputs}
                                            >
                                                Подтвердить
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                            </HookFormProvider>
                        </FormProviderControl>
                    )}
                    {checkLoginButtonVisibility(CUSTOM_LOGIN_TYPE) &&
                        (checkLoginButtonVisibility(SSO_LOGIN_TYPE) ||
                            checkLoginButtonVisibility(SAML_LOGIN_TYPE) ||
                            checkActiveHiddenControlsButton()) && <div className="rf-login__separator">или</div>}
                    <div className="rf-login__altLoginButtons">
                        {checkLoginButtonVisibility(SSO_LOGIN_TYPE) && (
                            <div className="rf-login__headersLoginBlock">
                                <Button
                                    preloader={isLoggingIn}
                                    onClick={onLoginByHeader}
                                    className={'rf-login__button rf-login__loginHeadersButton'}
                                    disabled={isLoggingIn}
                                >
                                    Войти через SSO
                                </Button>
                            </div>
                        )}
                        {checkLoginButtonVisibility(SAML_LOGIN_TYPE) && (
                            <div className="rf-login__headersLoginBlock">
                                <Button
                                    preloader={isLoggingIn}
                                    onClick={onLoginBySaml}
                                    className={'rf-login__button rf-login__loginHeadersButton'}
                                    disabled={isLoggingIn}
                                >
                                    Войти через ADFS
                                </Button>
                            </div>
                        )}
                        {checkActiveHiddenControlsButton() && (
                            <div className="rf-login__headersLoginBlock">
                                <Button
                                    onClick={onActiveHiddenControls}
                                    className={'rf-login__button rf-login__loginHeadersButton'}
                                >
                                    Другие варианты входа
                                </Button>
                            </div>
                        )}
                    </div>

                    {config.application.selfRegistration.signUpEnabled && (
                        <div className="rf-login__signUpWrapper">
                            <span>
                                Нет учётной записи?{' '}
                                <Link to={RouteNames.SIGN_UP} className="rf-login__signUp">
                                    Зарегистрируйтесь
                                </Link>
                            </span>
                        </div>
                    )}
                </div>
            </Box>
        </div>
    );
};

export default LoginForm;
