import React, { useEffect, useState } from 'react';

import './SignCheckContainer.scss';
import { ISignCheck } from '@models/Forms/IForms';
import { ISignVerificationResult } from '@models/attaches/IAttachViewSignModel';

import { CadespluginService } from '@services/CadespluginService';
import { MdCheckCircle, MdError } from 'react-icons/md';

export interface SignCheckContainerProps {
    signCheck: ISignCheck;
    rowData: any;
}

const SignCheckContainer: React.FC<SignCheckContainerProps> = ({ signCheck, rowData }: SignCheckContainerProps) => {
    const [checkResult, setCheckResult] = useState<ISignVerificationResult>();
    const activated = React.useRef(false);
    const InitData = async () => {
        //делаем проверку подписи
        let dataToVerify = rowData[signCheck.fieldToVerifyInBase64];
        let signToVerify = rowData[signCheck.fieldSignInBase64];

        if (!signToVerify) return;

        let serv = new CadespluginService();
        let data = await serv.verifyDataWithoutHash(dataToVerify, signToVerify);

        if (activated.current) {
            setCheckResult(data);
        }
    };
    useEffect(() => {
        activated.current = true;
        InitData();
        return () => {
            activated.current = false;
        };
    }, []);

    return checkResult ? (
        <div className="sign-check-container">
            <div className="sign-check-Row">
                <div className="sign-check-Icon">{checkResult.suceeded ? <MdCheckCircle /> : <MdError />}</div>
                <div className="sign-check-Msg">
                    {checkResult.suceeded
                        ? 'Подпись успешно проверена'
                        : `Ошибка проверки подписи: ${checkResult.errorText}`}
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default SignCheckContainer;
