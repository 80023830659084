import React, { useState } from 'react';

import './TableData.scss';
import { IDocumentTable } from '@models/Forms/IForms';
import TableForm from './ModalForms/TableForm';
import SignCheckContainer from './ModalForms/SignCheckContainer';
import { IField } from '@models/IFormData';

export interface OpenDocButtonProps {
    table: IDocumentTable;
    rowData: any;
    docId?: string;
}

const OpenDocButton: React.FC<OpenDocButtonProps> = ({ table, rowData, docId }: OpenDocButtonProps) => {
    let docIdRow = table.previewDocByKey ? rowData[table.previewDocByKey] : undefined;

    return (
        <>
            {docIdRow && (
                <a
                    href={'/document/' + docIdRow}
                    className="dx-link dx-link-delete dx-icon-doc dx-link-icon"
                    title="Просмотреть"
                    target={'_blank'}
                    aria-label="Просмотреть"
                ></a>
            )}
        </>
    );
};

export default OpenDocButton;
