import React, { useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';

import './CreateDocumentTemplateExecutor.scss';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { ModalSize } from '@atoms/Modal/Modal';
import { useForm } from 'react-hook-form';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import { InputControl } from '@controls/index';

export class CreateDocumentTemplateExecutor extends DocBaseActionExecutor {
    private _modalProps?: ICreateDocumentTemplateExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            message: action.options?.message ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <CreateDocumentTemplateExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ICreateDocumentTemplateExecutorProps {
    docId: string;
    actionKey: string;
    title: string;
    message: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

interface ICreateDocumentTemplateFormData {
    templateName: string;
}

const CreateDocumentTemplateExecutorModal: React.FC<ICreateDocumentTemplateExecutorProps> = (
    props: ICreateDocumentTemplateExecutorProps,
) => {
    const [templateName, setTemplateName] = useState<string>('');
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    let service = new DocumentExecutionService(props.docId);

    const onSubmit = (form: ICreateDocumentTemplateFormData) => {
        setLoading(true);

        return service
            .createDocumentTemplate(props.actionKey, templateName)
            .then((result) => {
                props.completeHandler && props.completeHandler(true);
                formState.reset({ templateName: '' } as ICreateDocumentTemplateFormData);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const formState = useForm<ICreateDocumentTemplateFormData>({
        mode: 'onBlur', // "onChange"
    });

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            <InputControl
                label="Название шаблона"
                readOnly={false}
                required={true}
                rules={{ required: 'Обязательное поле' }}
                name="templateName"
                formState={formState.formState}
                control={formState.control}
                onValueChange={(val: any) => {
                    setTemplateName(val);
                }}
            />
        </ModalContainer>
    );
};
