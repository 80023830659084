import React, { ReactNode } from 'react';
import { IControlProps, IPartialFormGroup } from '@controls/types';
import FormControl from '../FormControl';
import Checkbox, { ICheckboxProps } from '@atoms/Checkbox/Checkbox';
import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';

export type ICheckboxBitControlProps = ICheckboxProps &
    Omit<IControlProps, 'defaultValue'> & {
        formGroupProps?: IPartialFormGroup;
        tooltip?: ReactNode;
    };

const CheckboxBitControl = ({
    name,
    rules,
    control,
    formState,
    shouldUnregister,
    formGroupProps,
    tooltip,
    ...props
}: ICheckboxBitControlProps) => {
    const Controller = useHookFormController();

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            render={({ field: { ref, value, ...fieldProps } }) => (
                <FormControl name={name} formState={formState} {...formGroupProps} hideErrorMessage={true}>
                    <Checkbox
                        {...fieldProps}
                        {...props}
                        label={
                            <div className="rf-form-group__label">
                                {typeof props.label === 'string' ? (
                                    <span
                                        className="rf-form-group-fl"
                                        dangerouslySetInnerHTML={{ __html: props.label }}
                                    />
                                ) : (
                                    <span className="rf-form-group-fl">{props.label}</span>
                                )}
                                <span className="rf-form-group-fl"> {tooltip}</span>
                            </div>
                        }
                        defaultChecked={value && (value === 1 || value === '1') ? true : false}
                        onChange={(e) => {
                            let val = e.currentTarget.checked ? 1 : 0;

                            if (value === undefined || value === null || value.toString() != val.toString()) {
                                fieldProps.onChange(val);
                                if (props?.onChange) {
                                    props?.onChange(e);
                                }
                            }
                        }}
                    />
                </FormControl>
            )}
        />
    );
};

export default CheckboxBitControl;
