import './WinnerSelectionTablePositions.scss';
import React, { useEffect, useRef, useState } from 'react';
import { ICustomComponentProps } from '@molecules/formbuilder/controls/CustomComponent/CustomComponent';
import { DataGrid } from 'devextreme-react/data-grid';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import Box from '@atoms/Box';
import WinnerSelectionTableDemand from '../WinnerSelectionTableDemand/WinnerSelectionTableDemand';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { IPosition, ITablePositionsSettings } from '@models/winnerSelection/ITablePositions';
import { formatPrice } from '@utils/helpers';

export interface IWinnerSelectionTablePositionsProps<TFieldValues extends object = object>
    extends ICustomComponentProps<TFieldValues> {}

const WinnerSelectionTablePositions = <TFieldValues extends object = object>({
    component,
    formMethods,
    isEdit,
    isNew,
    fields,
    docId,
    ...props
}: IWinnerSelectionTablePositionsProps<TFieldValues>) => {
    const activated = React.useRef(false);
    const gridRef = useRef<DataGrid>(null);

    const [positions, setPositions] = useState<IPosition[]>();
    const [settings, setSettings] = useState<ITablePositionsSettings>();
    const [errorText, setErrorText] = useState<string>();

    const tableKey = component.params?.tableKey;

    const loadData = () => {
        gridRef.current?.instance.beginCustomLoading('Загрузка');

        if (!tableKey) {
            console.error('WinnerSelectionTablePositions. Ошибка загрузки данных (tableKey пустой)');
            return;
        }

        WinnerSelectionService.fetchTablePositionsData(docId, tableKey)
            .then((response) => {
                setPositions(response.data.positions);
                setSettings(response.data.settings);
            })
            .catch((reason) => {
                setErrorText(reason);
            })
            .finally(() => {
                gridRef.current?.instance.endCustomLoading();
            });
    };

    useEffect(() => {
        activated.current = true;

        loadData();

        return () => {
            activated.current = false;
        };
    }, []);

    return (
        <div className="winnerSelectionTablePositions-wrapper">
            {errorText ? (
                <Hint icon="info" title={`Ошибка загрузки: ${errorText}`} variant="red" maxWidth="100%" />
            ) : null}
            {positions !== undefined ? (
                positions.length === 0 ? (
                    <div className="winnerSelectionTablePositions__empty">
                        <h1>{settings?.emptyTextHeader}</h1>
                        <p>{settings?.emptyText}</p>
                    </div>
                ) : (
                    positions.map((position) => {
                        return (
                            <Box
                                key={position.key}
                                header={`Позиция ${position.name || ''}. 
                                Количество: ${position.quantity !== null && position.quantity !== undefined ? position.quantity : ''}. 
                                Единица измерения: ${position.units || ''}. 
                                Стоимость: ${formatPrice(position.price, position.currencyCode)} (${position.currencyText || ''})`}
                                collapseEnabledOverride={true}
                            >
                                <WinnerSelectionTableDemand
                                    isEdit={isEdit}
                                    isNew={isNew}
                                    fields={fields}
                                    component={component}
                                    formMethods={formMethods}
                                    docId={position.key}
                                />
                            </Box>
                        );
                    })
                )
            ) : (
                <div className={'winnerSelectionTablePositions__loading'}>
                    <Preloader inline={true} size="l" />
                </div>
            )}
        </div>
    );
};

export default WinnerSelectionTablePositions;
