import React, { ReactNode } from 'react';

import { IControlProps, IPartialFormGroup } from '@controls/types';
import FormControl from '../FormControl';
import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';
import DateTimePicker, { IDateTimePickerProps } from '@atoms/DateTimePicker/DateTimePicker';

export type IDateTimePickerControlProps = IDateTimePickerProps &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        tooltip?: ReactNode;
        required?: boolean;
        onChangeVal: (value: Date) => void;
    };

const DateTimepickerControl = ({
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
    formState,
    label,
    tooltip,
    required,
    formGroupProps,
    isNoSecFormat = true,
    onChangeVal,
    ...props
}: IDateTimePickerControlProps) => {
    const Controller = useHookFormController();

    const getMinDate = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    };
    const getMaxDate = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 0, 0);
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            defaultValue={defaultValue}
            render={({ field: { ref, onChange, value, ...fieldProps } }) => (
                <FormControl
                    name={name}
                    label={label}
                    tooltip={tooltip}
                    required={required}
                    formState={formState}
                    {...formGroupProps}
                >
                    <DateTimePicker
                        {...fieldProps}
                        {...props}
                        minDate={props.minDate ? getMinDate(props.minDate as Date) : props.minDate}
                        maxDate={props.maxDate ? getMaxDate(props.maxDate as Date) : props.maxDate}
                        defaultValue={value}
                        onChangeDate={(e) => {
                            onChange(e);
                            onChangeVal(e);
                        }}
                        showTailIcon={false}
                    />
                </FormControl>
            )}
        />
    );
};

export default DateTimepickerControl;
