import React, { FC, useEffect, useState } from 'react';
import './Documentation.scss';
import PageWraper from '@atoms/PageWraper';
import { DocumentationService } from '@services/DocumentationService';
import { ITab } from '@/types';
import Tabs from '@atoms/Tabs';
import DocumentationTabItem from './DocumentationTabItem';

const Documentation: FC = () => {
    const activated = React.useRef(false);
    const [tabs, setTabs] = useState<ITab[]>();

    const Init = async () => {
        let coll = await DocumentationService.getFileCatalogs();
        let _tabs: ITab[] = [];

        coll.data.forEach((element) => {
            let tab = {
                label: element.name,
                tab: <DocumentationTabItem fileCatalog={element} />,
            } as ITab;
            _tabs.push(tab);
        });
        if (activated.current) {
            setTabs(_tabs);
        }
    };

    useEffect(() => {
        activated.current = true;
        Init();
        return () => {
            activated.current = false;
        };
    }, []);

    return (
        <PageWraper title="Документация">
            <div className="documentation-container">{tabs && <Tabs list={tabs} />}</div>
        </PageWraper>
    );
};

export default Documentation;
