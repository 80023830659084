import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDictionaryDetailedItems, IDictionaryForm } from '@models/dictionary/IDictionaryDetailedItems';

export interface IDictionaryState {
    forms?: IDictionaryForm[];
    tmpFormDict?: string;
    rootDictionaryName: string;
    rootDictionaryId: string;
    isLoading: boolean;
    isFavorite: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canCreate: boolean;
    codeTitle?: string;
    nameTitle?: string;
}

const initialState: IDictionaryState = {
    forms: undefined,
    tmpFormDict: undefined,
    isLoading: true,
    rootDictionaryName: '',
    rootDictionaryId: '',
    isFavorite: false,
    canEdit: false,
    canDelete: false,
    canCreate: false,
};

export const DictionarySlice = createSlice({
    name: 'dictionary',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsFavorite: (state, action: PayloadAction<boolean>) => {
            state.isFavorite = action.payload;
        },

        successLoadDictionaryScheme: (state, action: PayloadAction<IDictionaryDetailedItems>) => {
            if (action.payload.template) {
                state.tmpFormDict = action.payload.template;
                if (action.payload.dictionaryName) state.rootDictionaryName = action.payload.dictionaryName;
                else state.rootDictionaryName = '';
                state.forms = undefined;
            } else {
                state.tmpFormDict = '';
                state.rootDictionaryName = action.payload.dictionaryName;
                state.forms = action.payload.forms;
            }
            state.rootDictionaryId = action.payload.dictionaryId;
            state.canEdit = action.payload.canEdit;
            state.canDelete = action.payload.canDelete;
            state.canCreate = action.payload.canCreate;
            state.codeTitle = action.payload.codeTitle;
            state.nameTitle = action.payload.nameTitle;
        },

        reset: (state) => {
            state.forms = undefined;
            state.tmpFormDict = undefined;
            state.isLoading = true;
            state.rootDictionaryName = '';
            state.rootDictionaryId = '';
            state.isFavorite = false;
            state.canEdit = false;
            state.canDelete = false;
            state.canCreate = false;
        },
    },
});

export const { setIsLoading } = DictionarySlice.actions;

export default DictionarySlice.reducer;
