import React from 'react';
import Chip from '@atoms/Chip';
import { classnames } from '@utils/classnames';
import { IChipProps } from '@atoms/Chip/Chip';

import './DictChipItem.scss';

export interface IDictChipItemProps extends Omit<IChipProps, 'children' | 'onRemove' | 'onClick'> {
    /** Значение */
    item?: string;
    /** Показывать иконку удаления
     * @default true
     */
    showRemoveIcon?: boolean;
    /** Функция удаления элемента */
    onRemove?: (item: string) => void;
    /** Функция клика по элементу */
    onClick?: () => void;
    /** Класс */
    className?: string;
}

const DictChipItem = ({ item, showRemoveIcon = true, onRemove, onClick, className, ...props }: IDictChipItemProps) => {
    if (!item) {
        return null;
    }

    return (
        <div className={classnames('rf-dictchipitem', className)}>
            <Chip
                size="s"
                type="outline"
                maxLength={30}
                tooltipBackground={'white'}
                onRemove={showRemoveIcon ? () => onRemove?.(item) : undefined}
                onClick={onClick}
                iconPosition="right"
                {...props}
            >
                {item}
            </Chip>
        </div>
    );
};

export default DictChipItem;
