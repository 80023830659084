export enum AttachActionTypes {
    // Скачать
    Download,
    // Создать версию
    CreateVersion,
    // Удалить
    Delete,
    // Редактировать
    Edit,
    // Подписать
    Sign,
    // Проверить подпись
    ViewSign,
}
