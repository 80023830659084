import React, { useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';

import './CustomEmailExecutor.scss';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { ModalSize } from '@atoms/Modal/Modal';

export class CustomEmailExecutor extends DocBaseActionExecutor {
    private _modalProps?: ICustomEmailExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            message: action.options?.message ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <CustomEmailExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ICustomEmailExecutorProps {
    docId: string;
    actionKey: string;
    title: string;
    message: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const CustomEmailExecutorModal: React.FC<ICustomEmailExecutorProps> = (props: ICustomEmailExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();

    const messageBoxProp: IMessageDialogProps = {
        header: props.title,
        message: props.message,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            let service = new DocumentExecutionService(props.docId);
            return service
                .sendCustomEmails(props.actionKey)
                .then(() => {
                    if (props.completeHandler) props.completeHandler(true);
                })
                .catch((error) => setErrorText(error));
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} />;
};
