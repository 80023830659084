export class RefresherDataSourceService {
    private _startedTimeout?: number;
    private _intervalTimeout?: number;
    private _requestTimeout?: number;
    private _isTabActive: boolean = true;
    // private _docId: string;
    // private _currentHash: string;
    private _initialDelay: number;
    private _interval: number;
    private _action?: () => void;

    constructor(initialDelay: number = 10, interval: number = 3000) {
        // this._docId = docId;
        // this._currentHash = currentHash;
        this._initialDelay = initialDelay;
        this._interval = interval;

        window.onfocus = () => {
            this._isTabActive = true;
        };
        window.onblur = () => {
            this._isTabActive = false;
        };
    }

    public stop() {
        if (this._startedTimeout) {
            clearTimeout(this._startedTimeout);
        }

        if (this._intervalTimeout) {
            clearTimeout(this._intervalTimeout);
        }

        if (this._requestTimeout) {
            clearTimeout(this._requestTimeout);
        }
    }

    public startMonitor(action: () => void) {
        this._action = action;
        this.stop();

        this._startedTimeout = window.setTimeout(() => {
            this.doAction();
        }, this._initialDelay);
    }

    public doAction() {
        if (!this._isTabActive) {
            this._intervalTimeout = window.setTimeout(() => {
                this.doAction();
            }, this._interval);
            return;
        } else {
            this._action && this._action();
            this._requestTimeout = window.setTimeout(() => {
                this.doAction();
            }, this._interval);
        }
    }
}
