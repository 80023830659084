import { AppDispatch, RootState } from '@/store/index';
import { UserAvatarSlice } from '@/store/reducers/UserAvatarReducer';
import { UserService } from '@services/UserService';

export const UserAvatarActionCreators = {
    getUserAvatar: () => async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const state = getState();
            const photoContent = state.userAvatar.photoContent;

            if (photoContent === null) {
                const response = await UserService.getCurrentUserAvatar();
                if (response) {
                    dispatch(UserAvatarSlice.actions.setAvatar(response.data));
                }
            }
        } catch (e) {
            console.log(e);
        }
    },
};
