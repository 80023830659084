import { IConfig } from '../models/IConfig';

export class ConfigService {
    static _config: IConfig;

    static async initAsync(): Promise<Readonly<IConfig>> {
        console.debug(`Инициализация конфигурации`);
        const basePath = import.meta.env.VITE_BASE_PATH;
        let response = await fetch(basePath === '/' ? `config.json` : `${basePath}/config.json`);
        this._config = await (response.json() as Promise<IConfig>);
        return Object.freeze(this._config);
    }

    static get(): Readonly<IConfig> {
        return Object.freeze(this._config);
    }
}

await ConfigService.initAsync();
