import React, { useEffect } from 'react';
import './VersionPanel.scss';
import { TITLE_PREFIX } from '@/App';
import { useActions } from '@hooks/useActions';
import { useTypedSelector } from '@hooks/useTypedSelector';

const VersionPanel: React.FC = () => {
    const version = useTypedSelector((state) => state.appData.version);
    const { getVersion } = useActions();
    useEffect(() => {
        getVersion();
    }, []);

    return (
        <div className="version-container">
            <div>{TITLE_PREFIX}</div>
            <div>{version ?? ''}</div>
        </div>
    );
};

export default VersionPanel;
