import { UseFormReturn } from 'react-hook-form';
import {
    IDictFilter,
    IDictFilters,
    IDocumentTable,
    IFormValues,
    ISetValue,
    ITableColumn,
    ITableColumnAbook,
    ITableColumnAutoComplete,
    ITableColumnCalc,
    ITableColumnDict,
} from '@models/Forms/IForms';
import { getFieldKeysByChanges, parseDisplayFormatCell } from '@utils/documentUtils';
import './EditTable.scss';
import { IField, IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import Dictpicker from '@atoms/Dictpicker';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import Abookpicker from '@atoms/Abookpicker';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import Checkbox from '@atoms/Checkbox';
import Textarea from '@atoms/Textarea';
import InputNumber from '@atoms/InputNumber/InputNumber';
import Datepicker from '@atoms/Datepicker';
import Input from '@atoms/Input';
import DisplayField from '@atoms/DisplayField/DisplayField';
import TableControl from '@controls/TableControl';
import { ChangesFieldValue, GetFormulaValues, GetValueForSetValue } from '@utils/ChangesManager';
import Select from '@atoms/Select';
import { IOption } from '@/types';
import { DictExternalDataSource } from '@utils/DictExternalDataSource';
import { ValueType } from '@/types/ValueType';
import { getOptions } from '@molecules/formbuilder/controls/AutoComplete/AutoCompleteHelper';
import AutoCompletePicker from '@atoms/AutoCompletePicker/AutoCompletePicker';
import {
    isTableColumn,
    isTableColumnAbook,
    isTableColumnAutoComplete,
    isTableColumnCalc,
    isTableColumnDict,
} from '@utils/tableHelper';
import Moment from 'moment';
import { round } from 'mathjs';
import { checkGridRowsByScript } from '@/utils/dataGridUtils';
import { classnames } from '@utils/classnames';

export interface IEditTableProps<TFieldValues extends object = object> {
    table?: IDocumentTable;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: string[]) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    docId?: string;
}

const EditTable = <TFieldValues extends object = object>({
    table,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    docId,
    ...props
}: IEditTableProps<TFieldValues>) => {
    const activated = React.useRef(false);
    const dataField = fields[table?.key!];
    let approvalListModeRules = table?.approvalListMode;
    const approvalListModeMng = new FormulaManager(approvalListModeRules!);
    approvalListModeMng.Init(fields);

    const [approvalListMode, setApprovalListMode] = useState<boolean>(false);
    const InitFormulas = async () => {
        if (approvalListModeRules) {
            let appMode = await approvalListModeMng.EvalFormulaValues(isEdit, isNew);
            if (activated.current) {
                setApprovalListMode(appMode);
            }
        }
        if (isNew && table?.invokeChangesOnNew) {
            await onTableChange(dataField.value, table);
        }
    };

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    useEffect(() => {
        if (isEdit && table?.invokeChangesOnEdit) {
            onTableChange(dataField.value, table);
        }
    }, [isEdit]);

    const onSaveFieldWithOutUpdate = async (item: ISetValue, table?: IDocumentTable, rowData?: any, value?: any) => {
        let val = value ? value : await GetValueForSetValue(item, table?.key, fields, rowData, formMethods);
        if (item.key.includes('|Document')) {
            let field = fields[item.key];
            formMethods.setValue(field.name as any, val, { shouldDirty: true });
        } else {
            const column = table?.tableColumn.find((col) => {
                return col.key == item.key;
            });
            if (
                column &&
                (column.valueType == ValueType.Date ||
                    column.valueType == ValueType.DateTime ||
                    column.valueType == ValueType.NoSecDateTime)
            ) {
                if (Moment(val, 'DD.MM.YYYY').isValid()) {
                    val = Moment(val, 'DD.MM.YYYY').toDate();
                } else {
                    if (Moment(val).isValid()) {
                        val = Moment(val).toDate();
                    }
                }
                rowData[item.key] = new Date(val);
            } else {
                rowData[item.key] = val;
            }
        }
    };
    const onSaveField = async (
        item: ISetValue,
        table?: IDocumentTable,
        rowData?: any,
        value?: any,
        withoutUpdateForm?: boolean,
    ) => {
        await onSaveFieldWithOutUpdate(item, table, rowData, value);
        if (table && (withoutUpdateForm === undefined || !withoutUpdateForm)) {
            let field = fields[table.key];
            if (field) {
                let tableRows = formMethods.getValues(field.name as any);
                let result = (tableRows as []).map((o: any) => (rowData['|NUM'] === o['|NUM'] ? rowData : o));
                formMethods.setValue(field?.name as any, result as any, { shouldDirty: false });
            }
        }
    };

    const getValue = (name: string, rowData?: any, rowParent?: any) => {
        if (name.includes('|Document') || name.startsWith('#links')) {
            let field = fields[name];
            let fname = ('fields.[' + field.index + '].value') as any;
            let val = formMethods.getValues(fname);
            return val as any;
        } else if (name.includes('|Parent') && rowParent) {
            let fname = name.replace('|Parent', '');
            type ObjectKey = keyof typeof rowParent;
            const attrNAme = fname as ObjectKey;
            return rowParent[attrNAme] as any;
        } else {
            if (rowData) {
                type ObjectKey = keyof typeof rowData;
                const attrNAme = name as ObjectKey;
                return rowData[attrNAme] as any;
            }
        }
    };

    const evalTableFormulaValue = async (condition: string, rowData?: any, rowParent?: any) => {
        let conditionMng = new FormulaManager(condition);
        conditionMng.Init(fields!, formMethods, rowParent);
        let coll = conditionMng.GetFields();
        // var common = fields.filter((x) => keysNew.indexOf(x) !== -1);
        // if (common.length > 0) {
        let values: any[] | undefined = [];
        coll.forEach((f) => {
            if (f.includes('|Document') || f.startsWith('#links')) {
                let field = fields[f];
                let fname = ('fields.[' + field.index + '].value') as any;
                let val = formMethods.getValues(fname);
                values?.push(val as any);
            } else if (f.includes('|Parent') && rowParent) {
                let fname = f.replace('|Parent', '');
                type ObjectKey = keyof typeof rowParent;
                const attrNAme = fname as ObjectKey;
                values?.push(rowParent[attrNAme] as any);
            } else {
                if (rowData) {
                    type ObjectKey = keyof typeof rowData;
                    const attrNAme = f as ObjectKey;
                    values?.push(rowData[attrNAme] as any);
                }
            }
        });
        if (values.every((element) => element === undefined)) {
            values = undefined;
        }

        return await conditionMng.EvalFormulaValues(isEdit, isNew, values);
    };

    const getFormValuesAsync = async (formValues: IFormValues, row: any) => {
        let formdataParams = formValues;
        const map = {} as Record<string, any>;
        if (formdataParams && formdataParams != null) {
            for (const ele of formdataParams?.formValue!) {
                if (ele.attr?.includes('|Document')) {
                    if (ele.formula) {
                        let manager = new FormulaManager(ele.formula);
                        manager.Init(fields, formMethods);
                        map[ele.key] = await manager.ReplaceFormulaValues(isEdit, isNew);
                    } else {
                        let field = fields[ele.attr];
                        let fname = ('fields.[' + field.index + '].value') as any;
                        let val = formMethods.getValues(fname);
                        if (ele.column) {
                            if (ele.function === '{join}') {
                                map[ele.key] =
                                    (val as [])?.map((item: any) => `'${item[ele.column]}'`).join(',') ?? val;
                            } else {
                                map[ele.key] = (val as [])?.map((item: any) => item[ele.column]) ?? val;
                            }
                        } else {
                            map[ele.key] = val;
                        }
                    }
                } else {
                    if (ele.formula) {
                        let manager = new FormulaManager(ele.formula);
                        manager.Init(fields, formMethods);
                        let _fields = manager.GetFields();
                        let values = _fields.map((field) => {
                            return getValue(field, row);
                        });
                        map[ele.key] = await manager.ReplaceFormulaValues(isEdit, isNew, values);
                    } else {
                        map[ele.key] = getValue(ele.attr, row);
                    }
                }
            }
        }
        return JSON.stringify(map);
    };

    const getFormValues = (formValues: IFormValues, row: any) => {
        let formdataParams = formValues;

        const map = {} as Record<string, any>;
        if (formdataParams && formdataParams != null) {
            for (const ele of formdataParams?.formValue!) {
                if (ele.attr.includes('|Document')) {
                    let field = fields[ele.attr];
                    let fname = ('fields.[' + field.index + '].value') as any;
                    let val = formMethods.getValues(fname);
                    if (ele.column) {
                        if (ele.function === '{join}') {
                            map[ele.key] = (val as [])?.map((item: any) => `'${item[ele.column]}'`).join(',') ?? val;
                        } else {
                            map[ele.key] = (val as [])?.map((item: any) => item[ele.column]) ?? val;
                        }
                    } else {
                        map[ele.key] = val;
                    }
                } else {
                    type ObjectKey = keyof typeof row;
                    const attrNAme = ele.attr as ObjectKey;
                    map[ele.key] = row[attrNAme] as any;
                }
            }
        }
        return JSON.stringify(map);
    };

    const getWatchesByFormula = (formulas?: string[], rowParent?: any) => {
        let result: string[] = [];
        if (formulas) {
            for (let index = 0; index < formulas.length; index++) {
                const formula = formulas[index];
                if (formula) {
                    let vis = new FormulaManager(formula);
                    vis.Init(fields, formMethods, rowParent);
                    let data = vis.GetWatchFields();
                    data.forEach((item: string) => {
                        result.push(item);
                    });
                }
            }
        }

        return result;
    };

    const getColumnWatches = (table?: IDocumentTable, rowParent?: any) => {
        let result: string[] = [];
        let allColumns: any[] = [];
        allColumns = allColumns
            .concat(table?.tableColumn)
            .concat(table?.tableColumnAbook)
            .concat(table?.tableColumnCalc)
            .concat(table?.tableColumnDict);
        allColumns.forEach((col) => {
            if (col.visibilityRules != undefined && col.visibilityRules != null) {
                let vis = new FormulaManager(col.visibilityRules);
                vis.Init(fields, formMethods, rowParent);
                result = result.concat(vis.GetWatchFields());
            }
            if (col.required != undefined && col.required != null) {
                let vis = new FormulaManager(col.required);
                vis.Init(fields, formMethods, rowParent);
                result = result.concat(vis.GetWatchFields());
            }
            if (col.readonly != undefined && col.readonly != null) {
                let vis = new FormulaManager(col.readonly);
                vis.Init(fields, formMethods, rowParent);
                result = result.concat(vis.GetWatchFields());
            }
        });
        return result;
    };

    const getFiltersAsync = async (dictFilters: IDictFilters, row: any) => {
        let filters = dictFilters;
        let result = {} as IDictFilter;
        const map = {} as Record<string, any>;
        if (filters && filters != null) {
            for (const ele of filters.filter) {
                let conditionMng = new FormulaManager(ele.condition);
                conditionMng.Init(fields, formMethods);
                let coll = conditionMng.GetFields();
                let values: any[] = [];
                coll.forEach((f) => {
                    if (f.includes('|Document')) {
                        let field = fields[f];
                        let val = formMethods.getValues(field.name as any);
                        values.push(val);
                    } else {
                        type ObjectKey = keyof typeof row;
                        const attrNAme = f as ObjectKey;
                        values.push(row[attrNAme] as any);
                    }
                });
                if (await conditionMng.EvalFormulaValues(isEdit, isNew, values)) {
                    result.condition = ele.condition;
                    if (ele.filter) {
                        let filterMng = new FormulaManager(ele.filter);
                        filterMng.Init(fields, formMethods);
                        result.filter = (await filterMng.ReplaceFormulaValues(isEdit, isNew))!;
                    }
                    if (ele.script) {
                        let scriptMng = new FormulaManager(ele.script);
                        scriptMng.Init(fields, formMethods);
                        result.script = (await scriptMng.ReplaceFormulaValues(isEdit, isNew))!;
                    }
                }
            }
        }
        return result;
    };

    const getValues = (values: IDictionaryData[], attr: string) => {
        let result = [] as string[];
        values.forEach((item) => {
            if (attr == 'code') {
                result.push(item.code);
            } else {
                let elem = item.fields.find((obj) => {
                    return obj.name === attr;
                });
                result.push(elem?.value?.toString()!);
            }
        });
        return result.join('|');
    };

    const getValuesAbook = (values: IAddressBookData[], attr: string) => {
        let result = [] as string[];
        values.forEach((item) => {
            type ObjectKey = keyof typeof item;
            const attrNAme = attr as ObjectKey;
            result.push(item[attrNAme]?.toString());
        });
        return result.join('|');
    };

    const getExternalDataSource = (dict: ITableColumnDict) => {
        if (dict?.externalDataSource) {
            const dictExternalDataSource = new DictExternalDataSource(dict?.externalDataSource);
            return dictExternalDataSource.GetData(formMethods, fields);
        }
        return [];
    };

    const getParentFields = () => {
        let fields: IField[] = [];
        let values = formMethods.getValues() as any;
        let coll = values.fields as IField[];
        if (coll != undefined) {
            for (let index = 0; index < coll.length; index++) {
                const field_src = coll[index];
                //if (field_src.name != table?.key) {
                let objCopy = { ...field_src };
                fields.push(objCopy);
                //}
            }
        }
        return fields;
    };

    const renderColumnGrid = async (column: ITableColumn, p: any, rowParent?: any) => {
        let readOnly = column.readonly
            ? column.readonly == 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;

        const options: IOption[] = [];
        let countItems = 100;
        let startYear = 1950;

        if (column?.min && Number(column?.min) > 1950) {
            startYear = Number(column.min);
        }
        if (column?.max && column.max) {
            countItems = Number(column.max) - startYear;
        }

        if (column?.valueType == ValueType.Year) {
            for (let index = 0; index <= countItems; index++) {
                let year = startYear + index;
                options.push({
                    label: year.toString(),
                    value: year.toString(),
                } as IOption);
            }
        }

        let minVal = await evalTableFormulaValue(column.min, p.data, rowParent);
        let maxVal = await evalTableFormulaValue(column.max!, p.data, rowParent);

        switch (column?.valueType) {
            case ValueType.Boolean:
                return (
                    <Checkbox
                        readOnly={readOnly}
                        disabled={readOnly}
                        name={column.key}
                        defaultChecked={p.value && (p.value === 1 || p.value === '1') ? true : false}
                        onChange={(e) => {
                            let val = e.currentTarget.checked ? 1 : 0;
                            p.setValue(val);
                            p.component.saveEditData();
                        }}
                    />
                );
            case ValueType.Year:
                return (
                    <Select
                        disabled={readOnly}
                        readOnly={true}
                        options={options}
                        onlySelect={true}
                        values={
                            p.value
                                ? [
                                      {
                                          value: p.value.toString(),
                                          label: p.value.toString(),
                                      } as IOption,
                                  ]
                                : undefined
                        }
                        onChange={(option: IOption[]) => {
                            if (option.length > 0) {
                                let val = option[0].value;
                                if (p.value === undefined || p.value === null || p.value.toString() != val.toString()) {
                                    p.setValue(+val);
                                }
                            }
                        }}
                        placeholder={column?.placeholder ? column.placeholder : undefined}
                    />
                );
            case ValueType.YesNo:
                return (
                    <Select
                        disabled={readOnly}
                        readOnly={true}
                        options={[
                            {
                                value: '1',
                                label: column.trueText || 'Да',
                            },
                            {
                                value: '0',
                                label: column.falseText || 'Нет',
                            },
                        ]}
                        onlySelect={true}
                        values={
                            p.value !== undefined && p.value !== null
                                ? [
                                      {
                                          value: p.value.toString(),
                                          label:
                                              p.value.toString() == '1'
                                                  ? column.trueText || 'Да'
                                                  : column.falseText || 'Нет',
                                      } as IOption,
                                  ]
                                : []
                        }
                        onChange={(option: IOption[]) => {
                            if (option.length > 0) {
                                let val = option[0].value == '1' ? 1 : 0;
                                if (p.value === undefined || p.value === null || p.value.toString() != val.toString()) {
                                    p.setValue(val);
                                }
                            }
                        }}
                        placeholder={column?.placeholder ? column.placeholder : undefined}
                    />
                );
            case ValueType.LongText:
                return (
                    <Textarea
                        autoResize={true}
                        readOnly={readOnly}
                        name={column.key}
                        initialRowCount={1}
                        value={p.value}
                        onValueChange={(e) => {
                            p.setValue(e);
                        }}
                        placeholder={column?.placeholder ? column.placeholder : undefined}
                    />
                );
            case ValueType.Double:
                return (
                    <InputNumber
                        readOnly={readOnly}
                        name={column.key}
                        groupBy={0}
                        separator={''}
                        floatPoints={column.floatPoints ? column.floatPoints : 2}
                        defaultValue={p.value}
                        onInputChange={(value) => {
                            let val = +value.replace(/\s/g, '');
                            if (p.value != val) {
                                p.setValue(val);
                            }
                        }}
                        placeholder={column?.placeholder ? column.placeholder : undefined}
                    />
                );
            case ValueType.Money:
                return (
                    <InputNumber
                        readOnly={readOnly}
                        name={column.key}
                        floatPoints={column.floatPoints ? column.floatPoints : 2}
                        defaultValue={p.value}
                        onInputChange={(value) => {
                            let val = +value.replace(/\s/g, '');
                            if (p.value != val) {
                                p.setValue(val);
                            }
                        }}
                        placeholder={column?.placeholder ? column.placeholder : undefined}
                    />
                );
            case ValueType.Integer:
                return (
                    <InputNumber
                        readOnly={readOnly}
                        name={column.key}
                        groupBy={0}
                        separator={''}
                        defaultValue={p.value}
                        onInputChange={(value) => {
                            let val = +value;
                            if (p.value != val) {
                                p.setValue(val);
                            }
                        }}
                        placeholder={column?.placeholder ? column.placeholder : undefined}
                    />
                );
            case ValueType.NoSecDateTime:
            case ValueType.Date:
            case ValueType.DateTime:
                return (
                    <Datepicker
                        readOnly={readOnly}
                        name={column.key}
                        defaultValue={p.value}
                        minDate={minVal}
                        maxDate={maxVal}
                        onChange={(e) => {
                            const val = e.date.value ?? null;
                            p.setValue(val);
                        }}
                        placeholder={column?.placeholder ? column.placeholder : undefined}
                    />
                );

            case ValueType.Url:
                return (
                    <Input
                        pattern={column.inputRegExp}
                        readOnly={readOnly}
                        name={column.key}
                        defaultValue={p.value}
                        onChange={(e) => {
                            p.setValue(e.currentTarget.value);
                        }}
                    />
                );

            case ValueType.Text:
                return (
                    <Input
                        maxLength={255}
                        pattern={column.inputRegExp}
                        readOnly={readOnly}
                        name={column.key}
                        defaultValue={p.value}
                        onChange={(e) => {
                            p.setValue(e.currentTarget.value);
                        }}
                        placeholder={column?.placeholder ? column.placeholder : undefined}
                    />
                );

            default:
                return <div></div>;
        }
    };

    const renderColumnDictGrid = async (column: ITableColumnDict, p: any, rowParent?: any) => {
        let readOnly = column.readonly
            ? column.readonly == 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;
        return (
            <Dictpicker
                readOnly={readOnly}
                docId={docId}
                isSelectMode={column.isSelectMode}
                dictionaryName={column.dictName}
                modalTitle={column.modalTitle}
                isFormData={column.isFormData}
                isMultiple={column.isMultiple}
                selectableLevels={column.selectableLevels}
                visibleLevels={column.visibleLevels}
                predicatesCache={column.predicatesCache}
                gridAttribute={column.gridAttribute}
                externalSearch={column.externalSearch}
                addForm={column.addForm}
                loadMode={column.loadMode}
                getExternalDataSource={() => {
                    return getExternalDataSource(column);
                }}
                getFormValuesAsync={async () => {
                    return await getFormValuesAsync(column.formValues, p.data);
                }}
                getFiltersAsync={async () => {
                    return await getFiltersAsync(column.filters, p.data);
                }}
                defaultValue={p.value}
                diplayValue={parseDisplayFormatCell(column.displayFormat, p.data)}
                insideGrid={true}
                source={column.source}
                useClientSideDataProcessing={column.useClientSideDataProcessing}
                placeholder={column?.placeholder ? column.placeholder : undefined}
                onChange={(e) => {
                    if (e && e.length > 0) {
                        column.setValues?.sets?.forEach((el) => {
                            let val: any = undefined;
                            if (el.attr === 'empty') {
                                val = p.data[el.key] === '' ? undefined : '';
                            } else {
                                val = getValues(e, el.attr);

                                val = setValueTyped(val, el);
                            }
                            p.data[el.key] = val;
                        });
                        p.setValue(getValues(e, 'code'));
                    } else {
                        column.setValues?.sets?.forEach((el) => {
                            let val: any = '';
                            p.data[el.key] = val;
                        });
                        p.setValue('');
                    }
                    // p.component.cancelEditData();
                    p.component.saveEditData();
                }}
                onCancel={() => {
                    p.component.cancelEditData();
                }}
            />
        );
    };

    const setValueTyped = (val: any, el: ISetValue) => {
        if (el.type) {
            switch (el.type) {
                case 'double':
                case 'integer': {
                    try {
                        if (typeof val == 'string' && val.indexOf(',')) {
                            val = val.replace(',', '.');
                        }
                        val = +val;
                        if (isNaN(val)) {
                            val = 0;
                        }
                    } catch (error) {
                        val = 0;
                    }
                    break;
                }
                case 'json': {
                    val = JSON.parse(val);
                    break;
                }
                case 'date': {
                    try {
                        let parseVal = new Date(val);
                        if (!isNaN(parseVal.getTime()) && parseVal.getTime() > 0) {
                            val = parseVal;
                        } else {
                            throw new Error('parse Date');
                        }
                    } catch (error) {
                        let parts = val.split(' ');
                        if (parts.length > 1) {
                            val = Moment(val, 'DD.MM.YYYY hh.mm.ss').toDate();
                        } else {
                            if (Moment(val, 'DD.MM.YYYY').isValid()) {
                                val = Moment(val, 'DD.MM.YYYY').toDate();
                            } else {
                                if (Moment(val).isValid()) {
                                    val = Moment(val).toDate();
                                } else {
                                    val = null;
                                }
                            }
                        }
                    }
                    break;
                }
                default:
                    val = val;
                    break;
            }
        }
        return val;
    };

    const renderColumnAutoCompleteGrid = async (column: ITableColumnAutoComplete, p: any, rowParent?: any) => {
        let readOnly = column.readonly
            ? column.readonly == 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;
        return (
            <AutoCompletePicker
                disabled={readOnly}
                defInputValue={p.value}
                debounceTime={500}
                placeholder={column?.placeholder ? column.placeholder : undefined}
                findOptions={async (text: string) => {
                    return await getOptions(column?.dataSource!, text);
                }}
                onInputValueChanged={(e) => {
                    p.setValue(e);
                }}
            />
        );
    };

    const renderColumnCalcGrid = async (column: ITableColumnCalc, p: any, rowParent?: any) => {
        let readOnly = column.readonly
            ? column.readonly == 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;
        const typeValue = column.valueType !== undefined ? column.valueType : ValueType.Integer;
        switch (typeValue) {
            case ValueType.Double:
                return (
                    <InputNumber
                        readOnly={readOnly}
                        name={column.key}
                        groupBy={0}
                        separator={''}
                        floatPoints={column.floatPoints ? column.floatPoints : 2}
                        defaultValue={p.value}
                        onInputChange={(value) => {
                            let val = +value.replace(/\s/g, '');
                            if (p.value != val) {
                                p.setValue(val);
                            }
                        }}
                        placeholder={column?.placeholder ? column.placeholder : undefined}
                    />
                );
            case ValueType.Money:
                return (
                    <InputNumber
                        readOnly={readOnly}
                        name={column.key}
                        floatPoints={column.floatPoints ? column.floatPoints : 2}
                        defaultValue={p.value}
                        onInputChange={(value) => {
                            let val = +value.replace(/\s/g, '');
                            if (p.value != val) {
                                p.setValue(val);
                            }
                        }}
                        placeholder={column?.placeholder ? column.placeholder : undefined}
                    />
                );
            default:
                return (
                    <InputNumber
                        readOnly={readOnly}
                        name={column.key}
                        groupBy={0}
                        separator={''}
                        defaultValue={p.value}
                        onInputChange={(value) => {
                            let val = +value;
                            if (p.value != val) {
                                p.setValue(val);
                            }
                        }}
                        placeholder={column?.placeholder ? column.placeholder : undefined}
                    />
                );
        }
    };
    const renderColumnAbookGrid = async (column: ITableColumnAbook, p: any, rowParent?: any) => {
        let readOnly = column.readonly
            ? column.readonly == 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;
        return (
            <Abookpicker
                readOnly={readOnly}
                insideGrid={true}
                isMultiple={column.isMultiple}
                tabsSettings={column.tabs}
                externalSearch={column.externalSearch}
                formValues={getFormValues(column.formValues, p.data)}
                title={column.name}
                defaultValue={p.value}
                showChips={column.showChips}
                diplayValue={parseDisplayFormatCell(column.displayFormat, p.data)}
                placeholder={column?.placeholder ? column.placeholder : undefined}
                onChange={(e) => {
                    if (e && e.length > 0) {
                        column.setValues.sets.forEach((el) => {
                            if (el.attr === 'empty') {
                                p.data[el.key] = p.data[el.key] === '' ? undefined : '';
                            } else {
                                p.data[el.key] = getValuesAbook(e, el.attr);
                            }
                        });
                        p.setValue(getValuesAbook(e, 'key'));
                    } else {
                        column.setValues.sets.forEach((el) => {
                            p.data[el.key] = '';
                        });
                        p.setValue('');
                    }
                    p.component.saveEditData();
                    p.component.cancelEditData();
                }}
            />
        );
    };

    const cellRenderSwitcherField = async (p: any, column: any, rowParent?: any) => {
        let content = cellRenderSwitcher(p, column);
        let readOnly = column.readonly
            ? column.readonly === 'true'
                ? true
                : await evalTableFormulaValue(column.readonly, p.data, rowParent)
            : false;
        let required = column.required
            ? column.required === 'true'
                ? true
                : await evalTableFormulaValue(column.required, p.data, rowParent)
            : false;

        let regexp = column.inputRegExp ? new RegExp(column.pattern, 'g') : null;
        let regval = p.value === undefined || p.value === null ? '' : p.value;
        const invalidCell =
            (required && (p.value === undefined || p.value === null || p.value === '')) ||
            (regexp && !regexp.test(regval)) ||
            (p.value && column.max && p.value.length > column.max) ||
            (column.min && (p.value === undefined || p.value === null || p.value === '' || p.data.length < column.min));
        let cellClassName = classnames('table-cell', {
            'invalid-cell': invalidCell,
        });
        if (column.styles) {
            for (let index = 0; index < column.styles.style.length; index++) {
                const x = column.styles.style[index];
                let result = await evalTableFormulaValue(x.condition, p.data, rowParent);
                if (result) {
                    cellClassName += ' ' + x.cellStyle;
                }
            }
        }
        return (
            <div className={cellClassName}>
                <div
                    className={classnames('w100', {
                        'longtext-cell': column.valueType == ValueType.LongText,
                        'text-cell': column.valueType == ValueType.Text,
                        'readonly-cell': readOnly,
                    })}
                >
                    {content}
                </div>
            </div>
        );
    };

    const cellRenderSwitcher = (p: any, column: any) => {
        if (isTableColumnDict(column) || isTableColumnAbook(column)) {
            return <div>{parseDisplayFormatCell(column.displayFormat, p.data)}</div>;
        } else if (isTableColumnCalc(column)) {
            const typeValue = column.valueType !== undefined ? column.valueType : ValueType.Integer;
            return <DisplayField type={typeValue} value={p.value} />;
        } else if (isTableColumnAutoComplete(column)) {
            return <DisplayField type={ValueType.Text} value={p.value} />;
        } else if (isTableColumn(column)) {
            return (
                <DisplayField
                    type={column?.valueType}
                    value={p.value}
                    trueText={column?.trueText}
                    falseText={column?.falseText}
                />
            );
        }
    };

    const editCellRenderSwitcher = async (p: any, column: any, rowParent?: any) => {
        if (isTableColumnCalc(column)) {
            return await renderColumnCalcGrid(column, p, rowParent);
        } else if (isTableColumnAbook(column)) {
            return await renderColumnAbookGrid(column, p, rowParent);
        } else if (isTableColumnDict(column)) {
            return await renderColumnDictGrid(column, p, rowParent);
        } else if (isTableColumnAutoComplete(column)) {
            return await renderColumnAutoCompleteGrid(column, p, rowParent);
        } else if (isTableColumn(column)) {
            return await renderColumnGrid(column, p, rowParent);
        }
    };

    const calculateRow = async (row: any, column: any, table: IDocumentTable) => {
        if (table.tableColumnCalc) {
            for (let index = 0; index < table.tableColumnCalc.length; index++) {
                let keysChanged: string[] = [];
                const item = table.tableColumnCalc[index];
                if (column) {
                    keysChanged.push(column.key);
                    if (column.changes) {
                        keysChanged = keysChanged.concat(getFieldKeysByChanges(column.changes));
                    }
                }

                if (
                    column == undefined ||
                    (column.key != item.key &&
                        keysChanged.some((x) => {
                            return item.formula.indexOf(x) > -1;
                        }))
                ) {
                    const formulaMng = new FormulaManager(item.formula!);
                    formulaMng.Init(fields);
                    let coll = formulaMng.GetFields();
                    let values: any[] | undefined = undefined;
                    if (formMethods && row && coll.length > 0) {
                        values = GetFormulaValues(coll, table.key, fields, row, formMethods);
                    }
                    let val = await formulaMng.EvalFormulaValues(isEdit, isNew, values);
                    let valForm = isNaN(val) ? 0 : val;
                    if (item.floatPoints) {
                        valForm = round(valForm, item.floatPoints);
                    }
                    row[item.key] = +valForm;
                }
            }
        }

        return row;
    };

    const onChangeCellValue = async (
        row: any,
        oldRow: any,
        column: any,
        table: IDocumentTable,
        withOutUpdate?: boolean,
    ) => {
        if (column && column.key && table && table.key) {
            let val = row[column.key];
            await ChangesFieldValue(
                column.changes,
                val,
                table.key,
                fields,
                isEdit,
                isNew,
                withOutUpdate ? onSaveFieldWithOutUpdate : onSaveField,
                setError,
                table,
                row,
                formMethods,
                oldRow,
            );
        }
    };
    const onInitNewRow = async (data: any, table: IDocumentTable) => {
        const sets = table.newRowData?.setValues?.sets;
        if (table.newRowData && sets) {
            const promises = [];
            for (let index = 0; index < sets.length; index++) {
                const item = sets[index];
                promises.push(onSaveField(item, table, data, undefined, true));
            }
            await Promise.all(promises);
        }
    };

    const onInitCopyRow = async (data: any, table: IDocumentTable) => {
        const sets = table.copyRowData?.setValues?.sets;
        if (table.copyRowData && sets) {
            const promises = [];
            for (let index = 0; index < sets.length; index++) {
                const item = sets[index];
                promises.push(onSaveField(item, table, data, undefined, true));
            }
            await Promise.all(promises);
        }
    };

    const onValidateExternalRowsData = async (data: IDictionaryData[], table: IDocumentTable) => {
        let messages: string[] = [];
        let result: boolean = true;

        table.addFormDataRows.validators.validators.forEach((validator) => {
            if (!checkGridRowsByScript(data, validator.script)) {
                messages.push(validator.message);
            }
        });

        if (messages.length > 0) {
            setError(messages);
            result = false;
        }

        return result;
    };

    const onSetFormDataNewRow = async (item: any, table: IDocumentTable, data: IDictionaryData) => {
        const sets = table.addFormDataRows?.setValues?.sets;
        if (table.addFormDataRows && sets) {
            const promises = [];
            for (let index = 0; index < sets.length; index++) {
                const set = sets[index];
                let elem: any = undefined;

                if (set.attr === 'code') {
                    elem = data.code;
                } else {
                    let elemField = data.fields.find((obj) => obj.name === set.attr);
                    if (elemField && elemField?.value !== undefined) {
                        elem = elemField.value;
                        elem = setValueTyped(elem, set);
                    }
                }

                if (elem && elem !== '') {
                    promises.push(onSaveField(set, table, item, elem));
                }
            }
            await Promise.all(promises);
        }
    };

    const onTableChange = async (value: any, table: IDocumentTable) => {
        await ChangesFieldValue(
            table.changes!,
            value,
            table.key,
            fields,
            isEdit,
            isNew,
            async (item: ISetValue, table?: IDocumentTable, rowData?: any, value?: any) => {
                await onSaveField(item, undefined, rowData, value);
            },
            setError,
            table,
            undefined,
            formMethods,
        );
        for (let index = 0; index < table.tables.length; index++) {
            const subTable = table.tables[index];
            await ChangesFieldValue(
                subTable.changes!,
                value,
                subTable.key,
                fields,
                isEdit,
                isNew,
                onSaveField,
                setError,
                subTable,
                undefined,
                formMethods,
            );
        }
    };

    const onTableRowDeleted = async (value: any, row: any, table: IDocumentTable) => {
        await ChangesFieldValue(
            table.onDeletedRows!,
            value,
            table.key,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            table,
            row,
            formMethods,
        );
        for (let index = 0; index < table.tables.length; index++) {
            const subTable = table.tables[index];
            await ChangesFieldValue(
                subTable.onDeletedRows!,
                value,
                subTable.key,
                fields,
                isEdit,
                isNew,
                onSaveField,
                setError,
                subTable,
                row,
                formMethods,
            );
        }
    };

    const onTableRowCopied = async (value: any, row: any, table: IDocumentTable) => {
        await ChangesFieldValue(
            table.onCopiedRows!,
            value,
            table.key,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            table,
            row,
            formMethods,
        );
        for (let index = 0; index < table.tables.length; index++) {
            const subTable = table.tables[index];
            await ChangesFieldValue(
                subTable.onCopiedRows!,
                value,
                subTable.key,
                fields,
                isEdit,
                isNew,
                onSaveField,
                setError,
                subTable,
                row,
                formMethods,
            );
        }
    };

    const onFormRowEdited = async (value: any, row: any, table: IDocumentTable) => {
        await ChangesFieldValue(
            table.onFormRowEdited!,
            value,
            table.key,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            table,
            row,
            formMethods,
        );
        for (let index = 0; index < table.tables.length; index++) {
            const subTable = table.tables[index];
            await ChangesFieldValue(
                subTable.onFormRowEdited!,
                value,
                subTable.key,
                fields,
                isEdit,
                isNew,
                onSaveField,
                setError,
                subTable,
                row,
                formMethods,
            );
        }
    };

    const setParentField = (field: IField) => {
        formMethods.setValue(field.name as any, field.value as any, { shouldDirty: true });
    };

    return table && table.key && table.tableColumn && dataField ? (
        <TableControl
            name={dataField.name}
            formMethods={formMethods}
            table={table}
            docId={docId}
            getParentFields={getParentFields}
            setParentField={setParentField}
            allowUpdating={!table?.updateRowsDisabled}
            onTableRowDeleted={onTableRowDeleted}
            onTableRowCopied={onTableRowCopied}
            onFormRowEdited={onFormRowEdited}
            calculateRow={calculateRow}
            onChangeCellValue={onChangeCellValue}
            cellRenderSwitcher={cellRenderSwitcherField}
            editCellRenderSwitcher={editCellRenderSwitcher}
            evalTableFormulaValue={evalTableFormulaValue}
            onInitNewRow={onInitNewRow}
            onInitCopyRow={onInitCopyRow}
            getColumnWatches={getColumnWatches}
            getWatchesByFormula={getWatchesByFormula}
            onSaved={async (e: any) => {
                formMethods.setValue(dataField.name as any, e as any, { shouldDirty: true });
                await onTableChange(e, table);
            }}
            getFormValuesAsync={async () => {
                return await getFormValuesAsync(table?.addFormDataRows.formValues, null);
            }}
            getFiltersAsync={async () => {
                return await getFiltersAsync(table?.addFormDataRows.filters, null);
            }}
            onSetFormDataNewRow={onSetFormDataNewRow}
            onValidateExternalRowsData={onValidateExternalRowsData}
        />
    ) : (
        <></>
    );
};

export default EditTable;
