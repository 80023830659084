import './InputPassword.scss';
import React, { forwardRef, useState } from 'react';
import Input, { IInputProps } from '../Input/Input';
import { MdRemoveRedEye } from 'react-icons/md';

export interface IInputPasswordProps extends Omit<IInputProps, 'type' | 'endAdornment'> {
    /** Отображать иконку просмотра пароля
     * @default true
     */
    showPreview?: boolean;
}

const InputPassword: React.FC<IInputPasswordProps> = forwardRef<HTMLLabelElement | null, IInputPasswordProps>(
    ({ showPreview = true, ...props }: IInputPasswordProps, ref) => {
        const [previewPassword, setPreviewPassword] = useState(false);

        return (
            <Input
                {...props}
                type={previewPassword ? 'text' : 'password'}
                endAdornment={
                    showPreview ? (
                        <MdRemoveRedEye
                            size={'24'}
                            onPointerDown={() => {
                                setPreviewPassword(true);
                            }}
                            onPointerUp={() => {
                                setPreviewPassword(false);
                            }}
                            onPointerOut={() => {
                                setPreviewPassword(false);
                            }}
                        />
                    ) : undefined
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let val = e.target.value;
                    if (props.onValueChange) {
                        props.onValueChange(val);
                    }
                }}
            />
        );
    },
);

export default InputPassword;
