import { DocActionTypes } from '@models/actions/DocActionTypes';
import { IBaseAction } from '@models/actions/IBaseAction';
import { AttachFilesExecutor } from './docExecutors/AttachFiles/AttachFilesExecutor';
import { DownloadAllExecutor } from './docExecutors/DownloadAll/DownloadAllExecutor';
import { DeleteDocumentExecutor } from './docExecutors/DeleteDocument/DeleteDocumentExecutor';
import { VoteQuestionWithSignExecutor } from './docExecutors/VoteQuestionWithSign/VoteQuestionWithSignExecutor';
import { IActionExecutor } from './IActionExecutor';
import { RunRouteExecutor } from './docExecutors/RunRoute/RunRouteExecutor';
import { RunAutoTaskExecutor } from './docExecutors/RunAutoTask/RunAutoTaskExecutor';
import { RegisterOnBaseExecutor } from './docExecutors/RegisterOnBase/RegisterOnBaseExecutor';
import { MailToExecutor } from './docExecutors/MailTo/MailToExecutor';
import { LoadFromFileExecutor } from './docExecutors/LoadFromFile/LoadFromFileExecutor';
import { RegisterExecutor } from './docExecutors/Register/RegisterExecutor';
import { DocumentsBatchEditingExecutor } from './docExecutors/DocumentsBatchEditing/DocumentsBatchEditingExecutor';
import { OpenDocumentExecutor } from './docExecutors/OpenDocument/OpenDocumentExecutor';
import { SendToEtpExecutor } from './docExecutors/SendToEtp/SendToEtpExecutor';
import { CopyAttachmentsExecutor } from './docExecutors/CopyAttachments/CopyAttachmentsExecutor';
import { CustomEmailExecutor } from './docExecutors/CustomEmail/CustomEmailExecutor';
import { CopyDocumentExecutor } from './docExecutors/CopyDocument/CopyDocumentExecutor';
import { RedirectExecutor } from './docExecutors/Redirect/RedirectExecutor';
import { CreateDocByTemplateExecutor } from './docExecutors/CreateDocByTemplate/CreateDocByTemplateExecutor';
import { CreateDocByTemplateDialogExecutor } from './docExecutors/CreateDocByTemplateDialog/CreateDocByTemplateDialogExecutor';
import { MailReminderExecutor } from './docExecutors/MailReminder/MailReminderExecutor';
import { DownloadLinkedDocumentsAttachmentsExecutor } from './docExecutors/DownloadLinkedDocumentsAttachments/DownloadLinkedDocumentsAttachmentsExecutor';
import { CreateDocumentLinkExecutor } from './docExecutors/CreateDocumentLink/CreateDocumentLinkExecutor';
import { BatchActivitiesCompleteExecutor } from './docExecutors/BatchActivitiesComplete/BatchActivitiesCompleteExecutor';
import { SendToEisExecutor } from './docExecutors/SendToEis/SendToEisExecutor';
import { OpenUrlExecutor } from './docExecutors/OpenUrl/OpenUrlExecutor';
import { CreateReportExecutor } from './docExecutors/CreateReport/CreateReportExecutor';
import { VoteQuestionExecutor } from './docExecutors/VoteQuestion/VoteQuestionExecutor';
import { CreateDocumentTemplateExecutor } from '@utils/actions/docExecutors/CreateDocumentTemplate/CreateDocumentTemplateExecutor';
import { AttachFilesFromExistingDocsExecutor } from './docExecutors/AttachFilesFromExistingDocs/AttachFilesFromExistingDocsExecutor';
import { WIICFreeDateExecutor } from './docExecutors/WIICFreeDate/WIICFreeDateExecutor';
import { RegisterModalExecutor } from '@utils/actions/docExecutors/RegisterModal/RegisterModalExecutor';

export class DocActionsExecFactory {
    public static allExecutors: { [actType: number]: () => IActionExecutor } = {
        [DocActionTypes[DocActionTypes.DownloadAll]]: () => new DownloadAllExecutor(),
        [DocActionTypes[DocActionTypes.AttachFiles]]: () => new AttachFilesExecutor(),
        [DocActionTypes[DocActionTypes.DeleteDocument]]: () => new DeleteDocumentExecutor(),
        [DocActionTypes[DocActionTypes.RunRoute]]: () => new RunRouteExecutor(),
        [DocActionTypes[DocActionTypes.VoteQuestion]]: () => new VoteQuestionExecutor(),
        [DocActionTypes[DocActionTypes.VoteQuestionWithSign]]: () => new VoteQuestionWithSignExecutor(),
        [DocActionTypes[DocActionTypes.RunAutoTask]]: () => new RunAutoTaskExecutor(),
        [DocActionTypes[DocActionTypes.MailTo]]: () => new MailToExecutor(),
        [DocActionTypes[DocActionTypes.RegisterOnBase]]: () => new RegisterOnBaseExecutor(),
        [DocActionTypes[DocActionTypes.LoadFromFile]]: () => new LoadFromFileExecutor(),
        [DocActionTypes[DocActionTypes.Register]]: () => new RegisterExecutor(),
        [DocActionTypes[DocActionTypes.DocumentsBatchEditing]]: () => new DocumentsBatchEditingExecutor(),
        [DocActionTypes[DocActionTypes.OpenDocument]]: () => new OpenDocumentExecutor(),
        [DocActionTypes[DocActionTypes.SendToEtp]]: () => new SendToEtpExecutor(),
        [DocActionTypes[DocActionTypes.SendToEis]]: () => new SendToEisExecutor(),
        [DocActionTypes[DocActionTypes.CopyAttachments]]: () => new CopyAttachmentsExecutor(),
        [DocActionTypes[DocActionTypes.CustomEmail]]: () => new CustomEmailExecutor(),
        [DocActionTypes[DocActionTypes.CopyDocument]]: () => new CopyDocumentExecutor(),
        [DocActionTypes[DocActionTypes.Redirect]]: () => new RedirectExecutor(),
        [DocActionTypes[DocActionTypes.CreateDocByTemplate]]: () => new CreateDocByTemplateExecutor(),
        [DocActionTypes[DocActionTypes.CreateDocByTemplateDialog]]: () => new CreateDocByTemplateDialogExecutor(),
        [DocActionTypes[DocActionTypes.MailReminder]]: () => new MailReminderExecutor(),
        [DocActionTypes[DocActionTypes.DownloadLinkedDocumentsAttachments]]: () =>
            new DownloadLinkedDocumentsAttachmentsExecutor(),
        [DocActionTypes[DocActionTypes.CreateDocumentLink]]: () => new CreateDocumentLinkExecutor(),
        [DocActionTypes[DocActionTypes.BatchActivitiesComplete]]: () => new BatchActivitiesCompleteExecutor(),
        [DocActionTypes[DocActionTypes.OpenUrl]]: () => new OpenUrlExecutor(),
        [DocActionTypes[DocActionTypes.CreateReport]]: () => new CreateReportExecutor(),
        [DocActionTypes[DocActionTypes.CreateDocumentTemplate]]: () => new CreateDocumentTemplateExecutor(),
        [DocActionTypes[DocActionTypes.AttachFilesFromExistingDocs]]: () => new AttachFilesFromExistingDocsExecutor(),
        [DocActionTypes[DocActionTypes.WIICFreeDate]]: () => new WIICFreeDateExecutor(),
        [DocActionTypes[DocActionTypes.RegisterModal]]: () => new RegisterModalExecutor(),
    };

    static GetActionExecutor(act: IBaseAction): IActionExecutor {
        let createExecutor = this.allExecutors[act.type];
        if (!createExecutor) throw new Error(`Не обнаружен ActionExecutor для типа ${act.type}`);
        return createExecutor();
    }
}
