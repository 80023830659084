import './OfferKOCritDetails.scss';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@atoms/Button';
import { classnames } from '@utils/classnames';
import { MdClose } from 'react-icons/md';
import Modal from '@atoms/Modal';
import { Controller, useForm } from 'react-hook-form';
import { FormProviderControl, HookFormProvider, SelectControl } from '@controls/index';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { sendNotification } from '@molecules/Notifications';
import Hint from '@atoms/Hint';
import { IOfferKOCrit, ITableKOItem } from '@models/winnerSelection/ITableKO';
import { IOption } from '@/types';

export interface IOfferKOCritDetailsProps {
    tableKey: string;
    isEdit: boolean;
    demandId: string;
    offerId: string;
    disabledHint?: string;
    editButtonName?: string | null;
    value: IOfferKOCrit;
    rowData: ITableKOItem;
    onSubmit?: (value: IOfferKOCritDetailsForm) => void;
}

export interface IOfferKOCritDetailsForm {
    critId: string;
    result: number | null;
}

const OfferKOCritDetails = ({
    tableKey,
    isEdit,
    disabledHint,
    editButtonName,
    demandId,
    offerId,
    value,
    rowData,
    onSubmit,
    ...props
}: IOfferKOCritDetailsProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const methods = useForm<IOfferKOCritDetailsForm>({
        mode: 'onBlur',
    });

    const { isSubmitting, isDirty } = methods.formState;

    useEffect(() => {
        const defaultValue: IOfferKOCritDetailsForm = {
            critId: rowData.critId,
            result: value.result,
        };
        methods.reset(defaultValue);
    }, [value, showModal]);

    const onClick = (e: any) => {
        setShowModal(!showModal);
    };

    const onFormSubmit = async (form: IOfferKOCritDetailsForm) => {
        setError(undefined);
        return WinnerSelectionService.saveTableKOOfferCrit(offerId, demandId, tableKey, form)
            .then((response) => {
                sendNotification({
                    message: 'Данные успешно сохранены',
                    variant: 'green',
                });
                setShowModal(!showModal);
                onSubmit && onSubmit(form);
            })
            .catch((reason) => {
                setError(reason);
                console.log(reason);
            });
    };

    const results = useMemo(() => {
        return [
            { label: 'Допущен', value: 1 },
            { label: 'Не допущен', value: 0 },
        ] as IOption[];
    }, []);

    const errorLabel = error && (
        <div className="offer-ko-crit-details__error">
            <Hint title={error} variant="red" />
        </div>
    );

    return (
        <>
            <div className={classnames('offer-ko-crit-details')} onClick={onClick}>
                <Button type="button" aria-label="Показать детали" buttonType={'secondary'} onClick={onClick}>
                    {editButtonName ?? 'Показать детали'}
                </Button>
            </div>
            {showModal ? (
                <Modal
                    size="xl"
                    header={
                        <div className={classnames('offer-ko-crit-details-modal__header')}>
                            <div className={classnames('offer-ko-crit-details-modal__header-text')}>
                                <span>{`${rowData.critName}`}</span>
                            </div>
                            <div className={classnames('offer-ko-crit-details-modal__header-close')}>
                                <Button
                                    buttonType="text"
                                    textColor="neutral"
                                    size="xs"
                                    aria-label="Закрыть окно"
                                    onClick={() => {
                                        setShowModal(false);
                                    }}
                                    startAdornment={<MdClose size="24" />}
                                />
                            </div>
                        </div>
                    }
                >
                    {errorLabel}
                    {!isEdit && disabledHint && (
                        <div className={'offer-ko-crit-details__hint'}>
                            <Hint icon="info" title={disabledHint} variant="yellow" maxWidth="100%" />
                        </div>
                    )}

                    <FormProviderControl
                        formMethods={methods}
                        className="offer-ko-crit-details__form"
                        onSubmit={onFormSubmit}
                    >
                        <HookFormProvider controller={Controller}>
                            <div className="offer-ko-crit-details__content">
                                <div className="offer-ko-crit-details__row">
                                    <SelectControl
                                        label="Результат допуска"
                                        name="result"
                                        readOnly={true}
                                        disabled={!isEdit}
                                        highlightMenuItems={false}
                                        options={results}
                                        formState={methods.formState}
                                        control={methods.control}
                                        required={true}
                                        rules={{ required: 'Обязательное поле' }}
                                        setValue={(options) => {
                                            return options[0].value;
                                        }}
                                        getValue={(options: IOption[], value: any) => {
                                            return options.filter((x) => x.value === value);
                                        }}
                                    />
                                </div>
                            </div>

                            {isEdit ? (
                                <div className="offer-ko-crit-details__footer">
                                    <div
                                        className={classnames(
                                            'offer-ko-crit-details__row',
                                            'offer-ko-crit-details__submit-row',
                                        )}
                                    >
                                        <Button
                                            type="submit"
                                            preloader={isSubmitting}
                                            className="offer-ko-crit-details__button offer-ko-crit-details__save-button"
                                            disabled={isSubmitting || !isDirty}
                                        >
                                            Сохранить
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                        </HookFormProvider>
                    </FormProviderControl>
                </Modal>
            ) : null}
        </>
    );
};

export default OfferKOCritDetails;
