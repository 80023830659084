import React, { HTMLProps, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import './Search.scss';
import { MdClose, MdSearch } from 'react-icons/md';

import { IDebounceResult } from '@/types/projects.types';
import { classnames } from '@utils/classnames';

import { useDebounce } from '@hooks/useDebounce';
import List from '../Menu/List';
import { SearchService } from '@services/SearchService';
import { IHistorySearchItem } from '@models/search/IHistorySearchItem';
import Preloader from '../Preloader';
import Button from '../Button';

export interface ISearchProps extends HTMLProps<HTMLInputElement> {
    /** Возможность очистки поля по клику */
    onClear?: () => void;
    /** Показать иконку очистки */
    showClear?: boolean;
    /** Дебаунс */
    debounce?: number;
    /** Иконка в конце поля */
    endAdornment?: ReactNode;
    /** Обработка нажатий с эффектом debounce */
    onDebounce?: (result: IDebounceResult) => void;
    /**
     * Проверять ввод в соответствии с регулярным выражением
     * @example Для проверки на отсутствие спецсимволов в строке можно использовать `'^[\da-zA-Zа-яА-Я]*$'`
     */
    pattern?: string;
    /**
     * Является ли Search компонентом HistoryCardFilter
     * @default false
     */
    isCardFilter?: boolean;
    /*Выбор элемента из истории поиска в выпадайке*/
    onHistorySearchSelected?: (historyItem: IHistorySearchItem) => void;
}

const Search: React.FC<ISearchProps> = ({
    onClear,
    showClear = true,
    debounce = 500,
    endAdornment,
    onDebounce = () => {},
    pattern,
    isCardFilter = false,
    onHistorySearchSelected,
    ...props
}: ISearchProps) => {
    // -------------------------------------------------------------------------------------------------------------------
    const mouseOnComponent = useRef(true);

    const [showHistorySearchItems, setShowHistorySearchItems] = useState<boolean>(false);
    const [loadingHistorySearchItems, setLoadingHistorySearchItems] = useState<boolean>(false);
    const [historySearchItems, setHistorySearchItems] = useState<IHistorySearchItem[]>([]);

    const [value, setValue] = useState<string>(props.value ? props.value.toString() : '');
    const ref = useRef<HTMLInputElement>(null);

    // Регулярное выражение для проверки ввода
    const regexp = useMemo(() => {
        if (pattern) {
            return new RegExp(pattern);
        }

        return null;
    }, [pattern]);

    // =======================

    useDebounce(value, debounce, (v) => {
        if (onDebounce) {
            onDebounce({ debounceString: v.toString() });
        }
    });

    useEffect(() => {
        setValue(props.value ? props.value.toString() : '');
    }, [props.value]);

    // -------------------------------------------------------------------------------------------------------------------

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (regexp && !regexp.test(e.target.value)) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        setValue(e.target.value);
        props.onChange && props.onChange(e);
    };

    const onClearClickHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.preventDefault();
        setValue('');
        onClear && onClear();
        onDebounce && onDebounce({ debounceString: '' });
    };

    const removeHistorySearchItem = (e: React.MouseEvent, id: number) => {
        e.stopPropagation();

        SearchService.removeHistorySearchItem(id).then(() => {
            if (!loadingHistorySearchItems) {
                SearchService.loadHistorySearchItems().then((res) => {
                    setHistorySearchItems(res.data);
                    setShowHistorySearchItems(true);
                });
            }
        });
    };

    const onLoadHistorySearchItems = (e: any) => {
        if (!loadingHistorySearchItems) {
            setShowHistorySearchItems(true);
            setLoadingHistorySearchItems(true);
            SearchService.loadHistorySearchItems()
                .then((res) => {
                    //  setLoadingHistorySearchItems(false);
                    setHistorySearchItems(res.data);
                })
                .finally(() => {
                    setLoadingHistorySearchItems(false);
                });
        }
    };

    const onKeyDown = (e: any) => {
        if (e.keyCode == 13) {
            setShowHistorySearchItems(false);
        }
    };

    // -------------------------------------------------------------------------------------------------------------------

    return (
        <div
            className={classnames('rf-search', isCardFilter && 'rf-search-hcf')}
            onMouseEnter={() => {
                mouseOnComponent.current = true;
            }}
            onMouseLeave={() => {
                mouseOnComponent.current = false;
                setTimeout(() => {
                    if (!mouseOnComponent.current) setShowHistorySearchItems(false);
                }, 1000);
            }}
        >
            <input
                {...props}
                ref={ref}
                type="text"
                className={classnames(endAdornment ? 'rf-search__input-endAdornment' : 'rf-search__input')}
                placeholder={props.placeholder || 'Поиск'}
                value={value}
                data-testid="search-test-id"
                onChange={onChangeHandler}
                onClick={onLoadHistorySearchItems}
                pattern={pattern}
                onKeyDown={onKeyDown}
            />
            <MdSearch className="rf-search__search-icon" size="24" />

            {value.length > 0 && showClear && (
                <MdClose
                    data-testid="search-clear-test-id"
                    className={classnames(
                        'rf-search__close-icon',
                        !!endAdornment && 'rf-search__close-withEndAdornment',
                    )}
                    onMouseDown={onClearClickHandler}
                />
            )}
            {endAdornment && <div className="rf-search__endAdornment">{endAdornment}</div>}

            {showHistorySearchItems &&
                (loadingHistorySearchItems ? (
                    <Preloader size="m" />
                ) : historySearchItems?.length > 0 ? (
                    <div>
                        <List
                            className="dropDownHistoryItems"
                            list={historySearchItems.map((x) => ({
                                label: (
                                    <div className={'rf-search__historyItem'}>
                                        <div className={'rf-search__historyItemText'}>{x.text}</div>
                                        <Button
                                            size="s"
                                            buttonType={'text'}
                                            textColor="neutral"
                                            onClick={(e) => {
                                                removeHistorySearchItem(e, x.id);
                                            }}
                                            startAdornment={<MdClose />}
                                            aria-label={'Удалить из истории'}
                                        />
                                    </div>
                                ),
                                value: x.text,
                                handler: () => {
                                    setShowHistorySearchItems(false);

                                    if (onHistorySearchSelected) {
                                        setValue(x.text);
                                        onHistorySearchSelected(x);
                                    }
                                },
                            }))}
                        />
                    </div>
                ) : null)}
        </div>
    );
};

export default Search;
