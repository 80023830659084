import React, { FC, useEffect, useState } from 'react';
import { RouteNames } from '@/router';
import './ReleaseNotes.scss';
import Button from '@/components/atoms/Button';
import { MdClose } from 'react-icons/md';
import { ReleaseNotesService } from '@/services/ReleaseNotesService';
import { nodeModuleNameResolver } from 'typescript';
import DOMPurify from 'dompurify';
import { IReleaseShortNotesDto } from '@/models/releaseNotes/IReleaseShortNotesDto';

const ReleaseNotes: FC = () => {
    const [text, setText] = useState<string | undefined>(undefined);

    useEffect(() => {
        var savedKey = localStorage.getItem('ReleaseNotesService');
        var today = new Date();

        if (savedKey === null || new Date(+savedKey).getDate() != today.getDate()) {
            ReleaseNotesService.fetchLastItem().then(function (note) {
                if (note && note.data && note.data.key) {
                    let temp = replaceLinkText(note.data);
                    setText(temp);
                }
            });
        }
    }, []);

    const replaceLinkText = (data: IReleaseShortNotesDto): string => {
        var url = `releasenotes?key=${data.key}`;
        let dataText = data.short;
        dataText = dataText?.replace('%link%', url);
        return dataText;
    };

    return text ? (
        <div className="rf-releasenotes">
            <div className="rf-releasenotes-bar rf-releasenotes-hidden" id="releaseNotes">
                <div
                    className="rf-releasenotes-content"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
                    id="rf-releasenotes-content"
                ></div>

                <div className="rf-releasenotes-close" id="rf-releasenotes-close">
                    <Button
                        buttonType="text"
                        textColor="neutral"
                        size="xs"
                        aria-label="Закрыть окно"
                        onClick={() => {
                            let today = new Date();
                            localStorage.setItem('ReleaseNotesService', today.getTime().toString());
                            setText(undefined);
                        }}
                        startAdornment={<MdClose size="24" />}
                    />
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default ReleaseNotes;
