import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IAllRoutesActions } from '@models/actions/IAllRoutesActions';
import { IBaseAction } from '@models/actions/IBaseAction';
import { ProcessActionTypes } from '@models/actions/ProcessActionTypes';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { RemoveFromControlExecutor } from '@utils/actions/processExecutors/RemoveFromControlExecutor';
import { ActivityActionsService } from './ActivityActionsService';
import { ActivityLocationTypes } from './ActivityLocationTypes';
import { BaseActionsService } from './BaseActionsService';
import { ChangeControllerExecutor } from '@utils/actions/processExecutors/ChangeControllerExecutor';
import { IChangeControllerModel } from '@models/document/actionsExecution/IChangeControllerModel';

export class ProcessActionsService extends BaseActionsService {
    public allExecutors: { [actType: string]: () => IActionExecutor } = {
        [ProcessActionTypes[ProcessActionTypes.RemoveFromControl]]: () => new RemoveFromControlExecutor(),
        [ProcessActionTypes[ProcessActionTypes.ChangeController]]: () => new ChangeControllerExecutor(),
    };

    constructor() {
        super();
        let activityActionsService = new ActivityActionsService(ActivityLocationTypes.DocumentView);
        for (let execKey in activityActionsService.allExecutors) {
            this.allExecutors[execKey] = activityActionsService.allExecutors[execKey];
        }
    }

    //Получает действия для процесса
    async fetchActions(objId: string): Promise<AxiosResponse<IBaseAction[]>> {
        let ids = objId.split('_');
        let processId = ids[0];
        let actionId = ids[1];

        if (actionId) {
            let actService = new ActivityActionsService(ActivityLocationTypes.DocumentView);
            return actService.fetchActions(actionId);
        } else {
            return $api.get<IBaseAction[]>(`tasks/getActionProcesses/${processId}`);
        }
    }

    //Получает действия для процесса
    async fetchGroupActions(objIds: string): Promise<AxiosResponse<IBaseAction[]>> {
        // групповые действия не поддерживаются
        return new Promise<AxiosResponse<IBaseAction[]>>(() => {});
    }

    //Получает все активити по документу (по всем активити и процессам)
    async fetchActionsDocumentView(docId: string): Promise<AxiosResponse<IAllRoutesActions>> {
        return this.fetchAllRouteActionsByLocation(docId, ActivityLocationTypes.DocumentView);
    }

    //Получает все активити по документу (по всем активити и процессам)
    async fetchAllRouteActionsTitleBar(
        docId: string,
        openDocumentGuid: string | undefined,
    ): Promise<AxiosResponse<IAllRoutesActions>> {
        return this.fetchAllRouteActionsByLocation(docId, ActivityLocationTypes.DocumentTitleBar, openDocumentGuid);
    }

    //Получает все активити по документу (по всем активити и процессам)
    async fetchAllRouteActionsByLocation(
        docId: string,
        location: number,
        openDocumentGuid: string | undefined = undefined,
    ): Promise<AxiosResponse<IAllRoutesActions>> {
        return $api.get<IAllRoutesActions>(`tasks/getAllDocActions/${docId}`, {
            params: {
                location: location,
                openDocumentGuid: openDocumentGuid,
            },
        });
    }

    //Получает все активити по документу (по всем активити и процессам)
    async fetchAllRouteActions(docId: string): Promise<AxiosResponse<IAllRoutesActions>> {
        return $api.get<IAllRoutesActions>(`tasks/getAllDocActions/${docId}`);
    }

    removeFromControl(processId: string, comment?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('comment', comment as string);
        return $api.post(`processActions/removeFromControl/${processId}`, formData);
    }

    async getChangeControllerModel(processId: string): Promise<AxiosResponse<IChangeControllerModel>> {
        return $api.get<IChangeControllerModel>(`processActions/changeController/${processId}`);
    }

    async saveChangeController(processId: string, controllerKey: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('controllerKey', controllerKey);
        return $api.post(`processActions/changeController/${processId}`, formData);
    }
}
