export enum ActionTypes {
    /// <summary>
    /// Действия по документу
    /// </summary>
    Document,
    /// <summary>
    /// Действия по активити
    /// </summary>
    Activity,
    /// <summary>
    /// Действия по процессу
    /// </summary>
    Process,
    /// <summary>
    /// Действия по карте маршрута
    /// </summary>
    RouteMap,
}
