import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IReleaseNotesDto } from '@/models/releaseNotes/IReleaseNotesDto';
import { IReleaseShortNotesDto } from '@/models/releaseNotes/IReleaseShortNotesDto';

export class ReleaseNotesService {
    static async fetchLastItem(): Promise<AxiosResponse<IReleaseShortNotesDto, any>> {
        return $api.get<IReleaseShortNotesDto>(`/releasenotes/last`);
    }

    static async fetchItemByKey(key: string): Promise<AxiosResponse<IReleaseNotesDto, any>> {
        return $api.get<IReleaseNotesDto>(`/releasenotes/key/${key}`);
    }
}
