import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IWidgetsData } from '@models/widgets/IWidgetsData';

export class WidgetsService {
    static async getData(): Promise<AxiosResponse<IWidgetsData>> {
        let res = await $api.get<IWidgetsData>(`/widgets/`);
        if (Object.keys(res.data).length === 0) {
            res.data.widgetIds = [];
            res.data.widgetConfigurations = {};
            res.data.layouts = {};
        }
        return res;
    }
    static async setData(data: IWidgetsData): Promise<AxiosResponse<void>> {
        return $api.post('/widgets/', data);
        //return axios.post("/notifications/markAsRead", keys);
    }
}
