import { UseFormReturn, useWatch } from 'react-hook-form';
import { IDictFilter, IDocumentDict, ISetValue } from '@models/Forms/IForms';
import { handlerFieldDisplayWatch, handlerFieldWatch, parseDisplayFormat } from '@utils/documentUtils';
import { DictpickerControl } from '@controls/index';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import './Dict.scss';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { addRow, ChangesFieldValue, clearTables, GetValueForSetValue } from '@utils/ChangesManager';
import { DictExternalDataSource } from '@utils/DictExternalDataSource';
import ToolTipTitle from '@atoms/ToolTipTitle/ToolTipTitle';
import { IPartialFormGroup } from '@controls/types';
import Moment from 'moment';
import DictChipItem from '@molecules/formbuilder/controls/Dict/DictChipItem/DictChipItem';

export interface IDictProps<TFieldValues extends object = object> {
    dict?: IDocumentDict;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: string[]) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
    docId?: string;
}

const Dict = <TFieldValues extends object = object>({
    dict,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    includedFields,
    docId,
    ...props
}: IDictProps<TFieldValues>) => {
    const activated = React.useRef(false);
    const watchDataDisplayFormat = React.useRef<any[]>([]);
    let visibilityRules = dict?.visibilityRules;
    let readOnlyRules = dict?.readonly;
    let requiredRules = dict?.required;
    const visibilityMng = new FormulaManager(visibilityRules!);
    const readOnlyMng = new FormulaManager(readOnlyRules!);
    const requiredMng = new FormulaManager(requiredRules!);
    const displayFormatMng = new FormulaManager(dict?.displayFormat!);
    visibilityMng.Init(fields, formMethods);
    readOnlyMng.Init(fields, formMethods);
    requiredMng.Init(fields, formMethods);
    displayFormatMng.Init(fields, formMethods);
    const [visibility, setVisibility] = useState<boolean>(false);
    const [required, setRequired] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [displayVal, setDisplayVal] = useState<string>('');
    const [rawVal, setRawVal] = useState<string>('');

    const InitFormulas = async () => {
        let vis = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(vis);
        }

        if (requiredRules) {
            let req = await requiredMng.EvalFormulaValues(isEdit, isNew);
            if (activated.current) {
                setRequired(req);
            }
        }

        if (readOnlyRules) {
            let readonly = await readOnlyMng.EvalFormulaValues(isEdit, isNew);
            if (activated.current) {
                setReadOnly(readonly);
            }
        }

        let dataDisplay = await displayFormatMng.ReplaceFormulaValues(isEdit, isNew, undefined, true);
        if (activated.current) {
            setDisplayVal(dataDisplay ? dataDisplay : '');
            setRawVal(dataField.value);
        }
        if (isNew && dict?.invokeChangesOnNew && vis) {
            let curValue = fields[dict.key];
            await ChangesFieldValue(
                dict?.changes!,
                curValue.value,
                dict?.key!,
                fields,
                isEdit,
                isNew,
                onSaveField,
                setError,
                undefined,
                undefined,
                formMethods,
            );
        }
    };

    useEffect(() => {
        if (isEdit && dict?.invokeChangesOnEdit && visibility) {
            let curValue = fields[dict.key];
            ChangesFieldValue(
                dict?.changes!,
                curValue.value,
                dict?.key!,
                fields,
                isEdit,
                isNew,
                onSaveField,
                setError,
                undefined,
                undefined,
                formMethods,
            );
        }
    }, [isEdit]);

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    const watchVisibility = useWatch({
        name: visibilityMng.GetWatchFields(),
    });
    const watchReadOnly = useWatch({
        name: readOnlyMng.GetWatchFields(),
    });
    const watchRequired = useWatch({
        name: requiredMng.GetWatchFields(),
    });
    const watchDisplayFormat = useWatch({
        name: displayFormatMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watchVisibility, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watchVisibility, isEdit, isNew]);
    useEffect(() => {
        if (readOnlyRules) {
            handlerFieldWatch(watchReadOnly, isEdit, isNew, readOnlyMng, setReadOnly, activated);
        }
    }, [watchReadOnly, isEdit, isNew]);
    useEffect(() => {
        if (requiredRules) {
            handlerFieldWatch(watchRequired, isEdit, isNew, requiredMng, setRequired, activated);
        }
    }, [watchRequired, isEdit, isNew]);

    const handlerDisplayVal = async (watch: any[]) => {
        let dataDisplay = await handlerFieldDisplayWatch(watch, isEdit, isNew, displayFormatMng);
        if (activated.current && watchDataDisplayFormat.current == dataDisplay?.watch) {
            setDisplayVal(dataDisplay?.result);
        }
    };

    useEffect(() => {
        watchDataDisplayFormat.current = watchDisplayFormat;
        handlerDisplayVal(watchDisplayFormat);
    }, [watchDisplayFormat]);

    const onSaveField = async (item: ISetValue, rowData?: any) => {
        let val = await GetValueForSetValue(item, undefined, fields, rowData, formMethods);
        let field = fields[item.key];
        formMethods.setValue(field.name as any, val, { shouldDirty: true });
    };

    const dataField = fields[dict?.key!];
    const idField = dataField?.index;
    includedFields.push(idField);

    const onSetValues = (value?: IDictionaryData[]) => {
        //TODO убрать всё это и сделать чтобы работало по основной логике через ченджМэнеджер
        let display = dict?.displayFormat;
        dict?.setValues?.sets?.forEach((item) => {
            let field = fields[item.key];
            let val = getValues(value, item.attr, item.separator ? item.separator : '|') as any;
            if (display?.indexOf(item.key)) {
                if (dict.source?.table) {
                    let columnName = display
                        .match(/\[.*?\]/g)
                        ?.values()
                        ?.next()?.value as string;
                    columnName = columnName.replace('[', '').replace(']', '');
                    let fname = ('fields.[' + idField + '].value') as any;
                    let val = formMethods.getValues(fname);
                    display = (val as any[]).map((item) => item[columnName]).join(', ');
                } else {
                    let fieldVal = '{' + item.key + '}';
                    var source = new RegExp(fieldVal.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
                    display = display.replace(source, val);
                }
            }
            if (item.type) {
                switch (item.type) {
                    case 'double':
                    case 'integer': {
                        try {
                            if (typeof val == 'string' && val.indexOf(',')) {
                                val = val.replace(',', '.');
                            }
                            val = +val;
                            if (isNaN(val)) {
                                val = 0;
                            }
                        } catch (error) {
                            val = 0;
                        }
                        break;
                    }
                    case 'json': {
                        val = JSON.parse(val);
                        break;
                    }
                    case 'date': {
                        try {
                            let parseVal = new Date(val);
                            if (!isNaN(parseVal.getTime()) && parseVal.getTime() > 0) {
                                val = parseVal;
                            } else {
                                throw new Error('parse Date');
                            }
                        } catch (error) {
                            let parts = val.split(' ');
                            if (parts.length > 1) {
                                val = Moment(val, 'DD.MM.YYYY hh.mm.ss').toDate();
                            } else {
                                if (Moment(val, 'DD.MM.YYYY').isValid()) {
                                    val = Moment(val, 'DD.MM.YYYY').toDate();
                                } else {
                                    if (Moment(val).isValid()) {
                                        val = Moment(val).toDate();
                                    } else {
                                        val = null;
                                    }
                                }
                            }
                        }
                        break;
                    }
                    default:
                        val = val;
                        break;
                }
            }

            formMethods.setValue(field.name as any, val, { shouldDirty: true });
        });
        clearTables(dict?.setValues?.clearTables!, formMethods, fields);
        addRow(dict?.setValues?.addRows!, formMethods, fields, '');
        if (display?.indexOf(dict?.key!) && value !== undefined) {
            let fname = ('fields.[' + idField + '].value') as any;
            let val = formMethods.getValues(fname);
            let fieldVal = '{' + dict?.key + '}';
            var source = new RegExp(fieldVal.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
            display = display.replace(source, val as any);
        }
        display = value === undefined || null ? '' : parseDisplayFormat(display, fields).text;
        setDisplayVal(display!);
        let result = [] as string[];
        value?.forEach((item) => {
            let elem = item.code;
            result.push(elem.toString()); //elem?.value?.toString()!);
        });
        // ChangesFieldValue(
        //     dict?.changes!,
        //     result.join("|"),
        //     dict?.key!,
        //     fields,
        //     isEdit,
        //     isNew,
        //     onSaveField,
        //     setError,
        //     undefined
        // );
    };

    const getValues = (values?: IDictionaryData[], attr?: string, separator: string = '|') => {
        if (values === undefined) {
            return '';
        }
        let result = [] as string[];
        values?.forEach((item) => {
            let elem = item.fields.find((obj) => {
                return obj.name === attr;
            });
            result.push(elem?.value?.toString()!);
        });
        return result.join(separator);
    };

    const getFormValuesAsync = async () => {
        let formdataParams = dict?.formValues;
        const map = {} as Record<string, any>;
        if (formdataParams && formdataParams != null) {
            for (const ele of formdataParams?.formValue!) {
                if (ele.formula) {
                    let manager = new FormulaManager(ele.formula);
                    manager.Init(fields, formMethods);
                    map[ele.key] = await manager.ReplaceFormulaValues(isEdit, isNew);
                } else {
                    let field = fields[ele.attr];
                    let fname = ('fields.[' + field.index + '].value') as any;
                    let val = formMethods.getValues(fname);
                    if (ele.column) {
                        if (ele.function === '{join}') {
                            map[ele.key] = (val as [])?.map((item: any) => `'${item[ele.column]}'`).join(',') ?? val;
                        } else {
                            map[ele.key] = (val as [])?.map((item: any) => item[ele.column]) ?? val;
                        }
                    } else {
                        map[ele.key] = val;
                    }
                }
            }
        }
        return JSON.stringify(map);
    };

    const getFiltersAsync = async () => {
        let filters = dict?.filters;
        let result = {} as IDictFilter;
        const map = {} as Record<string, any>;
        if (filters && filters != null) {
            for (const ele of filters.filter) {
                let conditionMng = new FormulaManager(ele.condition);
                conditionMng.Init(fields, formMethods);
                if (await conditionMng.EvalFormulaValues(isEdit, isNew)) {
                    result.condition = ele.condition;
                    if (ele.filter) {
                        let filterMng = new FormulaManager(ele.filter);
                        filterMng.Init(fields, formMethods);
                        result.filter = (await filterMng.ReplaceFormulaValues(isEdit, isNew))!;
                    }
                    if (ele.script) {
                        let scriptMng = new FormulaManager(ele.script);
                        scriptMng.Init(fields, formMethods);
                        result.script = (await scriptMng.ReplaceFormulaValues(isEdit, isNew))!;
                    }
                }
            }
        }
        return result;
    };

    const getExternalDataSource = () => {
        if (dict?.externalDataSource) {
            const dictExternalDataSource = new DictExternalDataSource(dict?.externalDataSource);
            return dictExternalDataSource.GetData(formMethods, fields);
        }
        return [];
    };

    const onChangeDictValue = async (value?: any) => {
        setRawVal(value);
        await ChangesFieldValue(
            dict?.changes!,
            value,
            dict?.key!,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            undefined,
            undefined,
            formMethods,
        );
    };
    useEffect(() => {
        if (
            !required &&
            formMethods.formState.errors &&
            (formMethods.formState.errors as any).fields &&
            (formMethods.formState.errors as any).fields[dataField?.index]
        ) {
            formMethods.clearErrors(dataField?.name as any);
        }
    }, [required]);

    return dict && dict.key && visibility && idField != undefined ? (
        <div className="form-field" data-testid={dict.id ? `dict-${dict.id}` : undefined}>
            {isEdit || isNew ? (
                <DictpickerControl
                    label={dict?.name}
                    tooltip={<ToolTipTitle title={dict?.title} />}
                    modalTitle={dict?.modalTitle}
                    formGroupProps={{ verticalBottomAlign: dict?.verticalBottomAlign } as IPartialFormGroup}
                    docId={docId}
                    externalSearch={dict?.externalSearch}
                    addForm={dict?.addForm}
                    dictionaryName={dict?.dictName!}
                    setValues={dict?.setValues}
                    isFormData={dict?.isFormData}
                    isMultiple={dict?.isMultiple}
                    isSelectMode={dict?.isSelectMode}
                    getExternalDataSource={getExternalDataSource}
                    getFormValuesAsync={getFormValuesAsync}
                    getFiltersAsync={getFiltersAsync}
                    required={required}
                    rules={{
                        required: {
                            value: required ? required : false,
                            message: 'Обязательное поле',
                        },
                    }}
                    readOnly={readOnly}
                    diplayValue={displayVal}
                    rawVal={rawVal}
                    gridAttribute={dict?.gridAttribute}
                    startLevel={dict?.startLevel}
                    name={`fields.[${idField}].value`}
                    formState={formMethods.formState}
                    onSetValues={onSetValues}
                    control={formMethods.control}
                    insideGrid={false}
                    onDictValueChanged={onChangeDictValue}
                    selectableLevels={dict?.selectableLevels}
                    visibleLevels={dict?.visibleLevels}
                    predicatesCache={dict?.predicatesCache}
                    loadMode={dict?.loadMode}
                    joinedDictionaries={dict?.joinedDictionaries}
                    source={dict?.source}
                    useClientSideDataProcessing={dict?.useClientSideDataProcessing}
                    chipsView={dict.chipsView}
                    placeholder={dict?.placeholder ? dict.placeholder : undefined}
                />
            ) : (
                <FieldWrapper inLineFormat={dict.isValue} lable={dict.name} title={dict.viewTitle}>
                    {dict.chipsView ? (
                        <div className="dict-chips-items">
                            {parseDisplayFormat(dict?.displayFormat, fields).values.map(
                                (item: string, index: number) => (
                                    <DictChipItem
                                        key={index}
                                        item={item}
                                        showRemoveIcon={false}
                                        /*onRemove={() => {}}*/
                                    />
                                ),
                            )}
                        </div>
                    ) : (
                        parseDisplayFormat(dict?.displayFormat, fields).text
                    )}
                </FieldWrapper>
            )}
        </div>
    ) : (
        <></>
    );
};

export default Dict;
