import React, { ReactNode } from 'react';
import { IControlProps, IPartialFormGroup } from '@controls/types';
import { IOption } from '@/types';
import FormControl from '../FormControl';
import Select, { ISelectProps } from '@atoms/Select/Select';
import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';

export type ISelectControlProps = Omit<ISelectProps, 'onChange' | 'values'> &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        required?: boolean;
        onChangeVal?: (value: IOption[]) => void;
        getValue?: (options: IOption[], value: any) => IOption[];
        setValue?: (value: IOption[]) => any;
    };

const SelectControl = ({
    name,
    rules,
    defaultValue = [],
    control,
    formState,
    shouldUnregister,
    label,
    required,
    formGroupProps,
    onChangeVal,
    getValue,
    setValue,
    highlightMenuItems = true,
    ...props
}: ISelectControlProps) => {
    const Controller = useHookFormController();

    const prepareValue = (options: IOption[], value: any): IOption[] | undefined => {
        if (getValue) {
            return getValue(options, value);
        } else {
            return value;
        }
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            defaultValue={defaultValue}
            render={({ field: { ref, value, onChange, ...fieldProps } }) => (
                <FormControl name={name} label={label} formState={formState} required={required} {...formGroupProps}>
                    <Select
                        {...fieldProps}
                        {...props}
                        highlightMenuItems={highlightMenuItems}
                        values={prepareValue(props.options, value)}
                        onChange={(option: IOption[]) => {
                            if (setValue) {
                                let val = setValue(option);
                                onChange(val);
                            } else {
                                onChange(option);
                            }
                            if (onChangeVal) {
                                onChangeVal(option);
                            }
                        }}
                    />
                </FormControl>
            )}
        />
    );
};

export default SelectControl;
