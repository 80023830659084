import React, { FC, useEffect, useState } from 'react';
import { IFile, IFileCatalog, IFileProperty } from '@models/documentation/IDocumentation';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { Column } from 'devextreme-react/data-grid';
import { DocumentationService } from '@services/DocumentationService';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { Template } from 'devextreme-react/core/template';
import { API_URL } from '@/http/index';

export interface IDocumentationTabItemProps {
    fileCatalog: IFileCatalog;
}

const DocumentationTabItem: FC<IDocumentationTabItemProps> = ({ fileCatalog }: IDocumentationTabItemProps) => {
    const [coll, setColl] = useState<IFile[]>();
    const activated = React.useRef(false);

    const Init = async () => {
        let response = await DocumentationService.getFiles(fileCatalog.key);

        setColl(response.data);
    };

    useEffect(() => {
        activated.current = true;
        Init();
        return () => {
            activated.current = false;
        };
    }, []);

    const dataSource = new DataSource({
        store: new ArrayStore({
            key: 'name',
            data: coll,
        }),
    });

    return (
        <DevExpressDataGrid dataSource={dataSource} onCellHoverChanged={onCellHoverChanged}>
            {fileCatalog.displayedFileProperties.map((val: IFileProperty, index: number) => {
                return (
                    <Column
                        dataField={val.name}
                        caption={val.displayName}
                        key={index}
                        width={
                            val.name == 'name'
                                ? 100 - (fileCatalog.displayedFileProperties.length - 1) * 10 + '%'
                                : 10 + '%'
                        }
                        alignment={val.name == 'name' ? 'left' : 'center'}
                        cellTemplate="dxGridCellTemplate"
                    />
                );
            })}

            <Template
                name="dxGridCellTemplate"
                render={function (data: any) {
                    const text = data.value;
                    const link = `${API_URL}/${data.data.link}&access_token=${localStorage.getItem(
                        'frontAccessToken',
                    )}`;

                    return <a href={`${link}`}>{text}</a>;
                }}
            />
        </DevExpressDataGrid>
    );
};

export default DocumentationTabItem;
