import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import './TableData.scss';
import { IDocumentTable } from '@models/Forms/IForms';
import TableForm from './ModalForms/TableForm';
import { IField, IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import Pagination from './Pagination';

export interface RowEditButtonProps {
    table: IDocumentTable;
    rowData: any;
    getParentFields: () => IField[];
    onSubmit: (data: IField[], rowIndex: number) => void;
    docId?: string;
    displayFormula?: string;
    rowArray: any;
    onMounted?: () => void;
}

export type IRowEditButtonHandle = {
    showModal: () => void;
};

const RowEditButton = forwardRef<IRowEditButtonHandle, RowEditButtonProps>(
    ({ table, rowData, getParentFields, onSubmit, docId, displayFormula, rowArray, onMounted}: RowEditButtonProps, ref) => {
        const [showModal, setShowModal] = useState<boolean>(false);
        const [count, setCount] = useState(0);
        const [isMounted, setIsMounted] = useState<boolean>(false);
        const [isVisible, setIsVisible] = useState<boolean>(true);
        const rowIndex = rowArray.findIndex((r: any) => r["|NUM"] == rowData['|NUM']);

        // Similar to componentDidMount and componentDidUpdate:
        useEffect(() => {
            setIsMounted(true);
            onMounted && onMounted();

            const calcVisibliRules = async () => {
                if (displayFormula) {
                    let formulaMgr = new FormulaManager(displayFormula);
                    let formulaData: Record<string, IFieldElem> = {} as Record<string, IFieldElem>;
                    for (let key in rowData) {
                        formulaData[key] = {
                            index: 0,
                            name: '',
                            value: rowData[key],
                        };
                    }
                    formulaMgr.Init(formulaData);
                    let vis = await formulaMgr.EvalFormulaValues(true, false);
                    setIsVisible(vis);
                }
            };
            calcVisibliRules();

            return () => {
                // componentwillunmount in functional component.
                setIsMounted(false);
            };
        }, []);

    const onClick = (e: any) => {      
        e.preventDefault();
        e.stopPropagation();
        setShowModal(!showModal);
    };

        const onSubmitHandler = (data: IField[], rowIndex: number) => {
            onSubmit(data, rowIndex);
            setShowModal(!showModal);
        };

        // Вызов открытия окна извне
        useImperativeHandle(ref, () => ({
            showModal() {
                setShowModal(true);
            },
        }));

    return !isVisible ? (
        <></>
    ) : (
        <>
            <a
                href="#"
                className="dx-link dx-link-delete dx-icon-edit dx-link-icon"
                onClick={onClick}
                title="Редактировать"
                aria-label="Редактировать"
            />
            {showModal && (
                
                <TableForm
                    table={table}
                    docId={docId}
                    title="Редактирование таблицы"
                    isEdit={true}
                    onClose={() => {
                        setShowModal(!showModal);
                    }}
                    getParentFields={getParentFields}
                    onSubmit={onSubmitHandler}
                    rowIndex={rowIndex}
                    rowArray={rowArray}
                ></TableForm>

            )}
        </>
    );
});

export default RowEditButton;
