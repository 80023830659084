import { UseFormReturn, useWatch } from 'react-hook-form';
import { IDocumentAutoComplete, ISetValue } from '@models/Forms/IForms';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import './AutoComplete.scss';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import { handlerFieldWatch } from '@utils/documentUtils';
import { ChangesFieldValue, GetValueForSetValue } from '@utils/ChangesManager';
import DisplayField from '@atoms/DisplayField/DisplayField';
import { ValueType } from '@/types/ValueType';
import AutoCompleteControl from '@controls/AutoCompleteControl';
import ToolTipTitle from '@atoms/ToolTipTitle/ToolTipTitle';
import { getOptions } from './AutoCompleteHelper';
import { IPartialFormGroup } from '@controls/types';

export interface IAutoCompleteProps<TFieldValues extends object = object> {
    autoComplete?: IDocumentAutoComplete;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: string[]) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
}

const AutoComplete = <TFieldValues extends object = object>({
    autoComplete,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    includedFields,
    ...props
}: IAutoCompleteProps<TFieldValues>) => {
    let visibilityRules = autoComplete?.visibilityRules;
    let readOnlyRules = autoComplete?.readonly;
    let requiredRules = autoComplete?.required;
    const visibilityMng = new FormulaManager(visibilityRules!);
    const readOnlyMng = new FormulaManager(readOnlyRules!);
    const requiredMng = new FormulaManager(requiredRules!);
    const dataField = fields[autoComplete?.key!];
    const idField = dataField?.name;
    includedFields.push(dataField?.index);
    visibilityMng.Init(fields, formMethods);
    readOnlyMng.Init(fields, formMethods);
    requiredMng.Init(fields, formMethods);

    const activated = React.useRef(false);

    const [visibility, setVisibility] = useState<boolean>(false);
    const [required, setRequired] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);

    const findOptions = async (text: string) => {
        let opts = await getOptions(autoComplete?.dataSource!, text);
        return opts;
    };

    const InitFormulas = async () => {
        let vis = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(vis);
        }
        if (isEdit || isNew) {
            if (requiredRules) {
                let req = await requiredMng.EvalFormulaValues(isEdit, isNew);
                if (activated.current) {
                    setRequired(req);
                }
            }
            let readonly = false;
            if (readOnlyRules) {
                readonly = await readOnlyMng.EvalFormulaValues(isEdit, isNew);
                if (activated.current) {
                    setReadOnly(readonly);
                }
            }
        }
    };

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    const watchVisibility = useWatch({
        name: visibilityMng.GetWatchFields(),
    });
    const watchReadOnly = useWatch({
        name: readOnlyMng.GetWatchFields(),
    });
    const watchRequired = useWatch({
        name: requiredMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watchVisibility, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watchVisibility, isEdit, isNew]);
    useEffect(() => {
        if (readOnlyRules) {
            handlerFieldWatch(watchReadOnly, isEdit, isNew, readOnlyMng, setReadOnly, activated);
        }
    }, [watchReadOnly, isEdit, isNew]);
    useEffect(() => {
        if (requiredRules) {
            handlerFieldWatch(watchRequired, isEdit, isNew, requiredMng, setRequired, activated);
        }
    }, [watchRequired, isEdit, isNew]);

    const onSaveField = async (item: ISetValue, rowData?: any) => {
        let val = await GetValueForSetValue(item, undefined, fields, rowData, formMethods);
        let field = fields[item.key];
        formMethods.setValue(field.name as any, val, { shouldDirty: true });
    };

    const onInputChanged = async (value: string) => {
        await ChangesFieldValue(
            autoComplete?.changes!,
            value,
            autoComplete?.key!,
            fields,
            isEdit,
            isNew,
            onSaveField,
            setError,
            undefined,
            undefined,
            formMethods,
        );
    };
    useEffect(() => {
        if (
            !required &&
            formMethods.formState.errors &&
            (formMethods.formState.errors as any).fields &&
            (formMethods.formState.errors as any).fields[dataField?.index]
        ) {
            formMethods.clearErrors(dataField?.name as any);
        }
    }, [required]);

    return autoComplete && autoComplete.key && visibility && idField != undefined ? (
        <div className="form-field" data-testid={autoComplete.id ? `auto-complete-${autoComplete.id}` : undefined}>
            {isEdit || isNew ? (
                <AutoCompleteControl
                    label={autoComplete?.name}
                    tooltip={<ToolTipTitle title={autoComplete?.title} />}
                    formGroupProps={
                        {
                            verticalBottomAlign: autoComplete?.verticalBottomAlign,
                        } as IPartialFormGroup
                    }
                    disabled={readOnly}
                    required={required}
                    rules={{
                        required: {
                            value: required ? required : false,
                            message: 'Обязательное поле',
                        },
                    }}
                    name={idField}
                    formState={formMethods.formState}
                    control={formMethods.control}
                    onChangeVal={onInputChanged}
                    findOptions={findOptions}
                    placeholder={autoComplete?.placeholder ? autoComplete.placeholder : undefined}
                />
            ) : (
                <FieldWrapper
                    inLineFormat={autoComplete.isValue}
                    lable={autoComplete.name}
                    title={autoComplete.viewTitle}
                >
                    <DisplayField type={ValueType.Text} lable={autoComplete.name} value={dataField.value} />
                </FieldWrapper>
            )}
        </div>
    ) : (
        <></>
    );
};

export default AutoComplete;
