import { IField, IFieldElem } from '@models/IFormData';
import { IExternalDataSource, ITableSourceColumn } from '@models/Forms/IForms';
import { UseFormReturn } from 'react-hook-form';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';

export class DictExternalDataSource {
    private _externalDataSource: IExternalDataSource;

    constructor(externalDataSource: IExternalDataSource) {
        this._externalDataSource = externalDataSource;
    }

    GetData(formMethods: UseFormReturn<any>, fields: Record<string, IFieldElem>) {
        let result: IDictionaryData[] = [];
        if (this._externalDataSource) {
            if (this._externalDataSource.currentFieldsSource) {
                this._externalDataSource.currentFieldsSource.fields.forEach((settings) => {
                    let row = this.getDsRow(null, null, settings.columns, formMethods, fields);
                    result.push(row);
                });
            }
            if (this._externalDataSource.tableSource) {
                let settings = this._externalDataSource.tableSource;
                let field = fields[settings.tableName];
                let tableVal = formMethods.getValues(field.name as any) as any[];
                for (let index = 0; index < tableVal.length; index++) {
                    const elementTable = tableVal[index];
                    if (settings.subTableName) {
                        let subTable = elementTable[settings.subTableName] as any[];
                        if (subTable != undefined) {
                            for (let index = 0; index < subTable.length; index++) {
                                const elementSubTable = subTable[index];
                                let row = this.getDsRow(
                                    elementSubTable,
                                    elementTable,
                                    settings.columns,
                                    formMethods,
                                    fields,
                                );
                                result.push(row);
                            }
                        }
                    } else {
                        let row = this.getDsRow(elementTable, null, settings.columns, formMethods, fields);
                        result.push(row);
                    }
                }
            }
        }
        return result;
    }

    private replaceFieldsValues(val: string, fieldsArr: IField[]) {
        const regex = /{(?<column>.*?)\}/gm;
        let formula = val;
        let m;
        while ((m = regex.exec(val)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            let match = m[0];
            let columnCurrentRow = m.groups?.column;
            if (columnCurrentRow) {
                let field = fieldsArr.find((x) => {
                    return x.name == columnCurrentRow;
                });
                if (field && field.value) {
                    formula = formula.replace(match, field.value.toString());
                } else {
                    formula = formula.replace(match, '');
                }
            }
        }
        return formula;
    }

    private getDsRow(
        row: any,
        parent: any,
        settings: ITableSourceColumn[],
        formMethods: UseFormReturn<any>,
        fields: Record<string, IFieldElem>,
    ) {
        let code: any = '';
        let fieldsArr: IField[] = [];
        for (let index = 0; index < settings.length; index++) {
            const columnSettings = settings[index];
            let val: any = '';
            if (columnSettings.fieldName.includes('|Document')) {
                let field = fields[columnSettings.fieldName];
                val = formMethods.getValues(field.name as any);
            } else {
                if (columnSettings.isParentTable) {
                    val = parent[columnSettings.fieldName];
                } else {
                    if (columnSettings.fieldName.includes('{')) {
                        val = this.replaceFieldsValues(columnSettings.fieldName, fieldsArr);
                    } else {
                        val = row[columnSettings.fieldName];
                    }
                }
            }
            if (columnSettings.visibleName === 'code') {
                code = val;
            } else {
                fieldsArr.push({
                    name: columnSettings.visibleName,
                    value: val,
                } as IField);
            }
        }
        return { id: code, code: code, fields: fieldsArr } as IDictionaryData;
    }
}
