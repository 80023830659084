export enum RouteMapActionTypes {
    /// <summary>
    /// Добавить
    /// </summary>
    Add,
    /// <summary>
    /// Редактировать
    /// </summary>
    Edit,
    /// <summary>
    /// Удалить
    /// </summary>
    Remove,
}
