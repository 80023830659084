import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRole } from '@models/Interface/IRole';

export interface IRoleState {
    data?: IRole[];
    isLoading: boolean;
    error: string;
}

const initialState: IRoleState = {
    isLoading: true,
    error: '',
    data: [],
};

export const RolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        successLoadData: (state, action: PayloadAction<IRole[]>) => {
            state.data = action.payload;
        },
    },
});

export const { successLoadData, setIsLoading, setError } = RolesSlice.actions;

export default RolesSlice.reducer;
