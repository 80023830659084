import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { ActivityActionTypes } from '@models/actions/ActivityActionTypes';
import { IActivityAction } from '@models/actions/IBaseAction';
import { IPartsSign } from '@models/attaches/IAttachViewSignModel';
import { ApproveWithSignExecutor } from '@utils/actions/activityExecutors/ApproveWithSignExecutor';
import { RejectWithSignExecutor } from '@utils/actions/activityExecutors/RejectWithSignExecutor';
import { ReworkWithSignExecutor } from '@utils/actions/activityExecutors/ReworkWithSignExecutor';
import { TakeToWorkExecutor } from '@utils/actions/activityExecutors/TakeToWorkExecutor';
import { CompleteExecutor } from '@utils/actions/activityExecutors/CompleteExecutor';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { ActivityLocationTypes } from './ActivityLocationTypes';
import { BaseActionsService } from './BaseActionsService';
import { IActivityExecutionService } from './IActivityExecutionService';
import { IForms } from '@models/Forms/IForms';
import { IAbookOptions } from '@models/Forms/IAbookOptions';
import { IFormData } from '@models/IFormData';
import {
    IRequestAdditionalApprovalModel,
    IRequestAdditionalApprovalSend,
} from '@models/document/activityExecutors/IRequestAdditionalApprovalModel';
import { RequestAddApprovalExecutor } from '@utils/actions/activityExecutors/RequestAddApprovalExecutor';
import { ActivityBatchEditingExecutor } from '@utils/actions/activityExecutors/ActivityBatchEditingExecutor';
import { ISelectedFiles } from '@/components/atoms/AttachFilesBlock/AttachFilesBlock';
import { ChangePerformerExecutor } from '@utils/actions/activityExecutors/ChangePerformerExecutor';
import { RollbackPerformerExecutor } from '@utils/actions/activityExecutors/RollbackPerformerExecutor';
import { DelegateExecutor } from '@utils/actions/activityExecutors/DelegateExecutor';
import { RollbackDelegateExecutor } from '@utils/actions/activityExecutors/RollbackDelegateExecutor';
import { ResolveExceptionExecutor } from '@utils/actions/activityExecutors/ResolveExceptionExecutor';
import { IResolveExceptionModel } from '@models/document/activityExecutors/IResolveExceptionModel';
import { IActionResultModel } from '@models/document/actionsExecution/IActionResultModel';
import { IDocumentResult } from '@models/document/IDocumentResult';
import { ApproveExecutor } from '@utils/actions/activityExecutors/ApproveExecutor';
import { RejectExecutor } from '@utils/actions/activityExecutors/RejectExecutor';
import { ReworkExecutor } from '@utils/actions/activityExecutors/ReworkExecutor';
import { IActivityActionModel } from '@models/document/activityExecutors/IActivityActionModel';

export class ActivityActionsService extends BaseActionsService implements IActivityExecutionService {
    public allExecutors: { [actType: string]: () => IActionExecutor } = {
        [ActivityActionTypes[ActivityActionTypes.TakeToWork]]: () => new TakeToWorkExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ApproveWithSign]]: () => new ApproveWithSignExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ReworkWithSign]]: () => new ReworkWithSignExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RejectWithSign]]: () => new RejectWithSignExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Approve]]: () => new ApproveExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Rework]]: () => new ReworkExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Reject]]: () => new RejectExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Complete]]: () => new CompleteExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RequestAdditionalApproval]]: () => new RequestAddApprovalExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ActivityBatchEditing]]: () => new ActivityBatchEditingExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ChangePerformer]]: () => new ChangePerformerExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RollbackPerformer]]: () => new RollbackPerformerExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Delegate]]: () => new DelegateExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RollbackDelegate]]: () => new RollbackDelegateExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ResolveException]]: () => new ResolveExceptionExecutor(),
    };
    private _location?: ActivityLocationTypes;

    constructor(location?: ActivityLocationTypes) {
        super();
        this._location = location;
    }

    //Получает все действия для активити
    async fetchActions(actId: string): Promise<AxiosResponse<IActivityAction[]>> {
        return $api.get<IActivityAction[]>(`tasks/getActionActivities/${actId}?location=${this._location}`);
    }

    //Получает все действия для группы активити
    async fetchGroupActions(actIds: string): Promise<AxiosResponse<IActivityAction[]>> {
        return $api.get<IActivityAction[]>(`tasks/getGroupActionActivities/${actIds}?location=${this._location}`);
    }

    getRequestAdditionalApprovalModel(
        actionKey: string,
        activityId: string,
    ): Promise<AxiosResponse<IRequestAdditionalApprovalModel>> {
        return $api.get<IRequestAdditionalApprovalModel>(
            `activityActions/requestAdditionalApproval/${activityId}/${actionKey}`,
        );
    }

    sendRequestAdditionalApproval(
        actionKey: string,
        activityId: string,
        data: IRequestAdditionalApprovalSend,
    ): Promise<AxiosResponse> {
        return $api.post(`activityActions/requestAdditionalApproval/${activityId}/${actionKey}`, data);
    }

    takeToWork(actionKey: string, actId: string, comment?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('comment', comment as string);
        return $api.post(`activityActions/takeToWork/${actId}/${actionKey}`, formData);
    }

    // approveWithSign({ actId, signs, comment }: { actId: string; signs: IPartsSign[]; comment?: string; }): Promise<AxiosResponse> {
    //     let data = {
    //         comment: comment,
    //         signs: signs
    //     };
    //     return $api.post(`activityActions/approveWithSign/${actId}`, data);
    // }

    getActivityActionModel(actionKey: string, activityId: string): Promise<AxiosResponse<IActivityActionModel>> {
        return $api.get<IActivityActionModel>(`activityActions/activityAction/${activityId}/${actionKey}`);
    }

    //согласовать, согласовать с замечаниями или отклонить
    activityActionWithSign(
        actionKey: string,
        actId: string,
        signs: IPartsSign[],
        actFinishType: ActFinishType,
        comment?: string,
    ): Promise<AxiosResponse<IActionResultModel>> {
        let data = {
            comment: comment,
            signs: signs,
            actFinishType: actFinishType,
        };
        return $api.post(`activityActions/activityActionWithSign/${actId}/${actionKey}`, data);
    }

    activityAction(
        actionKey: string,
        actId: string,
        actFinishType: ActFinishType,
        comment?: string,
    ): Promise<AxiosResponse<IActionResultModel>> {
        let data = {
            comment: comment,
            actFinishType: actFinishType,
        };
        return $api.post(`activityActions/activityAction/${actId}/${actionKey}`, data);
    }

    // Сохранить набор полей в документах
    async saveBatchEditingData(
        documentIds: string,
        actionKey: string,
        activityIds: string,
        data: IFormData,
        files?: ISelectedFiles,
    ): Promise<AxiosResponse<IDocumentResult>> {
        const formData = new FormData();
        formData.append('documentIds', documentIds);
        formData.append('actionKey', actionKey);
        formData.append('activityIds', activityIds);
        formData.append('data', JSON.stringify(data));

        if (files) {
            files.attachProperties.map((prop) => formData.append('attachProps', JSON.stringify(prop)));
            files.files.map((f) => formData.append('attachFiles', f));
        }

        return $api.post(`/activityActions/saveBatchEditingData`, formData);
    }

    activityComplete(actionKey: string, actId: string, comment?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('comment', comment ?? '');

        return $api.post(`activityActions/activityComplete/${actionKey}/${actId}`, formData);
    }

    // Возвращает модель для формы
    async getFormBuilderScheme(actionKey: string): Promise<AxiosResponse<IForms>> {
        return $api.get<IForms>(`/activityActions/getFormBuilderScheme/${actionKey}`);
    }

    async getChangePerformerModel(actionKey: string, activityId: string): Promise<AxiosResponse<IAbookOptions>> {
        return $api.get<IAbookOptions>(`activityActions/changePerformer/${activityId}/${actionKey}`);
    }

    async saveChangePerformer(actionKey: string, activityId: string, performerKey: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('performerKey', performerKey);
        return $api.post(`activityActions/changePerformer/${activityId}/${actionKey}`, formData);
    }

    async saveRollbackPerformer(actionKey: string, activityId: string): Promise<AxiosResponse> {
        return $api.post(`activityActions/rollbackPerformer/${activityId}/${actionKey}`);
    }

    async getDelegateModel(actionKey: string, activityId: string): Promise<AxiosResponse<IAbookOptions>> {
        return $api.get<IAbookOptions>(`activityActions/delegate/${activityId}/${actionKey}`);
    }

    async saveDelegate(
        actionKey: string,
        activityId: string,
        performerKey: string,
        comment?: string,
    ): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('performerKey', performerKey);
        formData.append('comment', comment ?? '');
        return $api.post(`activityActions/delegate/${activityId}/${actionKey}`, formData);
    }

    async saveRollbackDelegate(actionKey: string, activityId: string, comment?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('comment', comment ?? '');
        return $api.post(`activityActions/rollbackDelegate/${activityId}/${actionKey}`, formData);
    }

    async getResolveExceptionModel(
        actionKey: string,
        activityId: string,
    ): Promise<AxiosResponse<IResolveExceptionModel>> {
        return $api.get<IResolveExceptionModel>(`activityActions/resolveException/${activityId}/${actionKey}`);
    }

    async saveResolveException(actionKey: string, activityId: string, templateId: string): Promise<AxiosResponse> {
        return $api.post(`activityActions/resolveException/${activityId}/${actionKey}/${templateId}`);
    }
}

export enum ActFinishType {
    //Согласовать
    Approve = 0,
    //Согласовать с замечаниями
    Rework = 1,
    //Отклонить
    Refuse = 2,
}
