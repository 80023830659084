import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReportData } from '@models/reports/IReportData';
import { IReportScheme } from '@models/reports/IReportScheme';
import { IReportFormats } from '@models/reports/IReportFormats';

export interface IReportState {
    isLoading: boolean;
    scheme?: IReportScheme;
    data?: IReportData;
    formats?: IReportFormats;
}

const initialState: IReportState = {
    isLoading: true,
    scheme: undefined,
    data: undefined,
};

export const ReportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },

        successLoadScheme: (state, action: PayloadAction<IReportScheme>) => {
            state.scheme = action.payload;
        },
        successLoadData: (state, action: PayloadAction<IReportData>) => {
            state.data = action.payload;
        },
        successLoadTypes: (state, action: PayloadAction<IReportFormats>) => {
            state.formats = action.payload;
        },
        reset: (state) => {
            state.scheme = undefined;
            state.data = undefined;
            state.formats = undefined;
        },
    },
});

export const { setIsLoading, successLoadScheme, successLoadData, successLoadTypes } = ReportSlice.actions;

export default ReportSlice.reducer;
