import React, { useState } from 'react';
import Chip from '@atoms/Chip';
import Preloader from '@atoms/Preloader';
import { classnames } from '@utils/classnames';
import { IChipProps } from '@atoms/Chip/Chip';

import './AbookpickerItem.scss';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';

export interface IAbookpickerItemProps extends Omit<IChipProps, 'children' | 'onRemove'> {
    /** Список адрес бук элементов */
    item?: IAddressBookData;
    /** Показывать иконку удаления
     * @default true
     */
    showRemoveIcon?: boolean;
    /** Функция удаления элемента */
    onRemove?: (item: IAddressBookData) => void;
    /** Ксласс */
    className?: string;
}

const AbookpickerItem = ({ item, showRemoveIcon = true, onRemove, className, ...props }: IAbookpickerItemProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    if (!item) {
        return null;
    }

    return (
        <div className={classnames('rf-abookpickeritem', className)}>
            {isLoading && (
                <div className="rf-abookpickeritem__loader">
                    <Preloader size="s" />
                </div>
            )}

            <Chip
                size="s"
                type="outline"
                maxLength={30}
                tooltipBackground={'white'}
                onRemove={showRemoveIcon ? () => onRemove?.(item) : undefined}
                iconPosition="right"
                {...props}
            >
                {item.fullName}
            </Chip>
        </div>
    );
};

export default AbookpickerItem;
