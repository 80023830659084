import React, { useEffect, useState } from 'react';

import './DocumentFormDetails.scss';
import FormBuilder from '@molecules/formbuilder/FormBuilder';
import { IFieldElem } from '@models/IFormData';
import { Controller, useForm } from 'react-hook-form';
import { IDocumentData } from '@models/document/IDocumentData';
import { IDocumentScheme } from '@models/document/IDocumentScheme';
import { DocumentService } from '@services/DocumentService';
import { convertListToMap } from '@utils/documentUtils';
import { FormProviderControl, HookFormProvider } from '@controls/index';
import Preloader from '@atoms/Preloader';
import { IDetailsForm } from '@models/response/IGridDescriptionResponse';

export interface IDocumentFormDetailsProps {
    docId: string;
    detailsForm: IDetailsForm;
}

const DocumentFormDetails: React.FC<IDocumentFormDetailsProps> = (props: IDocumentFormDetailsProps) => {
    const methods = useForm<IDocumentData>({
        mode: 'onBlur', // "onChange"
    });

    const [scheme, setScheme] = useState<IDocumentScheme>();
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();

    useEffect(() => {
        DocumentService.getScheme(props.docId).then((value) => {
            setScheme(value.data);
        });
        DocumentService.getData(props.docId).then((value) => {
            let fields = convertListToMap(value.data.fields);
            setFieldsData(fields);
        });
    }, []);

    return (
        <div className="document-form-details">
            {!scheme || !fieldsData ? (
                <div className="busy-loader">
                    <Preloader size="m" />
                </div>
            ) : (
                <FormProviderControl formMethods={methods} onSubmit={() => {}}>
                    <HookFormProvider controller={Controller}>
                        <FormBuilder
                            fields={fieldsData!}
                            setError={(errors?) => {}}
                            rows={props.detailsForm.form.view}
                            isEdit={false}
                            isNew={false}
                            formMethods={methods}
                            includedFields={[]}
                            docId={props.docId}
                            uniqueKey={props.docId}
                            docScheme={scheme}
                        />
                    </HookFormProvider>
                </FormProviderControl>
            )}
        </div>
    );
};

export default DocumentFormDetails;
