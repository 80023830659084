import React from 'react';
import './DocumentTabLinks.scss';

import { TableTab } from '@models/document/IDocumentScheme';
import DocumentTabLinksTable from './DocumentTabLinksTable';
import { IFieldElem } from '@models/IFormData';
import { UseFormReturn } from 'react-hook-form';
import { IDocumentData } from '@models/document/IDocumentData';

export interface IDocumentTabLinksProps {
    /** ИД документа */
    docId: string;
    /** Таблицы */
    tables: TableTab[];

    formMethods: UseFormReturn<IDocumentData>;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
}

const DocumentTabLinks = ({ docId, tables, formMethods, isEdit, isNew, fields }: IDocumentTabLinksProps) => {
    return (
        <div className="links-tab">
            {tables.map((element) => {
                return (
                    <DocumentTabLinksTable
                        key={element.key}
                        linkTable={element}
                        formMethods={formMethods}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        docId={docId}
                    />
                );
            })}
        </div>
    );
};

export default DocumentTabLinks;
