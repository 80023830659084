import { AuthActionCreators } from './actionCreators/authCreators';
import { SearchActionCreators } from './actionCreators/searchCreators';
import { DocumentActionCreators } from './actionCreators/documentCreators';
import { WidgetsActionCreators } from './actionCreators/widgetsCreators';
import { ReportActionCreators } from './actionCreators/reportCreators';
import { MainLayoutActionCreators } from './actionCreators/mainLayoutCreators';
import { DictionaryActionCreators } from './actionCreators/dictionaryCreators';
import { NotificationSettingsCreator } from './actionCreators/notificationSettingsCreator';
import { RolesActionCreators } from './actionCreators/rolesCreators';
import { UserAvatarActionCreators } from '@/store/actionCreators/userAvatarCreators';
import { AppDataActionCreators } from '@/store/actionCreators/appDataCreators';

export const allActionCreators = {
    ...AuthActionCreators,
    ...SearchActionCreators,
    ...DocumentActionCreators,
    ...WidgetsActionCreators,
    ...ReportActionCreators,
    ...MainLayoutActionCreators,
    ...DictionaryActionCreators,
    ...RolesActionCreators,
    ...NotificationSettingsCreator,
    ...UserAvatarActionCreators,
    ...AppDataActionCreators,
};
