import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { IForms } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { IDocumentData } from '@models/document/IDocumentData';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import ModalFormBuilder, { IModalFormBuilderProps } from '@molecules/ModalFormBuilder/ModalFormBuilder';
import { DocumentService } from '@services/DocumentService';

import './../actions.scss';
import { ActivityActionsService } from '@services/actions/ActivityActionsService';
import Preloader from '@atoms/Preloader';
import {
    checkAttachValidation,
    convertListToMap,
    getEmptyFormDataByScheme,
    getFormDataByScheme,
} from '@utils/documentUtils';

import { AttachActionsService } from '@services/actions/AttachActionsService';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { ISelectedFiles } from '@/components/atoms/AttachFilesBlock/AttachFilesBlock';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@atoms/Modal/Modal';
import { RemindersService } from '@services/RemindersService';
import { IMailRemindersBlockModel } from '@models/mailRemindersBlock/IMailRemindersBlockModel';
import { IMailRemindersOptions } from '@molecules/AddMailReminderBlock/AddMailReminderBlock';
import { IRequiredValidation } from '@/models/actions/IRequiredValidation';
import { sendErrorMsg } from '@/components/molecules/Errors';
import { ValidatorManager } from '@/utils/validatorManager';

export class ActivityBatchEditingExecutor implements IActionExecutor {
    private _model?: IActivityBatchEditingProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        let act = action as IActivityAction;
        this._model = {
            displayName: action.displayName,
            actionKey: action.key ?? '',
            docFlow: act?.flowName,
            actId: getActivityIdFromCompositeId(objId),
            docId: act.docId,
            attachFiles: action.options?.attachFiles == true,
            attachRequired: action.options?.attachRequired == true,
            attachRequiredValidation: action.options?.attachRequiredValidation as IRequiredValidation[],
            addMailReminders: action.options?.addMailReminders == true,
            virtualFields: action.options?.virtualFields as string[],
            showCurrentValues: action.options?.showCurrentValues ?? true,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            //showComment: action.options?.showComment,
            completeHandler: completeHandler,
        };
        //}
    };

    visualElement = () => {
        return this._model ? <ActivityBatchEditingModal {...this._model} /> : <></>;
    };
}

interface IActivityBatchEditingProps {
    displayName: string;
    actId: string;
    actionKey: string;
    docFlow?: string;
    docId: string;
    attachFiles: boolean;
    attachRequired: boolean;
    attachRequiredValidation?: IRequiredValidation[];
    addMailReminders: boolean;
    virtualFields?: string[];
    showCurrentValues: boolean;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    //    showComment: boolean;
    completeHandler?: (isSucceed: boolean) => void;
}

const ActivityBatchEditingModal: React.FC<IActivityBatchEditingProps> = (props: IActivityBatchEditingProps) => {
    //const [comment, setComment] = useState<string>();
    const [errorText, setErrorText] = useState<string>();
    const [warningText, setWarningText] = useState<string>();
    const [showOkButton, setShowOkButton] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>();
    const [form, setForm] = useState<IForms>();
    //const  document  = useTypedSelector((state) => state.document);
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();
    const [attachesCreateModel, setAttachesCreateModel] = useState<IAttachesCreateModel>();
    const [mailRemindersBlockModel, setMailRemindersBlockModel] = useState<IMailRemindersBlockModel>();

    const methods = useForm<IDocumentData>({
        mode: 'onBlur', // "onChange"
    });
    const service = new ActivityActionsService();

    useEffect(() => {
        //получение модели для аттачей
        if (props.attachFiles) {
            let activityId = props.actId;
            let attachService = new AttachActionsService(props.docId, '-1');
            attachService.getAttachInfo(props.actionKey, activityId).then((res) => {
                setAttachesCreateModel(res.data);
            });
        }

        //получение модели для блока напоминаний
        if (props.addMailReminders) {
            RemindersService.getMailRemindersBlockModel(props.docId, props.actionKey, props.actId).then((res) => {
                setMailRemindersBlockModel(res.data);
            });
        }

        // Получение схемы
        service
            .getFormBuilderScheme(props.actionKey)
            .then((dto) => {
                let form = dto.data;
                setForm(form);

                // Заполнить пустые данные на основе схемы
                if (!props.showCurrentValues) {
                    let formData = getEmptyFormDataByScheme(form?.view?.rows?.row!);
                    let fields = convertListToMap(formData.fields);
                    setFieldsData(fields);
                    methods.reset(formData);
                }
            })
            .catch((error) => setErrorText(error));

        // Данные документа
        // Получение данных
        if (props.showCurrentValues) {
            DocumentService.getDataByAction(props.actionKey, props.docId, undefined, true)
                .then((result) => {
                    if (result.data) {
                        let f = result.data.fields;

                        //доп фаршируем данные виртуальными свойствами
                        if (props.virtualFields) {
                            props.virtualFields.forEach((x) => {
                                f.push({
                                    name: x,
                                    value: undefined,
                                });
                            });
                        }

                        let fields = convertListToMap(f);
                        setFieldsData(fields);
                        methods.reset(result.data);
                    }
                })
                .catch((error) => setErrorText(error));
        }
    }, []);

    const onSubmit = useCallback(
        async (data: FieldValues, files?: ISelectedFiles, mailReminderOptions?: IMailRemindersOptions) => {
            setLoading(true);

            // проверка на обязательность наличия аттачей
            if (
                props.attachFiles &&
                props.attachRequired &&
                (files?.files === undefined || files?.files.length === 0)
            ) {
                setWarningText('Необходимо приложить файл');
                setLoading(false);
                return;
            }
            if (props.attachRequiredValidation && props.attachRequiredValidation.length > 0) {
                let checkResult = checkAttachValidation(props.attachRequiredValidation, files);
                if (checkResult.length > 0) {
                    let text = checkResult.join('\n');
                    setWarningText(text);
                }
            }
            let errors: string[] = [];

            if (form?.view?.validators && form?.view?.validators != null && fieldsData) {
                let validatorManager = new ValidatorManager(fieldsData, methods);
                await validatorManager.validateAllAsync(form?.view.validators?.validator, errors);

                if (errors.length > 0) {
                    sendErrorMsg({
                        message: errors,
                    });
                    setLoading(false);
                    return;
                }
            }

            let val = getFormDataByScheme(form?.view?.rows?.row!, data);

            //тут сохранение
            // Сохранение данных
            //let serv = new DocumentExecutionService(props.docId);
            service
                .saveBatchEditingData(props.docId, props.actionKey, props.actId, val, files)
                .then((result) => {
                    if (result.data.warnings && result.data.warnings.length > 0) {
                        setWarningText(result.data.warnings.join(', '));
                        setShowOkButton(false);
                    } else {
                        props.completeHandler && props.completeHandler(true);
                    }
                })
                .catch((error) => setErrorText(error))
                .finally(() => {
                    setLoading(false);
                });

            if (mailReminderOptions && mailRemindersBlockModel) {
                let remIds =
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderUserIdsField)?.value ?? '';
                let remTargetDate = new Date(
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderTargetDateField)
                        ?.value as string,
                );
                let remText =
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderTextField)?.value ?? '';

                let dayOfWeek =
                    mailReminderOptions.periodical.dayOfWeek !== undefined
                        ? mailReminderOptions.periodical.dayOfWeek[0]?.value
                        : '';

                //Создание напоминаний
                RemindersService.createBlockMailReminders(
                    props.docId,
                    props.actionKey,
                    remText?.toString(),
                    remTargetDate.toISOString(),
                    remIds?.toString(),
                    mailReminderOptions.daysBefore,
                    mailReminderOptions.periodical.weekly === 1 ? 'true' : 'false',
                    mailReminderOptions.periodical.monthly === 1 ? 'true' : 'false',
                    dayOfWeek,
                );
            }
        },
        [form, fieldsData, methods],
    );

    const messageBoxProp: IModalFormBuilderProps = {
        header: props.displayName,
        showOkButton: showOkButton,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        isBusy: loading,
        onSubmit: onSubmit,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        warningText: warningText,
        formMethods: methods,
        fields: fieldsData!,
        rows: form?.view,
        createAttachModel: attachesCreateModel,
        mailRemindersBlockModel: mailRemindersBlockModel,
        showContent: showOkButton,
        docId: props.docId,
    };

    return fieldsData ? <ModalFormBuilder {...messageBoxProp}></ModalFormBuilder> : <Preloader size="l" />;
};
