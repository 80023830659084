import React, { ReactNode } from 'react';
import './Hint.scss';
import { VariantClassic } from '@/types';

import { MdCheckCircle, MdClose, MdError, MdInfo } from 'react-icons/md';
import { classnames } from '@utils/classnames';
import DOMPurify from 'dompurify';

export interface IHintProps {
    /** Основное сообщение */
    children?: ReactNode | string;
    /** Заголовок */
    title?: ReactNode | string;
    /** Текст кнопки */
    button?: ReactNode;
    /** Цвет сообщения */
    variant?: Exclude<VariantClassic, 'white'>;
    /** Сопоставление цвета с иконкой по умолчанию. */
    iconMapping?: Record<Exclude<VariantClassic, 'white'>, ReactNode>;
    /** Использовать собственную иконку. Если передать `false` то иконка скроется.
     * Тип string оставлен для совместимости. Тип string - deprecated
     * */
    icon?: ReactNode | string;
    /** Максимальная ширина */
    maxWidth?: string;
    /** CSS класс */
    className?: string;
    onClose?: () => void;
}

const ICON_MAPPING: Record<Exclude<VariantClassic, 'white'>, ReactNode> = {
    default: <MdInfo />,
    red: <MdError />,
    green: <MdCheckCircle />,
    blue: <MdInfo />,
    yellow: <MdInfo />,
};

// FIXME: Elements must have sufficient color contrast
const Hint: React.FC<IHintProps> = ({
    children,
    button,
    variant = 'default',
    iconMapping = ICON_MAPPING,
    icon,
    title,
    maxWidth = '648px',
    onClose,
    className,
}: IHintProps) => {
    return (
        <div style={{ maxWidth }} className={classnames(`rf-hint rf-hint--${variant}`, className)}>
            {icon !== false && (
                <div className="rf-hint__icon">
                    {icon === undefined || typeof icon === 'string' ? iconMapping[variant] : icon}
                </div>
            )}
            <div className="rf-hint__body">
                {title ? (
                    typeof title === 'string' ? (
                        <div
                            className="rf-hint__title"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title, { ADD_ATTR: ['target'] }) }}
                        />
                    ) : (
                        <div className="rf-hint__title">{title}</div>
                    )
                ) : null}
                {children && <div className="rf-hint__message"> {children} </div>}
                {button && <div className="rf-hint__button">{button}</div>}
            </div>
            {!!onClose && (
                <button className="rf-hint__close" onClick={onClose} aria-label="Закрыть">
                    <MdClose size="18" />
                </button>
            )}
        </div>
    );
};

export default Hint;
