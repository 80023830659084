import React, { FC, ReactNode } from 'react';
import { Modifier, Popper, PopperProps } from 'react-popper';

import './Tooltip.scss';
import { createPortal } from 'react-dom';
import { Placement } from '@popperjs/core';
import { Options } from '@popperjs/core/lib/modifiers/offset';

export interface ITooltipContentProps extends Omit<PopperProps<Modifier<any>>, 'children' | 'modifiers' | 'placement'> {
    /** Содержимое tooltip */
    children: ReactNode | ReactNode[];
    /** Позиция tooltip */
    position?: Placement;
    /** Отключить показ самого тултипа
     * @default true
     */
    hidePadding?: boolean;
    /** Дополнительный класс */
    className?: string;
    /**
     * Цвет тултипа
     * @default default
     */
    background?: 'black' | 'white';
    /** Расстояние по оси X и Y
     * @default [0, 8]
     * */
    offset?: Options['offset'];
}

const TooltipContent: FC<ITooltipContentProps> = ({
    children,
    position = 'top',
    className = '',
    background = 'white',
    offset,
    hidePadding,
    ...props
}: ITooltipContentProps) =>
    createPortal(
        <Popper
            placement={position}
            modifiers={[
                {
                    name: 'offset',
                    options: { offset: offset || [0, 8] },
                },
                {
                    name: 'flip',
                    options: {
                        allowedAutoPlacements: ['right', 'left', 'top', 'bottom'],
                    },
                },
            ]}
            {...props}
        >
            {({ ref, style: dropdownStyle }) => (
                <div ref={ref} className="rf-tooltip__content-wrapper" style={{ ...dropdownStyle }}>
                    <div className={`rf-tooltip__content rf-tooltip__content--${background} ${className}`}>
                        <div className={`rf-tooltip__inner ${hidePadding ? 'rf-tooltip__inner--no-pudding' : ''}`}>
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </Popper>,
        document.body,
    );

export default TooltipContent;
