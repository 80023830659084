//Модель для создания документа из шаблона
export interface ICreateDocByTemplateModel {
    result: ResultDocumentActionType;
}

//список выбранных вложений для переноса
//тип решение при голосовании
export enum ResultDocumentActionType {
    Default = 0,
    Overwrite = 1,
    CreateVersion = 2,
    Download = 3,
}
