export enum DocActionTypes {
    /// <summary>
    /// создать документ, на основе другого уже существующего.
    /// </summary>
    RegisterOnBase,
    /// <summary>
    /// создать новый документ
    /// </summary>
    Register,
    /// <summary>
    /// Запустить задачу автоимпорта или автоэкспорта
    /// </summary>
    RunAutoTask,
    /// <summary>
    /// Редактирование документа
    /// </summary>
    DocumentsBatchEditing,
    /// <summary>
    /// Запустить маршрут
    /// </summary>
    RunRoute,
    /// <summary>
    /// Сохранить все аттачи документа
    /// </summary>
    DownloadAll,
    /// <summary>
    /// Удалить документ из БД
    /// </summary>
    DeleteDocument,
    /// <summary>
    /// Присоединить файлы
    /// </summary>
    AttachFiles,
    /// <summary>
    /// Голосование по вопросу (с ЭЦП)
    /// </summary>
    VoteQuestionWithSign,
    /// <summary>
    /// Голосование по вопросу (без ЭЦП)
    /// </summary>
    VoteQuestion,
    /// <summary>
    /// Формирование файла электронного письма
    /// </summary>
    MailTo,
    /// <summary>
    /// Загрузка документов из файла
    /// </summary>
    LoadFromFile,
    /// <summary>
    /// Открыть документ
    /// </summary>
    OpenDocument,
    /// <summary>
    /// Отправка данных на ЭТП
    /// </summary>
    SendToEtp,
    /// <summary>
    /// Копирование вложений документа
    /// </summary>
    CopyAttachments,
    /// <summary>
    /// Отправка электронной почты
    /// </summary>
    CustomEmail,
    /// <summary>
    /// Копирование документа
    /// </summary>
    CopyDocument,
    /// <summary>
    /// Выполнить переход
    /// </summary>
    Redirect,
    /// <summary>
    /// Сформировать печатную форму из шаблона
    /// </summary>
    CreateDocByTemplate,
    /// <summary>
    /// Сформировать печатную форму из шаблона (с диалоговым окном)
    /// </summary>
    CreateDocByTemplateDialog,
    /// <summary>
    /// Создать напоминание
    /// </summary>
    MailReminder,
    /// <summary>
    /// Скачать аттачи со связанных документов
    /// </summary>
    DownloadLinkedDocumentsAttachments,
    /// <summary>
    /// Создать связь с документом
    /// </summary>
    CreateDocumentLink,
    /// <summary>
    /// Массовое завершение активити на документе
    /// </summary>
    BatchActivitiesComplete,
    /// <summary>
    /// Отправка данных в ЕИС
    /// </summary>
    SendToEis,
    /// <summary>
    /// Открыть URL
    /// </summary>
    OpenUrl,
    /// <summary>
    /// Создать отчёт
    /// </summary>
    CreateReport,
    /// <summary>
    /// Создать шаблон документа, по существующему документу
    /// </summary>
    CreateDocumentTemplate,
    /// <summary>
    /// Умный бизнес
    /// </summary>
    SmartBusiness,
    /// <summary>
    /// Присоединить файлы из существующих доков
    /// </summary>
    AttachFilesFromExistingDocs,
    /// <summary>
    /// Интеграция с WIIC, запрос свободных рейсов
    /// </summary>
    WIICFreeDate,
    /// <summary>
    /// создать новый документ через модальное окно
    /// </summary>
    RegisterModal,
}
