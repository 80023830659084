import { DocumentService } from '@services/DocumentService';
import { IDocumentData } from '@models/document/IDocumentData';
import { DocumentSlice } from '@/store/reducers/DocumentReducer';
import { AppDispatch } from '@/store/index';
import { AxiosResponse } from 'axios';
import { IDocumentScheme } from '@models/document/IDocumentScheme';
import { DocumentActionsService } from '@services/actions/DocumentActionsService';
import { ProcessActionsService } from '@services/actions/ProcessActionsService';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { groupByToArray } from '@utils/helpers';
import { FavoritesService } from '@services/FavoritesService';
import { IAllRoutesActions } from '@models/actions/IAllRoutesActions';
import { IDocumentTitleBarActions } from '@models/actions/IDocumentActions';
import { RouteMapActionsService } from '@services/actions/RouteMapActionsService';
import { sendErrorMsg } from '@molecules/Errors';
import { IWarningAction } from '@/models/document/IDocumentResult';
import { DocumentBaseActionType } from '@/types/document.types';
import { ActionAccessibleCheckResult } from '@models/actions/ActionAccessibleCheckResult';

const fetchDocumentDataInternal = async (
    dispatch: AppDispatch,
    getDataFunc: () => Promise<AxiosResponse<IDocumentData>>,
) => {
    try {
        dispatch(DocumentSlice.actions.setIsLoading(true));
        //const response = await DocumentService.getData(id);
        const response = await getDataFunc();
        dispatch(DocumentSlice.actions.successLoadData(response.data));
    } catch (e) {
        let error = e as string[];
        sendErrorMsg({
            message: error,
        });
    } finally {
        dispatch(DocumentSlice.actions.setIsLoading(false));
    }
};

const fetchDocumentSchemeInternal = async (
    dispatch: AppDispatch,
    getSchemeFunc: () => Promise<AxiosResponse<IDocumentScheme>>,
) => {
    try {
        dispatch(DocumentSlice.actions.setIsEdit(false));
        dispatch(DocumentSlice.actions.setIsLoading(true));
        //const response = await DocumentService.getScheme(id);
        const response = await getSchemeFunc();
        dispatch(DocumentSlice.actions.successLoadScheme(response.data));
    } catch (e) {
        let error = e as string[];
        sendErrorMsg({
            message: error,
        });
    } finally {
        dispatch(DocumentSlice.actions.setIsLoading(false));
    }
};

export const DocumentActionCreators = {
    fetchDocumentScheme: (id: string, openDocumentGuid: string | undefined) => async (dispatch: AppDispatch) => {
        fetchDocumentSchemeInternal(dispatch, () => {
            return DocumentService.getScheme(id, undefined, openDocumentGuid);
        });
    },

    fetchDocumentSchemeByAction: (actId: string, baseDocId?: string) => async (dispatch: AppDispatch) => {
        fetchDocumentSchemeInternal(dispatch, () => {
            return DocumentService.getSchemeByAction(actId, baseDocId);
        });
    },

    fetchDocumentData: (id: string, openDocumentGuid: string | undefined) => async (dispatch: AppDispatch) => {
        await fetchDocumentDataInternal(dispatch, () => {
            return DocumentService.getData(id, openDocumentGuid);
        });
    },
    fetchDocumentDataByAction:
        (actId: string, docId?: string, templateId?: string) => async (dispatch: AppDispatch) => {
            await fetchDocumentDataInternal(dispatch, () => {
                return DocumentService.getDataByAction(actId, docId, templateId);
            });
        },
    fetchDocumentStatusBar: (id: string, openDocumentGuid: string | undefined) => async (dispatch: AppDispatch) => {
        try {
            dispatch(DocumentSlice.actions.setIsLoadingStatusBar(true));
            const response = await DocumentService.getStatusBar(id, openDocumentGuid);
            dispatch(DocumentSlice.actions.successLoadStatusBar(response.data));
        } catch (e) {
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
            dispatch(DocumentSlice.actions.setIsLoadingStatusBar(false));
        }
    },
    fetchDocumentTitleBarActions:
        (id: string, openDocumentGuid: string | undefined) => async (dispatch: AppDispatch) => {
            try {
                dispatch(DocumentSlice.actions.setIsLoadingTitleBarActions(true));
                const documentActionsService = new DocumentActionsService();
                const responseDocActions = await documentActionsService.fetchActionsDocumentTitleBar(
                    id,
                    openDocumentGuid,
                );
                const processActionsService = new ProcessActionsService();
                const responseAllActActions = await processActionsService.fetchAllRouteActionsTitleBar(
                    id,
                    openDocumentGuid,
                );

                let resultActions: IDocumentTitleBarActions = {};
                resultActions.documentActions = [];
                resultActions.groupedActivityActions = [];
                resultActions.processActions = [];

                //действия по документу
                resultActions.documentActions = responseDocActions.data;

                //действия по активити
                let activityActions: { activityKey: string; action: IActivityAction }[] = [];
                for (let activityKey in responseAllActActions.data.activityActions) {
                    let actions = responseAllActActions.data.activityActions[activityKey];
                    activityActions = activityActions.concat(
                        actions.map((action: IActivityAction, i: number) => ({
                            activityKey: activityKey,
                            action: action,
                        })),
                    );
                }

                resultActions.groupedActivityActions = groupByToArray(
                    activityActions.map((activityAction: { activityKey: string; action: IActivityAction }) => ({
                        ...activityAction.action,
                        activityKey: activityAction.activityKey,
                    })),
                    (activityAction: any) => activityAction.key,
                ) as { key: string; value: { activityKey: string; action: IActivityAction }[] }[];

                //действия по процессам
                for (let processKey in responseAllActActions.data.processActions) {
                    let actions = responseAllActActions.data.processActions[processKey];
                    resultActions.processActions = resultActions.processActions.concat(
                        actions.map((action: IBaseAction, i: number) => ({
                            processKey: processKey,
                            action: action,
                        })),
                    );
                }

                dispatch(DocumentSlice.actions.successLoadTitleBarActions(resultActions));
            } catch (e) {
                let error = e as string[];
                sendErrorMsg({
                    message: error,
                });
            } finally {
                dispatch(DocumentSlice.actions.setIsLoadingTitleBarActions(false));
            }
        },
    fetchDocumentMenuActions: (id: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(DocumentSlice.actions.setIsLoadingMenuDocumentActions(true));

            const documentActionsService = new DocumentActionsService();
            const responseDocActions = await documentActionsService.fetchActionsDocumentView(id);

            //действия по документу
            let resultActions = responseDocActions.data;

            dispatch(DocumentSlice.actions.successLoadMenuDocumentActions(resultActions));
        } catch (e) {
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
            dispatch(DocumentSlice.actions.setIsLoadingMenuDocumentActions(false));
        }
    },
    fetchDocumentMenuAllRoutesActions: (id: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(DocumentSlice.actions.setIsLoadingMenuAllRoutesActions(true));

            const processActionsService = new ProcessActionsService();
            const responseAllActActions = await processActionsService.fetchActionsDocumentView(id);

            let resultActions: IAllRoutesActions = {};

            //действия по активити
            resultActions.activityActions = responseAllActActions.data.activityActions;

            //действия по процессам
            resultActions.processActions = responseAllActActions.data.processActions;

            dispatch(DocumentSlice.actions.successLoadMenuAllRoutesActions(resultActions));
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
            dispatch(DocumentSlice.actions.setIsLoadingMenuAllRoutesActions(false));
        }
    },
    fetchDocumentIsFavorite: (id: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(DocumentSlice.actions.setIsFavorite(false));
            const response = await FavoritesService.checkIsFavoriteDocument(id);
            dispatch(DocumentSlice.actions.setIsFavorite(response.data));
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        }
    },
    fetchRouteMapAddActionConfig: (id: string) => async (dispatch: AppDispatch) => {
        try {
            const response = await RouteMapActionsService.fetchActionConfig(id, 'AddAction');
            dispatch(DocumentSlice.actions.setRouteMapAddActionConfig(response.data));
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        }
    },
    addDocumentToFavorites: (id: string) => async (dispatch: AppDispatch) => {
        try {
            await FavoritesService.addDocumentToFavorites(id);
            dispatch(DocumentSlice.actions.setIsFavorite(true));
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        }
    },
    removeDocumentFromFavorites: (id: string) => async (dispatch: AppDispatch) => {
        try {
            await FavoritesService.removeDocumentFromFavorites(id);
            dispatch(DocumentSlice.actions.setIsFavorite(false));
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        }
    },
    setDocumentIsLoading: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(DocumentSlice.actions.setIsLoading(flag));
    },
    setDocumentIsEdit: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(DocumentSlice.actions.setIsEdit(flag));
    },

    setDocumentIsNew: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(DocumentSlice.actions.setIsNew(flag));
    },
    resetDocument: () => async (dispatch: AppDispatch) => {
        dispatch(DocumentSlice.actions.reset());
    },
    resetDocumentActions: () => async (dispatch: AppDispatch) => {
        dispatch(DocumentSlice.actions.resetActions());
    },
    setDocumentIsHistoryView: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(DocumentSlice.actions.setIsHistoryView(flag));
    },
    setPartsVersionToAttach: (partsVesrions: string[]) => async (dispatch: AppDispatch) => {
        dispatch(DocumentSlice.actions.setPartsVersionToAttach(partsVesrions));
    },
    setActionWarning: (action?: IWarningAction) => async (dispatch: AppDispatch) => {
        dispatch(DocumentSlice.actions.setActionWarning(action));
    },
    saveDocumentData: (data: IDocumentData, templateId?: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(DocumentSlice.actions.setIsLoading(true));
            dispatch(DocumentSlice.actions.setActionWarning(undefined));
            const result = await DocumentService.saveData(data, templateId);

            if (result.data.warningAction) {
                dispatch(DocumentSlice.actions.setActionWarning(result.data.warningAction));
                return;
            }

            const response = await DocumentService.getData(result.data.id, undefined);
            dispatch(DocumentSlice.actions.successLoadData(response.data));
            if (result.data.warnings && result.data.warnings.length > 0) {
                sendErrorMsg({
                    message: result.data.warnings,
                    type: 'warning',
                });
            }
            dispatch(DocumentSlice.actions.setIsEdit(false));
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
            dispatch(DocumentSlice.actions.setIsLoading(false));
        }
    },
    checkDocumentBaseActionAccessibility:
        (
            docId: string,
            actionKey: DocumentBaseActionType,
            onAccessibleAction: (result: ActionAccessibleCheckResult) => void,
            onInaccessibleAction: (result: ActionAccessibleCheckResult) => void,
        ) =>
        async (dispatch: AppDispatch) => {
            const svc = new DocumentActionsService();
            svc.checkBaseActionAccessibility(docId, actionKey)
                .then(async (res) => {
                    const result = res.data;
                    if (result.isAccessibly) {
                        //успешная проверка Accessibility
                        onAccessibleAction(result);
                    } else {
                        onInaccessibleAction(result);
                    }
                })
                .catch((reason) => {
                    console.log('Ошибка проверки доступности действия: ' + reason);
                    sendErrorMsg({
                        title: 'Ошибка проверки доступности действия',
                        message: reason,
                        type: 'error',
                    });
                });
        },
};
