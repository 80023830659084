import React from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { FilesService } from '@services/FilesService';

export class DownloadExecutor implements IActionExecutor {
    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
    ) => {
        const filesService = new FilesService();
        filesService.downloadFile(`attachment/download/${parentId}/${objId}`);
    };
}
