import { FC, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Button from '@atoms/Button';
import Modal from '../../Modal';
import { IAddDictForm, IForms } from '@models/Forms/IForms';
import { DictionariesService } from '@services/DictionariesService';
import { sendErrorMsg } from '@molecules/Errors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IField, IFieldElem, IFormData } from '@/models/IFormData';
import { convertListToMap, getFieldKeysByScheme } from '@/utils/documentUtils';
import { FormProviderControl, HookFormProvider } from '@/components/controls';
import FormBuilder from '@/components/molecules/formbuilder/FormBuilder';

export interface IDictAddFormModalProp {
    settings: IAddDictForm;
    onClose: () => void;
    onSubmit?: (data: IFormData) => void;
}

const DictAddFormModal: FC<IDictAddFormModalProp> = (p: IDictAddFormModalProp) => {
    const [form, setForm] = useState<IForms>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const methods = useForm<IFormData>({
        mode: 'onBlur', // "onChange"
    });
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();
    let fieldsName: number[] = [];
    useEffect(() => {
        DictionariesService.fetchAddFormScheme(p.settings.customForm).then((response) => {
            var keys = getFieldKeysByScheme(response.data.form.view.rows.row);
            var uniqueKeys = keys.filter((v, i, a) => v !== undefined && a.indexOf(v) === i);
            let formFields: IField[] = [];
            uniqueKeys.forEach((key) => {
                formFields.push({ name: key, value: null } as IField);
            });
            let data = { fields: formFields } as IFormData;

            methods.reset(data);
            setFieldsData(convertListToMap(data.fields, ''));
            setForm(response.data.form);
        });
    }, [p.settings.customForm]);

    const onSubmitHandler: SubmitHandler<IFormData> = (data: IFormData) => {
        p.onSubmit && p.onSubmit(data);
    };

    const ModalFooter = ({ onClose }: { onClose: () => void }) => (
        <div className={'dictModal_footer'}>
            <div className={'left'}>
                <Button type="submit" size="s">
                    {'Сохранить'}
                </Button>

                <Button onClick={onClose} buttonType="light" size="s">
                    {'Отменить'}
                </Button>
            </div>
        </div>
    );

    return (
        <Modal size="xl" custom={true} className={'dictExternalSearchModal dx-dropdowneditor-overlay'}>
            <FormProviderControl
                id={'add-dict-form'}
                formMethods={methods}
                className="doc-form"
                onSubmit={onSubmitHandler}
            >
                <HookFormProvider controller={Controller}>
                    <div className="rf-modal__wrapper">
                        <div className="rf-modal__header">
                            <div className="box">
                                <div className="left">
                                    <Button
                                        startAdornment={<MdClose size="24" />}
                                        buttonType="text"
                                        size="xs"
                                        onClick={p.onClose}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="rf-modal__content">
                            <div className={'view-form-modal-body'}>
                                <div className="dict-form-content">
                                    <FormBuilder
                                        fields={fieldsData!}
                                        setError={(errors?: string[]) => {
                                            if (errors) {
                                                sendErrorMsg({
                                                    message: errors,
                                                });
                                            }
                                        }}
                                        rows={form?.view}
                                        isEdit={false}
                                        isNew={true}
                                        formMethods={methods}
                                        includedFields={fieldsName}
                                        uniqueKey={'add-dict-form'}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={'rf-modal__footer'}>{<ModalFooter onClose={p.onClose} />}</div>
                    </div>
                </HookFormProvider>
            </FormProviderControl>
        </Modal>
    );
};

export default DictAddFormModal;
