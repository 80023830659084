import React, { FC, ReactNode, useRef } from 'react';
import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { classnames } from '@utils/classnames';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import Preloader from '@atoms/Preloader';
import FormBuilder from '@molecules/formbuilder/FormBuilder';
import { IRows } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { IDocumentData } from '@models/document/IDocumentData';
import { FormProviderControl, HookFormProvider } from '@controls/index';
import './ModalFormBuilder.scss';
import Hint from '@atoms/Hint';
import { ModalSize } from '@atoms/Modal/Modal';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import {
    AttachFilesBlock,
    IAttachFilesBlockRefActions,
    ISelectedFiles,
} from '../../atoms/AttachFilesBlock/AttachFilesBlock';
import { v4 as uuidv4 } from 'uuid';
import { AddMailReminderBlock, IMailRemindersOptions } from '../AddMailReminderBlock/AddMailReminderBlock';
import { IMailRemindersBlockModel } from '@models/mailRemindersBlock/IMailRemindersBlockModel';
import { sendErrorMsg } from '../Errors';

export interface IModalFormBuilderProps {
    header: string;
    message?: string;
    size?: ModalSize;
    showOkButton: boolean;
    okButtonText?: string;
    showCancelButton: boolean;
    cancelButtonText?: string;
    onSubmit?: (data: FieldValues, files?: ISelectedFiles, mailReminderOptions?: IMailRemindersOptions) => void;
    cancelClick?: () => void;
    isBusy?: boolean;
    errorText?: string;
    successText?: string;
    warningText?: string;
    rows?: IRows;
    formMethods: UseFormReturn<IDocumentData>;
    fields: Record<string, IFieldElem>;
    children?: ReactNode;
    createAttachModel?: IAttachesCreateModel;
    mailRemindersBlockModel?: IMailRemindersBlockModel;
    showContent?: boolean;
    docId?: string;
    isEdit?: boolean;
    isNew?: boolean;
}

const ModalFormBuilder: FC<IModalFormBuilderProps> = ({
    size = 'm',
    showContent = true,
    okButtonText = 'ОК',
    cancelButtonText = 'Отмена',
    isEdit = false,
    isNew = true,
    ...props
}: IModalFormBuilderProps) => {
    const childFilesBlock = useRef<IAttachFilesBlockRefActions | null>(null);
    const childAddMailRemindersBlock = useRef();

    let fieldsName: number[] = [];
    let idForm = uuidv4();

    const onSubmit = (form: any) => {
        var fields = props.formMethods.control._formValues.fields as FieldValues;
        let filesToSend: ISelectedFiles = { attachProperties: [], files: [] };
        if (props.createAttachModel && !childFilesBlock?.current?.isValid()) {
            return;
        }
        if (childFilesBlock?.current) {
            let f = childFilesBlock?.current.getData();
            if (f) filesToSend = f;
        }

        let mrOptions = props.formMethods.control._formValues.mailReminders;

        props.onSubmit && props.onSubmit(fields, filesToSend, mrOptions);
    };

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={size}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>
                            <span>{props.header}</span>
                        </div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props?.cancelClick) props?.cancelClick();
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {props.isBusy ? (
                <Preloader size="m" />
            ) : (
                <div>
                    {props.errorText && (
                        <Hint icon="info" title={`Ошибка: ${props.errorText}`} variant="red" maxWidth="100%" />
                    )}
                    {props.warningText && (
                        <Hint icon="info" title={`Внимание: ${props.warningText}`} variant="yellow" maxWidth="100%" />
                    )}
                    {props.successText && (
                        <Hint icon="info" title={`${props.successText}`} variant="green" maxWidth="100%" />
                    )}
                    {props.message && <div className="modal-dlg-text">{props.message}</div>}

                    <FormProviderControl
                        formMethods={props.formMethods}
                        className="base-form-provider doc-form"
                        onSubmit={onSubmit}
                        id={idForm}
                    >
                        <HookFormProvider controller={Controller}>
                            {showContent && (
                                <>
                                    {props.children}
                                    <div className=" pw-container-row pw-content">
                                        <FormBuilder
                                            fields={props.fields}
                                            setError={(errors?: string[]) => {
                                                if (errors) {
                                                    sendErrorMsg({
                                                        message: errors,
                                                    });
                                                }
                                            }}
                                            rows={props.rows}
                                            isEdit={isEdit}
                                            isNew={isNew}
                                            formMethods={props.formMethods}
                                            includedFields={fieldsName}
                                            docId={props.docId ? props.docId : ''}
                                            uniqueKey={props.docId ? props.docId : ''}
                                        />
                                    </div>

                                    {props.createAttachModel && (
                                        <AttachFilesBlock ref={childFilesBlock} model={props.createAttachModel} />
                                    )}

                                    {props.mailRemindersBlockModel && (
                                        <AddMailReminderBlock
                                            ref={childAddMailRemindersBlock}
                                            model={props.mailRemindersBlockModel}
                                            formMethods={props.formMethods}
                                        />
                                    )}
                                </>
                            )}
                            <div className="modal-dlg-buttons">
                                <div className={'left'}>
                                    {props.showOkButton && (
                                        <Button size="s" aria-label={okButtonText} type="submit" form={idForm}>
                                            {okButtonText}
                                        </Button>
                                    )}

                                    {props.showCancelButton && (
                                        <Button
                                            buttonType="light"
                                            size="s"
                                            aria-label={cancelButtonText}
                                            onClick={() => {
                                                if (props?.cancelClick) props?.cancelClick();
                                            }}
                                        >
                                            {cancelButtonText}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </HookFormProvider>
                    </FormProviderControl>
                </div>
            )}
        </Modal>
    );
};

export default ModalFormBuilder;
