import './PlanningMenuActionsControl.scss';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionTypes } from '@models/actions/ActionTypes';
import { IBaseAction } from '@models/actions/IBaseAction';
import { ActionExecutorModel } from '@utils/actions/ActionExecutorModel';
import Button from '@atoms/Button';
import { MdAdd } from 'react-icons/md';

export type IPlanningMenuActionsControlProps = {
    actions?: IBaseAction[];
    completeHandler?: (isSucceed: boolean) => void;
};

export const PlanningMenuActionsControl = ({ ...props }: IPlanningMenuActionsControlProps) => {
    const [action, setAction] = useState<IBaseAction>();
    const navigate = useNavigate();
    const location = useLocation();

    const onClick = (act: IBaseAction) => {
        setAction(act);
    };

    const completeHandler = (success: boolean) => {
        setAction(undefined);
        props.completeHandler && props.completeHandler(success);
    };

    return (
        <div className="planning-menu-actions">
            {action && (
                <ActionExecutorModel
                    type={ActionTypes.Document}
                    objId={''}
                    action={action}
                    completeHandler={completeHandler}
                    navigateFunc={navigate}
                    location={location}
                />
            )}
            {// кнопки
            props.actions?.map((act: IBaseAction, i: number) => {
                let buttonStyle: React.CSSProperties = {};
                if (act.options?.buttonStyle && act.options?.buttonStyle?.backgroundColor) {
                    buttonStyle.backgroundColor = act.options.buttonStyle.backgroundColor;
                }
                if (act.options?.buttonStyle && act.options?.buttonStyle?.textColor) {
                    buttonStyle.color = act.options.buttonStyle.textColor;
                }

                return (
                    <Button
                        key={i}
                        className={'planning-menu-actions__button'}
                        title={act.displayName}
                        style={buttonStyle}
                        buttonType="secondary"
                        textColor="neutral"
                        size="s"
                        aria-label={act.displayName}
                        startAdornment={<MdAdd size="18" />}
                        onClick={() => {
                            onClick(act);
                        }}
                    >
                        {act.displayName}
                    </Button>
                );
            })}
        </div>
    );
};

export default PlanningMenuActionsControl;
