import React, { ReactNode } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, UseFormReturn } from 'react-hook-form';

export interface IFormProviderControlProps {
    children: ReactNode;
    className?: string;
    formMethods: UseFormReturn<any, any>;
    onSubmit: SubmitHandler<any>;
    onInvalid?: SubmitErrorHandler<any>;
    ignoreEnterSubmit?: boolean;
    id?: string;
    autoComplete?: string;
}

const FormProviderControl = ({
    children,
    className = '',
    formMethods,
    onSubmit,
    onInvalid,
    ignoreEnterSubmit = true,
    id,
    autoComplete,
}: IFormProviderControlProps) => {
    return (
        <FormProvider {...formMethods}>
            <form
                name={id}
                id={id}
                onSubmit={(e) => {
                    if (e.target === e.currentTarget) {
                        formMethods
                            .handleSubmit(
                                onSubmit,
                                onInvalid,
                            )(e)
                            .catch(() => {});
                    }
                }}
                autoComplete={autoComplete}
                className={`base-form-provider ${className}`}
                onKeyDown={(e) => {
                    if (ignoreEnterSubmit == true && e.key == 'Enter') {
                        e.preventDefault();
                    }
                }}
            >
                {children}
            </form>
        </FormProvider>
    );
};

export default FormProviderControl;
