import React, { useEffect, useRef, useState } from 'react';
import { ITreeOption } from '@/types';
import './TreeItem.scss';
import Tree from './Tree';
import Preloader from '../Preloader';
import { MdOutlineExpandLess } from 'react-icons/md';
import Checkbox from '../Checkbox';
import { classnames } from '@utils/classnames';
import { Link, useNavigate } from 'react-router-dom';

interface IFolderItemProps {
    id: string;
    item: ITreeOption;
    depth: number;
    open: boolean;
    parent?: ITreeOption;
    onChange?: (o: ITreeOption) => void;
    activeItem: ITreeOption | undefined;
    last?: boolean;
    /** Изменение состояния чекбокса. */
    onCheck?: (option: ITreeOption) => void;
    /** Клик по лейблу. */
    onClick?: (o: ITreeOption) => void;
    lazyLoad?: (o: ITreeOption) => Promise<ITreeOption[]>;
}

const FolderItem: React.FC<IFolderItemProps> = ({
    id,
    item,
    onChange,
    onCheck,
    onClick,
    lazyLoad,
    depth,
    open,
    activeItem,
    last = false,
}: IFolderItemProps) => {
    const itemRef = useRef<HTMLDivElement>(null);
    const folder = useRef<HTMLDivElement>(null);
    const [showFolder, toggleFolder] = useState<boolean>(open);
    const [childrenItems, setChildrenItems] = useState<ITreeOption[] | undefined>();
    const [loading, setLoading] = useState<boolean | undefined>(item.loading);

    useEffect(() => {
        toggleFolder(open);
    }, [open]);

    useEffect(() => {
        setChildrenItems(item.children);
    }, [item]);

    // ---------------------------------------------------------------------------------------------------------------------------------------

    const openClass =
        showFolder && childrenItems && childrenItems.length > 0 ? 'rf-tree__item--open' : 'rf-tree__item--close';
    const showFolderClass = showFolder ? '' : 'rf-tree__item-folder--hidden';
    const rotateIconClass =
        (item.hasChildren === undefined
            ? childrenItems && childrenItems.length === 0
            : item.hasChildren && !childrenItems?.length) || !showFolder
            ? 'rf-tree__item-label-icon--rotate'
            : '';
    const itemChildrenClass = childrenItems && childrenItems.length > 0 ? '' : 'rf-tree__item--no-children';
    const activeClass = activeItem?.value === item.value ? 'rf-tree__item--active' : '';
    const withIcon = item.icon != undefined ? 'with-icon' : '';
    const firstLevelClass = depth === 1 ? 'rf-tree__item--1' : '';
    const router = useNavigate();
    // ---------------------------------------------------------------------------------------------------------------------------------------

    const loadData = (item: ITreeOption) => {
        setLoading(true);
        lazyLoad &&
            lazyLoad(item)
                .then((data) => {
                    setChildrenItems(data);
                })
                .finally(() => {
                    setLoading(false);
                });
    };

    const openFolder = (e: React.MouseEvent) => {
        e.stopPropagation();

        onClick && onClick(item);

        if (item.url != undefined && childrenItems == undefined) {
            router(item.url);
            return;
        }

        if (
            item.hasChildren === undefined
                ? !childrenItems || childrenItems.length === 0
                : item.hasChildren && !childrenItems?.length
        ) {
            lazyLoad && loadData(item);
            onChange && onChange(item);
            toggleFolder(true);
            return;
        }

        toggleFolder((f: boolean) => !f);
    };

    const handleChange = (e: React.MouseEvent) => {
        e.stopPropagation();

        onClick && onClick(item);

        if (item.url != undefined && childrenItems == undefined) {
            //  router(item.url);
            return;
        }

        onChange && onChange(item);

        if (item.hasChildren === undefined ? childrenItems : item.hasChildren) {
            toggleFolder(true);
        }
    };

    // ---------------------------------------------------------------------------------------------------------------------------------------

    const onCheckboxClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    const onCheckboxChange = () => {
        if (onCheck) {
            onCheck(item);
        }
    };

    // ---------------------------------------------------------------------------------------------------------------------------------------

    return (
        <div
            className={`rf-tree__item ${openClass} ${itemChildrenClass} ${
                item == activeItem ? activeClass : ''
            } ${firstLevelClass}`}
            ref={itemRef}
        >
            <div
                className={`rf-tree__item-label rf-tree__item-label--${item.variant || 'default'} ${withIcon}`}
                onClick={handleChange}
            >
                <div title={item.label} className={'rf-tree__item-icon'}>
                    {(item.hasChildren === undefined ? childrenItems && childrenItems.length > 0 : item.hasChildren) ? (
                        <button type="button" className="rf-tree__arrow-icon-wrapper" onClick={openFolder}>
                            {!item.icon ? (
                                <MdOutlineExpandLess className={`rf-tree__item-label-icon ${rotateIconClass}`} />
                            ) : (
                                item.icon
                            )}
                        </button>
                    ) : (
                        item.icon != undefined && item.icon
                    )}
                </div>
                <label
                    onClick={openFolder}
                    className={classnames('rf-tree__item-label-text', 'rf-tree__item-label-text--clickable')}
                >
                    {!!onCheck && (
                        <div className="rf-tree__checkbox">
                            <Checkbox checked={item.checked} onChange={onCheckboxChange} onClick={onCheckboxClick} />
                        </div>
                    )}
                    {item.url != undefined && childrenItems == undefined ? (
                        <Link to={item.url}> {item.label}</Link>
                    ) : (
                        item.label
                    )}
                </label>
            </div>

            {loading && (
                <div className="rf-tree__item-preloader">
                    <Preloader size="s" />
                </div>
            )}

            {childrenItems && childrenItems.length > 0 && (
                <div className={`rf-tree__item-folder ${showFolderClass}`} ref={folder}>
                    <Tree
                        id={id}
                        list={childrenItems}
                        onChange={onChange}
                        onClick={onClick}
                        parent={item}
                        depth={depth}
                        open={open}
                        activeItem={activeItem}
                        onCheck={onCheck}
                    />
                </div>
            )}
        </div>
    );
};

export default FolderItem;
