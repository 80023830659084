import { AppDispatch } from '@/store/index';
import { ReportSlice } from '@/store/reducers/ReportReducer';
import { ReportsService } from '@services/ReportsService';
import { IReportData } from '@models/reports/IReportData';
import { FilesService } from '@services/FilesService';
import { sendErrorMsg } from '@molecules/Errors';

export const ReportActionCreators = {
    fetchReportScheme: (formName: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(ReportSlice.actions.setIsLoading(true));
            const response = await ReportsService.getScheme(formName);
            dispatch(ReportSlice.actions.successLoadScheme(response.data));
        } catch (e) {
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
            dispatch(ReportSlice.actions.setIsLoading(false));
        }
    },

    fetchReportData: (folder: string, name: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(ReportSlice.actions.setIsLoading(true));
            const response = await ReportsService.getData(folder, name);
            dispatch(ReportSlice.actions.successLoadData(response.data));
        } catch (e) {
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
            dispatch(ReportSlice.actions.setIsLoading(false));
        }
    },

    fetchReportFormats: (folder: string, name: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(ReportSlice.actions.setIsLoading(true));
            const response = await ReportsService.getFormats(folder, name);
            dispatch(ReportSlice.actions.successLoadTypes(response.data));
        } catch (e) {
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
            dispatch(ReportSlice.actions.setIsLoading(false));
        }
    },

    setReportIsLoading: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(ReportSlice.actions.setIsLoading(flag));
    },

    resetReport: () => async (dispatch: AppDispatch) => {
        dispatch(ReportSlice.actions.reset());
    },

    buildAndDownloadReport:
        (folder: string, name: string, format: string, data: IReportData) => async (dispatch: AppDispatch) => {
            try {
                dispatch(ReportSlice.actions.setIsLoading(true));
                await ReportsService.build(folder, name, format, data).then((response) => {
                    const filesService = new FilesService();
                    filesService.downloadFileByGuid(response.data.fileGuid, response.data.fileName);
                });
            } catch (e) {
                let error = e as string[];
                sendErrorMsg({
                    message: error,
                });
            } finally {
                dispatch(ReportSlice.actions.setIsLoading(false));
            }
        },
};
