import React, { FC, useEffect, useState } from 'react';
import './DocumentTabRoutesMap.scss';

import DevExpressDataGridDynamic, {
    IDxDataGridDynamicHandle,
} from '@atoms/DevExpress/DataGrid/DevExpressDataGridDynamic';
import Box from '@atoms/Box';
import { DocumentRoutesMapService } from '@services/DocumentRoutesMapServices';
import { DataGridStateService } from '@services/DataGridStateService';
import { FilesService } from '@services/FilesService';
import { Template } from 'devextreme-react/core/template';
import { classnames } from '@utils/classnames';
import Tooltip from '@atoms/Tooltip';
import { RouteMapActionsService } from '@services/actions/RouteMapActionsService';
import Button from '@atoms/Button';
import { Item } from 'devextreme-react/data-grid';
import { MdAdd } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import { ActionExecutorModel } from '@utils/actions/ActionExecutorModel';
import { ActionTypes } from '@models/actions/ActionTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useActions } from '@hooks/useActions';
import dxDataGrid from 'devextreme/ui/data_grid';
import { IDocumentRouteMap } from '@models/document/IDocumentRouteMap';
import ActionButton from '@atoms/DevExpress/DataGrid/Buttons/ActionButton';
import { IFieldElem } from '@models/IFormData';

export interface IDocumentTabRoutesMapProps {
    /** ИД документа */
    docId: string;
    routeMap?: IDocumentRouteMap;
    fields?: Record<string, IFieldElem>;
}

const DocumentTabRoutesMap: FC<IDocumentTabRoutesMapProps> = ({
    docId,
    routeMap,
    fields,
}: IDocumentTabRoutesMapProps) => {
    const serviceGrid = new DocumentRoutesMapService();
    const stateService = new DataGridStateService();
    const filesService = new FilesService();
    const actionsService = new RouteMapActionsService();

    const [action, setAction] = useState<IBaseAction>();
    const [isVisibleAction, setIsVisibleAction] = useState<boolean>(false);
    const { fetchRouteMapAddActionConfig } = useActions();
    const navigateFunc = useNavigate();
    const location = useLocation();

    const gridRef = React.useRef<IDxDataGridDynamicHandle>(null);

    useEffect(() => {
        if (!action) fetchRouteMapAddActionConfig(docId);
    }, [action]);

    useEffect(() => {
        setIsVisibleAction(routeMap?.addAction.isVisibleAction || false);
    }, [routeMap?.addAction.actionConfig]);

    const completeHandler = (success: boolean) => {
        setAction(undefined);
        if (success) gridRef.current?.refresh();
    };
    const addNodeItem: JSX.Element = (
        <Item location="after" visible={isVisibleAction} key="add_node">
            <Tooltip openDelay={100} background="black" position="top">
                <>
                    {action && (
                        <ActionExecutorModel
                            type={ActionTypes.RouteMap}
                            objId={docId}
                            action={action}
                            completeHandler={completeHandler}
                            navigateFunc={navigateFunc}
                            location={location}
                            fields={fields}
                        />
                    )}
                    <Button
                        buttonType="secondary"
                        textColor="neutral"
                        size="xs"
                        aria-label="Добавить этап"
                        onClick={() => onClick()}
                        startAdornment={<MdAdd size="24" />}
                    />
                </>
                Добавить этап
            </Tooltip>
        </Item>
    );

    const onClick = () => {
        if (routeMap?.addAction.actionConfig) setAction(routeMap.addAction.actionConfig);
    };

    const getActivityStatus = () => {
        return (
            <Template
                key="dxGridRowActivityStatusCellTemplate"
                name="dxGridRowActivityStatusCellTemplate"
                render={function (data: any) {
                    const { text } = data;
                    let tooltipText = text == 'finish' ? 'Этап завершён' : text == 'running' ? 'Этап выполняется' : '';
                    return (
                        <Tooltip openDelay={100} background="black" position="bottom">
                            {
                                <div className="status_cell">
                                    <div
                                        className={classnames(
                                            'status_box',
                                            text == 'finish' && 'status_box_finish',
                                            text == 'running' && 'status_box_running',
                                        )}
                                    ></div>
                                </div>
                            }
                            {tooltipText}
                        </Tooltip>
                    );
                }}
            />
        );
    };

    const getActivityActionsPreloaded = () => {
        return (
            <Template
                key="dxGridRowActivityActionsPreloadedCellTemplate"
                name="dxGridRowActivityActionsPreloadedCellTemplate"
                render={
                    //js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
                    function (data: any) {
                        var dataGrid = data['component'] as dxDataGrid;
                        var key = data['key'];

                        return (
                            <ActionButton
                                objId={key}
                                service={actionsService}
                                objData={data.data}
                                onModifyData={() => {
                                    dataGrid.refresh();
                                }}
                            />
                        );
                    }
                }
            />
        );
    };

    const templates: JSX.Element[] = [getActivityStatus(), getActivityActionsPreloaded()];

    return (
        <div className="routes-map-tab">
            <Box header="Карта маршрута">
                <div className="routes-map-table">
                    <DevExpressDataGridDynamic
                        ref={gridRef}
                        key={`grid_routes_map`}
                        stateService={stateService}
                        gridKey={docId}
                        service={serviceGrid}
                        filesService={filesService}
                        customTemplates={templates}
                        toolbarItems={[addNodeItem]}
                    />
                </div>
            </Box>
        </div>
    );
};

export default DocumentTabRoutesMap;
