import React, { ReactNode, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './Datepicker.scss';
import { DateFormat, IDateVariants } from './DatepickerCalendar/datepicker.types';
import { DropdownPosition } from '@/types';
import { formatDate, generateMask, getWeekDay, parseToFormat, stringToDateFormat } from '@utils/helpersDatePicker';
import { Manager, Reference } from 'react-popper';
import { MdClose, MdOutlineExpandMore } from 'react-icons/md';
import Input from '../Input';
import InputMask from 'react-input-mask';
import { classnames } from '@utils/classnames';
import Dropdown from '../Dropdown';
import DatepickerCalendar from './DatepickerCalendar';
import { ITooltipProps } from '../Tooltip/Tooltip';
import TrapFocus from '../TrapFocus';

export interface IDatePickerRefActions {
    reset: () => void;
}

export interface IDatepickerProps<T extends HTMLElement = HTMLDivElement> {
    /** Имя поля */
    name?: string;
    /** Текст Placeholder */
    placeholder?: string;
    /** Значение по умолчанию */
    defaultValue?: Date | string | number;
    /** Скрыть поле */
    disabled?: boolean;
    /** Только для просмотра */
    readOnly?: boolean;
    /** Минимальное значения даты */
    minDate?: Date | string | number;
    /** Максимальное значения даты */
    maxDate?: Date | string | number;
    /** Функция измекнения значения даты */
    onChange?: (value: IDateVariants, name?: string) => void;
    /** Диапазон
     * @default false
     */
    range?: boolean;
    /** Показывать день недели в инпуте
     * @default false
     */
    showDayOfWeek?: boolean;
    /** Локализация
     * @default ru
     */
    locale?: 'ru' | 'en';
    /** Положение выпадающего меню */
    position?: DropdownPosition;
    /** Формат даты */
    format?: DateFormat;
    /** Ограничения на дни недели 0 - 6 */
    disableWeekDays?: number[];
    /** Кастомная кнопка */
    children?: ReactNode | ReactNode[];
    /** Переводит инпут в невалидный статус */
    invalid?: boolean;
    /**
     * Добавляет инпуту белый фон
     * @default true
     */
    filled?: boolean;
    /** Цвет tooltip */
    tooltipBackground?: ITooltipProps['background'];
    /** Сыылка на контейнер портала */
    containerRef?: RefObject<T>;
    /** Реф для пробрасывания действий по кастомным кнопкам */
    controlRef?: RefObject<IDatePickerRefActions | null>;
    /**
     *  Добавлять фокус при выборе дат
     * @default false
     *  */
    isFocusBorder?: boolean;
    /**
     *  Отображение иконки в конце строки
     * @default true
     *  */
    showTailIcon?: boolean;
    /**
     *  Css класс
     * @default ""
     *  */
    className?: string;
    /**
     *  Отображение иконки сброса значения
     * @default true
     *  */
    showClearIcon?: boolean;
}

// FIXME: Добавить управление с клавиатуры
const Datepicker: React.FC<IDatepickerProps> = ({
    name = 'datepicker',
    locale = 'ru',
    placeholder = locale === 'ru' ? 'Выберите дату' : 'Select date',
    defaultValue,
    minDate,
    maxDate,
    invalid = false,
    filled = true,
    disabled = false,
    readOnly = false,
    onChange,
    range = false,
    showDayOfWeek = false,
    position = 'bottom-start',
    format = 'dd.mm.yyyy',
    disableWeekDays = [],
    children,
    tooltipBackground = 'white',
    containerRef,
    controlRef,
    isFocusBorder = false,
    showTailIcon = true,
    className,
    showClearIcon = true,
}: IDatepickerProps) => {
    const separator = format[2];

    const [dayOfWeek, setDayOfWeek] = useState<string[]>([]);

    const [minDateVal, setMinDate] = useState<Date | undefined>(undefined);
    const [maxDateVal, setMaxDate] = useState<Date | undefined>(undefined);

    useEffect(() => {
        setMinDate(minDate ? parseToFormat(format, minDate).date : undefined);
    }, [minDate]);

    useEffect(() => {
        setMaxDate(maxDate ? parseToFormat(format, maxDate).date : undefined);
    }, [maxDate]);

    useImperativeHandle(controlRef, () => ({
        reset: () => clearDateRangeHandler(),
    }));

    // -------------------------------------------------------------------------------------------------------------------

    const datepickerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);

    const [showCalendar, toggleCalendar] = useState<boolean>(false);

    const onClose = () => toggleCalendar(false);

    // -------------------------------------------------------------------------------------------------------------------

    const [inputValue, setInputValue] = useState<string>('');

    const validate = (date: string): string => {
        let result = date;

        if (range) {
            let [from, to] = date.split(' - ');
            let fromD = 0;
            let toD = 0;

            if (from) {
                from = from.slice(0, 10);
            }

            if (to) {
                to = to.slice(0, 10);
            }

            if (from && !from.includes('_')) {
                fromD = stringToDateFormat(from, format).getTime();

                // if (minDateVal && fromD < minDateVal.getTime()) {
                //     fromD = minDateVal.getTime();
                // }

                // if (maxDateVal && fromD > maxDateVal.getTime()) {
                //     fromD = minDateVal ? minDateVal.getTime() : maxDateVal.getTime();
                // }

                from = formatDate(fromD, format).date;
            }

            if (to && !to.includes('_')) {
                toD = stringToDateFormat(to, format).getTime();

                if (toD < fromD) {
                    toD = fromD + 24 * 3600 * 1000;
                }

                // if (maxDateVal && toD > maxDateVal.getTime()) {
                //     toD = maxDateVal.getTime();
                // }

                to = formatDate(toD, format).date;
            }

            if (from || to) {
                result = [from, to].join(' - ');
            }

            if (result === '__.__.____ - __.__.____') {
            }
        } else {
            // const d = stringToDate(date, format);
            // result = d;
            // if (date !== '' && minDateVal && d.getTime() < minDateVal.getTime()) {
            //     result = formatDate(minDateVal.getTime(), format).date;
            // }
            // if (maxDateVal && d.getTime() > maxDateVal.getTime()) {
            //     result = formatDate(maxDateVal.getTime(), format).date;
            // }
        }

        return result;
    };

    useEffect(() => {
        if (defaultValue == undefined || defaultValue == '') {
            setInputValue('');
            return;
        }

        let inputValue = parseToFormat(format, defaultValue).string;

        if (!inputValue.includes('_')) {
            inputValue = validate(parseToFormat(format, defaultValue).string);
        }

        setInputValue(inputValue);
    }, [defaultValue, minDateVal, maxDateVal]);

    // -------------------------------------------------------------------------------------------------------------------

    const getReturnValue = (value: string, range: boolean): IDateVariants => {
        if (range) {
            const [from, to] = value.split(' - ');
            const fromD = stringToDateFormat(from, format).getTime();
            const toD = stringToDateFormat(to, format).getTime();
            const fromUTCD = stringToDateFormat(from, format, true).getTime();
            const toUTCD = stringToDateFormat(to, format, true).getTime();

            return {
                value,
                date: {
                    from: new Date(fromD),
                    to: new Date(toD),
                    value: new Date(fromD),
                    utc: new Date(fromUTCD),
                },
                timestamp: {
                    from: fromD,
                    to: toD,
                    value: fromD,
                    utc: {
                        from: fromUTCD,
                        to: toUTCD,
                        value: fromUTCD,
                    },
                },
            };
        }

        const date = stringToDateFormat(value, format);
        const dateUTC = stringToDateFormat(value, format, true);

        return {
            date: {
                from: date,
                to: date,
                value: date,
                utc: dateUTC,
            },
            value,
            timestamp: {
                from: date.getTime(),
                to: date.getTime(),
                value: date.getTime(),
                utc: {
                    from: dateUTC.getTime(),
                    to: dateUTC.getTime(),
                    value: dateUTC.getTime(),
                },
            },
        };
    };

    const onDatepickerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let result = e.target.value;

        if (range || (result.length === 10 && !result.includes('_'))) {
            result = validate(result);
        }

        setInputValue(result);
        changeValue(result);
    };

    const setValue = (value: string) => {
        let result = validate(value);
        setInputValue(result);
        changeValue(result);
    };

    const changeValue = (val: string) => {
        if (!val.includes('_') && val !== '') {
            const result = getReturnValue(val, range);
            onChange && onChange(result, name);
            fireOnChange();
        } else {
            if (showDayOfWeek) {
                setDayOfWeek([]);
            }

            if (val == '__.__.____') {
                let r: IDateVariants = {
                    date: {
                        from: new Date(),
                        to: new Date(),
                        value: new Date(),
                        utc: new Date(),
                    },
                    timestamp: {
                        from: 0,
                        to: 0,
                        value: 0,
                        utc: {
                            from: 0,
                            to: 0,
                            value: 0,
                        },
                    },
                    value: '',
                };

                onChange && onChange(r, name);
                fireOnChange();
            } else if (val == '') {
                let r: IDateVariants = {
                    date: {
                        from: new Date(),
                        to: new Date(),
                        value: undefined,
                        utc: undefined,
                    },
                    timestamp: {
                        from: 0,
                        to: 0,
                        value: 0,
                        utc: {
                            from: 0,
                            to: 0,
                            value: 0,
                        },
                    },
                    value: '',
                };

                onChange && onChange(r, name);
                fireOnChange();
            }
        }
    };

    // useEffect(() => {
    //     changeValue();
    // }, [showDayOfWeek]);

    const fireOnChange = () => {
        setTimeout(() => {
            if (inputRef.current) {
                const input = inputRef.current.querySelector('input');

                if (input) {
                    let event;

                    if (typeof Event === 'function') {
                        event = new Event('change');
                    } else {
                        event = document.createEvent('Event');
                        event.initEvent('change', true, true);
                    }

                    input.dispatchEvent(event);
                }
            }
        }, 100);
    };

    // -------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (showDayOfWeek) {
            if (!range) {
                if (!inputValue.includes('_') && inputValue !== '') {
                    const result = getReturnValue(inputValue, range);
                    const dayFrom = result.date.value?.getDay();
                    if (dayFrom) setDayOfWeek([getWeekDay(dayFrom, locale)]);
                }
            } else {
                const [fromValue, toValue] = inputValue.split(' - ');

                if (fromValue && !fromValue.includes('_')) {
                    const from = getReturnValue(fromValue, false);
                    const dayFrom = from.date.from.getDay();
                    setDayOfWeek([getWeekDay(dayFrom, locale)]);
                }

                if (toValue && !toValue.includes('_')) {
                    const to = getReturnValue(toValue, false);
                    const dayTo = to.date.from.getDay();
                    setDayOfWeek([...dayOfWeek, getWeekDay(dayTo, locale)]);
                }
            }
        }
    }, [inputValue, showDayOfWeek, range]);

    // -------------------------------------------------------------------------------------------------------------------

    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key.toLowerCase() === 'enter' || e.charCode === 13) {
            e.stopPropagation();
            e.preventDefault();
        }
    };

    const clearDateRangeHandler = () => {
        setInputValue('');
        changeValue('');
    };

    const handleEscapePress = (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            e.stopPropagation();
            onClose();
        }
    };

    // -------------------------------------------------------------------------------------------------------------------

    const mask = generateMask(inputValue, format, range, showDayOfWeek, dayOfWeek);

    // -------------------------------------------------------------------------------------------------------------------

    const isCrossChevronPicker = inputValue.split('-').length === 2 && inputValue.split('-')[1].trim() !== '__.__.____';
    return (
        <Manager>
            <div
                className={classnames(
                    'rf-datepicker',
                    isFocusBorder && 'rf-datepicker__focus-border',
                    disabled && 'rf-datepicker--disabled',
                )}
                ref={datepickerRef}
            >
                <Reference>
                    {(referenceProps) => (
                        <div
                            {...referenceProps}
                            className={classnames('rf-datepicker__input-wrapper', {
                                'rf-datepicker__input-wrapper--disabled': disabled,
                                'rf-datepicker__input-wrapper--readonly': readOnly,
                                'rf-datepicker__input-wrapper--range': range,
                            })}
                            onClick={readOnly ? () => {} : () => toggleCalendar((prev) => !prev)}
                        >
                            {children || (
                                <InputMask
                                    mask={mask}
                                    name={name}
                                    placeholder={placeholder}
                                    value={inputValue}
                                    disabled={disabled}
                                    readOnly={readOnly}
                                    autoComplete="off"
                                    onKeyPress={onKeyPress}
                                    onChange={onDatepickerChange}
                                >
                                    <Input
                                        className={className}
                                        disabled={disabled}
                                        invalid={invalid}
                                        filled={filled}
                                        autoComplete="off"
                                        // startAdornment={
                                        //     <div
                                        //         onClick={(e) => e.stopPropagation()}
                                        //         className="rf-datepicker__calendar-button"
                                        //     >
                                        //         <MdOutlineExpandMore size="20" />
                                        //     </div>
                                        // }
                                        endAdornment={
                                            showTailIcon && (
                                                <div
                                                    className={classnames(
                                                        'rf-datepicker__calendar-chevron',
                                                        'rf-datepicker__calendar-cross',
                                                    )}
                                                >
                                                    <div className="rf-datepicker__icon-wrapper">
                                                        {showClearIcon && (
                                                            <MdClose
                                                                onClick={(e) => {
                                                                    clearDateRangeHandler();
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}
                                                            />
                                                        )}
                                                        <MdOutlineExpandMore />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    />
                                </InputMask>
                            )}
                        </div>
                    )}
                </Reference>

                <Dropdown
                    show={showCalendar}
                    toggleRef={datepickerRef}
                    onClose={onClose}
                    position={position}
                    strategy="absolute"
                    style={{
                        maxWidth: 'auto',
                        width: 'auto',
                    }}
                    containerRef={containerRef}
                >
                    {/* <TrapFocus open> */}
                    <div tabIndex={-1} className="rf-datepicker__date-picker-container" onKeyUp={handleEscapePress}>
                        <DatepickerCalendar
                            value={inputValue}
                            minDate={minDateVal}
                            maxDate={maxDateVal}
                            setInputValue={setValue}
                            range={range}
                            locale={locale}
                            toggleCalendar={toggleCalendar}
                            separator={separator}
                            format={format}
                            disableWeekDays={disableWeekDays || []}
                            tooltipBackground={tooltipBackground}
                        />
                    </div>
                    {/* </TrapFocus> */}
                </Dropdown>
            </div>
        </Manager>
    );
};

export default Datepicker;
