import { RefObject, useEffect, useState } from 'react';

function useHasFocus(ref: RefObject<HTMLElement>) {
    const [state, setState] = useState(ref?.current === document.activeElement);

    useEffect(() => {
        const element = ref.current;

        if (!element) {
            return;
        }

        setState(element === document.activeElement);

        const handleFocus = () => setState(true);
        const handleBlur = () => setState(false);

        element.addEventListener('focus', handleFocus);
        element.addEventListener('blur', handleBlur);

        return () => {
            element.removeEventListener('focus', handleFocus);
            element.removeEventListener('blur', handleBlur);
        };
    }, [ref]);

    return state;
}

export default useHasFocus;
