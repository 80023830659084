export enum ValueType {
    Unknown = '',
    Text = 'text',
    LongText = 'longtext',
    Flow = 'flow',
    AddressBook = 'addressbook',
    Date = 'date',
    Time = 'time',
    DateTime = 'datetime',
    Boolean = 'boolean',
    Url = 'url',
    Money = 'money',
    Integer = 'integer',
    Double = 'double',
    MultipleString = 'multiplestring', // несколько значений, разделенных либо запятыми, либо символом перевода строки
    Year = 'year',
    NoSecDateTime = 'nosecdatetime',
    YesNo = 'yesno',
    Base64Text = 'base64text',
    FileSize = 'filesize',
}
