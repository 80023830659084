import React, { useCallback, useState } from 'react';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@utils/actions/IActionExecutor';

import './../actions.scss';
import { ActivityActionsService } from '@services/actions/ActivityActionsService';
import Preloader from '@atoms/Preloader';
import Modal from '@atoms/Modal';
import { classnames } from '@utils/classnames';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Hint from '@atoms/Hint';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import Textarea from '@atoms/Textarea';
import { ModalSize } from '@atoms/Modal/Modal';

export class CompleteExecutor implements IActionExecutor {
    private _model?: ICompleteActionDialogProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        let act = action as IActivityAction;
        //let parts = objId.split('_');
        this._model = {
            displayName: action.displayName,
            actionKey: action.key ?? '',
            actId: getActivityIdFromCompositeId(objId),
            docId: act.docId,
            commentIsRequired: action.options?.commentIsRequired ?? false,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'm',
            completeHandler: completeHandler,
        };
    };

    visualElement = () => {
        return this._model ? <CompleteModal {...this._model} /> : <></>;
    };
}

interface ICompleteActionDialogProps {
    displayName: string;
    actId: string;
    actionKey: string;
    docId: string;
    commentIsRequired: boolean;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const CompleteModal: React.FC<ICompleteActionDialogProps> = (props: ICompleteActionDialogProps) => {
    const [comment, setComment] = useState<string>();
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const service = new ActivityActionsService();

    const onSubmit = useCallback(() => {
        setLoading(true);

        // Сохранение данных
        service
            .activityComplete(props.actionKey, props.actId, comment)
            .then(() => {
                props.completeHandler && props.completeHandler(true);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setLoading(false);
            });
    }, [comment]);

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>
                            <span>{props.displayName}</span>
                        </div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props?.completeHandler) props?.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {loading ? (
                <Preloader size="m" />
            ) : (
                <div className="actForm">
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    <Textarea
                        className={classnames(
                            'action-modal-dlg-input',
                            props.commentIsRequired && (comment == undefined || comment.length == 0) && 'invalid',
                        )}
                        placeholder={'Комментарий' + (props.commentIsRequired ? ' (обязательно)' : '')}
                        rows={6}
                        name="commentText"
                        required={props.commentIsRequired}
                        onChange={(e: any) => {
                            setComment(e.target.value);
                        }}
                    />

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                onClick={onSubmit}
                                size="s"
                                aria-label={props.okButtonText}
                                disabled={props.commentIsRequired && (comment == undefined || comment?.length == 0)}
                            >
                                {props.okButtonText}
                            </Button>
                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.okButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
