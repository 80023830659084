import { ModalSize } from '@atoms/Modal/Modal';

export interface ITableDemand {
    offers: IOffer[];
    data: ITableDemandItem[];
    settings: ITableDemandSettings;
}

export interface ITableDemandItem {
    critId: string;
    category: string;
    critName: string;
    critWeight: number;
    isInfoCrit: boolean;
    offers: Record<string, IOfferCrit>;
}

export interface IOfferCrit {
    description: string;
    score: number | null;
    rating: number | null;
    allowEdit: boolean;
}

export interface IOffer {
    key: string;
    type: string;
    name: string;
    number: string;
    rank: string;
    result: string;
    acceptanceResult: number;
    acceptanceComment: string;
    approveToStageTwo: number | null;
    price: number | null;
    currencyCode: string;
    currencyText: string;
    summaryScore: number | null;
    actions: IOfferAction[];
}

export interface IOfferAction {
    key: string;
    displayName: string;
    title: string;
    modalSize: ModalSize;
    type: keyof typeof OfferActionTypes;
    visible: boolean;
}

export interface ITableDemandSettings {
    emptyTextHeader: string;
    emptyText: string;
    offerCritDisabledHint: string;
    categorySortOrder: string[];
    priceVisible: boolean;
    editOfferCritButtonName: string | null;
}

export enum OfferActionTypes {
    /// <summary>
    /// Открыть предложение
    /// </summary>
    OpenOffer,
    /// <summary>
    /// Скачать документацию
    /// </summary>
    DownloadAttachments,
    /// <summary>
    /// Оценить
    /// </summary>
    RateOffer,
    /// <summary>
    /// Технический анализ
    /// </summary>
    TechAnalysisOffer,
    /// <summary>
    /// Отображение кастомной формы документа
    /// </summary>
    CustomForm,
    /// <summary>
    /// Допустить ко второму этапу
    /// </summary>
    ApproveToStageTwo,
}
