import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IFileInfo } from '@models/response/IFileInfo';
import { IDataGridDescriptionResponse } from '@models/response/IDataGridDescriptionResponse';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import { IGridResponse } from '@models/response/IGridResponse';
import { getLoadOptionsQuery } from '@utils/dataGridUtils';
import { IDataGridService } from './IDataGridService';
import { IDocumentResult } from '@/models/document/IDocumentResult';

export class DocumentRoutesMapService extends IDataGridService {
    async fetchScheme(data: any): Promise<AxiosResponse<IDataGridDescriptionResponse, any>> {
        return $api.get<IDataGridDescriptionResponse>(`/document/routesMapScheme/${data}`);
    }
    putData(data: any): Promise<AxiosResponse<IDocumentResult, any>> {
        throw new Error('Method not implemented.');
    }
    async fetchDetailData(data: any, detailData: any, loadOptions: any): Promise<AxiosResponse<IGridResponse, any>> {
        throw new Error('Method not implemented.');
    }
    async fetchData(data: any, loadOptions: any): Promise<AxiosResponse<IGridResponse, any>> {
        return $api.get<IGridResponse>(`/document/routesMapData/${data}${loadOptions}`);
    }

    async exportData(
        template: string,
        options: any,
        columns: IGridExportSettings[],
    ): Promise<AxiosResponse<IFileInfo>> {
        return $api.post<IFileInfo>(`/document/export?template=${template}&${getLoadOptionsQuery(options)}`, columns);
    }
}
