import { createContext, useContext } from 'react';

interface ISelectContextValue {
    query: string;
}

export const SelectContext = createContext<ISelectContextValue | null>(null);

export const useSelectContext = () => {
    const value = useContext(SelectContext);

    if (!value) {
        throw new Error('No provider for SelectContext.');
    }

    return value;
};
