import './RegisterModalExecutor.scss';
import React, { useEffect, useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';
import { ModalSize } from '@atoms/Modal/Modal';
import { IDocumentModalState } from '@/store/reducers/DocumentReducer';
import { DocumentService } from '@services/DocumentService';
import DocumentModal from '@organisms/DocumentModal';
import { IDocumentData } from '@models/document/IDocumentData';
import { useNavigate } from 'react-router-dom';

export class RegisterModalExecutor extends DocBaseActionExecutor {
    private _modalProps?: IRegisterModalExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            saveButtonText: action.options?.saveButtonText ?? 'Сохранить',
            modalSize: modalSize ?? action.options?.modalSize ?? 'max',
            openInNewTab: action.options?.openInNewTab ?? false,
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <RegisterModalExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface IRegisterModalExecutorProps {
    docId: string;
    actionKey: string;
    saveButtonText: string;
    modalSize: ModalSize;
    openInNewTab: boolean;
    completeHandler?: (isSucceed: boolean) => void;
}

const RegisterModalExecutorModal: React.FC<IRegisterModalExecutorProps> = (props: IRegisterModalExecutorProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [documentState, setDocumentState] = useState<IDocumentModalState>();

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            DocumentService.getSchemeByAction(props.actionKey),
            DocumentService.getDataByAction(props.actionKey),
        ]).then((values) => {
            const scheme = values[0];
            const data = values[1];
            setDocumentState({
                scheme: scheme.data,
                data: data.data,
            });
            setIsLoading(false);
        });
    }, []);

    const onSaveDocumentModal = async (data: IDocumentData) => {
        const result = await DocumentService.saveData(data);

        if (props.openInNewTab) {
            window.open(`./document/${result.data.id}`, '_blank');
        } else {
            navigate('/document/' + result.data.id);
        }

        props.completeHandler && props.completeHandler(true);
    };

    const onCancelDocumentModal = () => {
        props.completeHandler && props.completeHandler(false);
    };

    return (
        <DocumentModal
            scheme={documentState?.scheme}
            data={documentState?.data}
            modalSize={props.modalSize}
            preloaderSize={'m'}
            saveButtonText={props.saveButtonText}
            isLoading={isLoading}
            isEdit={true}
            isNew={true}
            onSave={onSaveDocumentModal}
            onCancel={onCancelDocumentModal}
            fullHeight={false}
        />
    );
};
