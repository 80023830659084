import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { BaseActionsService } from './BaseActionsService';
import { LinkedDocsTypes } from '@models/actions/LinkedDocsTypes';
import { RemoveLinkExecutor } from '@utils/actions/docExecutors/RemoveLink/RemoveLinkExecutor';
import { DocActionsExecFactory } from '@utils/actions/DocActionsExecFactory';
import { LocationTypes } from '@models/actions/LocationTypes';
import { DocumentActionsService } from '@services/actions/DocumentActionsService';
import { getDocIdFromLinkedDocId, getParentDocIdFromLinkedDocId } from '@utils/helpers';

export class LinkedDocsActionsService extends BaseActionsService {
    public allExecutors: { [actType: number]: () => IActionExecutor } = {
        [LinkedDocsTypes[LinkedDocsTypes.RemoveLink]]: () => new RemoveLinkExecutor(),
    };

    constructor() {
        super();
        for (let execKey in DocActionsExecFactory.allExecutors) {
            this.allExecutors[execKey] = DocActionsExecFactory.allExecutors[execKey];
        }
    }

    //Получает действия с поисковой папки
    async fetchActions(linkedDocId: string): Promise<AxiosResponse<IBaseAction[]>> {
        let parentId = getParentDocIdFromLinkedDocId(linkedDocId);
        let docId = getDocIdFromLinkedDocId(linkedDocId);
        return $api.get<IBaseAction[]>(`document/linkedDocActions/${docId}/${parentId}`);
    }

    //Получает групповые действия с поисковой папки
    async fetchGroupActions(linkedDocIds: string): Promise<AxiosResponse<IBaseAction[]>> {
        let ids = linkedDocIds.split(',');
        let docIds = ids.map((id) => getDocIdFromLinkedDocId(id)).join(',');
        return DocumentActionsService.fetchGroupActionsByLocation(docIds, LocationTypes.DocumentLinks);
    }
}
