import './FilePreviewButton.scss';
import React, { useState } from 'react';
import { IFileViewerModalProps } from '@organisms/FileViewerModal/FileViewerModal';
import FileViewerModal from '@organisms/FileViewerModal';
import Tooltip from '@atoms/Tooltip';
import Button from '@atoms/Button';
import { classnames } from '@utils/classnames';
import { MdOutlineFindInPage } from 'react-icons/md';

export interface IFilePreviewButtonProps extends IFileViewerModalProps {}

const FilePreviewButton = ({ ...props }: IFilePreviewButtonProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const onClick = (e: any) => {
        setShowModal(!showModal);
    };

    return (
        <div className={classnames('file-preview-button')}>
            <Tooltip openDelay={100} background="black" position="bottom">
                <Button
                    buttonType="secondary"
                    size="xs"
                    aria-label="Просмотреть файл"
                    onClick={onClick}
                    startAdornment={<MdOutlineFindInPage size="24" />}
                ></Button>
                {'Предпросмотр файла'}
            </Tooltip>
            {showModal ? (
                <FileViewerModal
                    {...props}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
            ) : null}
        </div>
    );
};

export default FilePreviewButton;
