import React, { FC, ReactNode } from 'react';
import Button from '@atoms/Button';
import Folders from '@molecules/Folders/Folders';
import { MdMenu, MdMenuOpen } from 'react-icons/md';
import { useActions } from '@hooks/useActions';
import { useTypedSelector } from '@hooks/useTypedSelector';
import Notifications from '@molecules/Notifications';
import Errors from '@molecules/Errors';
import Tooltip from '@atoms/Tooltip';
import { Helmet } from 'react-helmet-async';
import ReleaseNotes from '@/components/molecules/ReleaseNotes/ReleaseNotes';

export interface ILayoutMainProps {
    /** Наполнение */
    children: ReactNode | ReactNode[];
    /** Панель вверху */
    header?: ReactNode;
    /** Левое меню */
    sidebar?: ReactNode;
}

const LayoutMain: FC<ILayoutMainProps> = ({ children, header, sidebar }: ILayoutMainProps) => {
    //const [showMenu, toggleMenu] = useState<boolean>(true);
    const mainLayout = useTypedSelector((state) => state.mainLayout);

    const { toggleMenu } = useActions();

    const openClass = mainLayout.isMenuOpen ? 'sidebar--open' : 'sidebar--close';
    const openContainerClass = mainLayout.isMenuOpen ? 'content--open' : 'content--close';
    const toggleClick = () => {
        toggleMenu(!mainLayout.isMenuOpen);
    };

    return (
        <>
            <Helmet>
                <meta
                    name="viewport"
                    content={
                        // Для устройств с маленьким экраном устанавливаем фиксированный вьюпорт, чтобы вертска не ломалась
                        window.screen.width < 1280
                            ? 'width=1280'
                            : 'width=device-width, initial-scale=1, shrink-to-fit=no'
                    }
                />
            </Helmet>
            <header>
                <div className="header-navbar">
                    <Tooltip openDelay={100} background="black" position="bottom">
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="m"
                            onClick={toggleClick}
                            className="menu-toggle-button"
                            startAdornment={mainLayout.isMenuOpen ? <MdMenuOpen size="24" /> : <MdMenu size="24" />}
                        ></Button>
                        {mainLayout.isMenuOpen ? 'Скрыть навигацию' : 'Показать навигацию'}
                    </Tooltip>
                    {header}
                </div>
            </header>

            <div className="main-container">
                <aside className={`sidebar ${openClass}`}>
                    <Folders isOpen={mainLayout.isMenuOpen} />
                </aside>
                <main className={`content ${openContainerClass}`}>
                    <div className="main-box">
                        <div className="notes">
                            <ReleaseNotes />
                        </div>
                        <div className="main-content">{children}</div>
                    </div>
                </main>
                <Notifications />
                <Errors />
            </div>
        </>
    );
};

export default LayoutMain;
