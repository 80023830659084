import React, { ReactNode } from 'react';
import Input, { IInputProps } from '@atoms/Input/Input';
import { IControlProps, IPartialFormGroup } from '@controls/types';
import FormControl from '../FormControl';
import { useHookFormController } from '../HookFormProvider/HookFormProvider';

export type IInputControlProps = IInputProps &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        tooltip?: ReactNode;
        required?: boolean;
        replaceOnValueChange?: boolean;
    };

const InputControl = ({
    name,
    rules,
    defaultValue,
    control,
    formState,
    shouldUnregister,
    label,
    tooltip,
    required,
    formGroupProps,
    replaceOnValueChange = true,
    ...props
}: IInputControlProps) => {
    const Controller = useHookFormController();

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ...fieldProps } }) => (
                <FormControl
                    name={name}
                    label={label}
                    tooltip={tooltip}
                    required={required}
                    formState={formState}
                    {...formGroupProps}
                >
                    <Input
                        {...fieldProps}
                        {...props}
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let val = e.target.value;
                            if (value === undefined || value === null || value.toString() != val.toString()) {
                                onChange(val);
                                if (props.onValueChange) {
                                    if (replaceOnValueChange) {
                                        props.onValueChange(+val.replace(/\s/g, ''));
                                    } else {
                                        props.onValueChange(val);
                                    }
                                }
                            }
                        }}
                    />
                </FormControl>
            )}
        />
    );
};

export default InputControl;
