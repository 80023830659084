import { IOption } from '@/types';

export enum DueTimeModeType {
    //Бессрочное
    Indefinite,
    //Срок
    TimeSpan,
    //Дата
    Date,
}

const DueTimeModeTypeOption: IOption[] = [
    {
        label: 'Бессрочное',
        value: DueTimeModeType[DueTimeModeType.Indefinite],
    },
    {
        label: 'Срок',
        value: DueTimeModeType[DueTimeModeType.TimeSpan],
    },
    {
        label: 'Дата',
        value: DueTimeModeType[DueTimeModeType.Date],
    },
];

export default DueTimeModeTypeOption;
