import { ActFinishType } from '@services/actions/ActivityActionsService';
import { BaseApproveExecutor } from './BaseApproveExecutor';

export class ApproveExecutor extends BaseApproveExecutor {
    constructor() {
        super();

        this.approveModel = {
            commentIsRequired: false,
            actType: ActFinishType.Approve,
        };
    }
}
