import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { IFileData } from '@/types';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import InputFile from '@atoms/InputFile';
import { checkFilesSize, formatBytes, getAcceptExtensionString } from '@utils/helpers';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { MAX_FILE_SIZE } from '@utils/constants';
import Hint from '@/components/atoms/Hint';

export interface IAttachFilesBlockRefActions {
    getData: () => ISelectedFiles;
    setData: (data: ISelectedFiles) => void;
    isValid: () => boolean;
}

export interface IAttachFilesBlockProps {
    id?: string;
    model: IAttachesCreateModel;
    onChanged?: (data: ISelectedFiles) => void;
}

export interface ISelectedFiles {
    attachProperties: any[];
    files: File[];
}

export const AttachFilesBlock = forwardRef<IAttachFilesBlockRefActions | null, IAttachFilesBlockProps>(
    (props: IAttachFilesBlockProps, ref) => {
        const [errorText, setErrorText] = useState<string>();
        const [dataSourceFiles, setDataSourceFiles] = useState<any[]>([]);
        const [deleteFiles, setDeleteFiles] = useState<any[]>([]);
        const [binaryFiles, setBinaryFiles] = useState<{ [fileId: string]: File }>({});
        const [filesSizeExceeded, setFilesSizeExceeded] = useState<boolean>(false);

        useEffect(() => {
            if (filesSizeExceeded)
                setErrorText(
                    `Превышен максимальный размер загружаемых файлов (${formatBytes(MAX_FILE_SIZE! * 1024)}).`,
                );
            else setErrorText(undefined);
        }, [filesSizeExceeded]);

        useEffect(() => {
            onRowUpdated();
        }, [deleteFiles, dataSourceFiles]);

        const onRowUpdated = () => {
            gridRef.current?.instance.saveEditData();
            let data = getUpdatedFiles();
            props.onChanged && props.onChanged(data);
        };

        const getUpdatedFiles = (): ISelectedFiles => {
            let binaryFilesToUpload = dataSourceFiles.map((fileData) => {
                let f = binaryFiles[fileData._fileId];
                //delete fileDataClone._fileId;
                return f;
            });

            let filesDataToUpload = dataSourceFiles.map((x) => {
                let clone = { ...x };
                //delete clone._fileId;
                return clone;
            });
            deleteFiles.forEach((delItem) => {
                let clone = { ...delItem };
                clone._actType = 'delete';
                filesDataToUpload.push(clone);
            });

            return {
                attachProperties: filesDataToUpload,
                files: binaryFilesToUpload,
            };
        };

        useImperativeHandle(ref, () => ({
            getData: (): ISelectedFiles => {
                gridRef.current?.instance.saveEditData();
                return getUpdatedFiles();
            },
            setData: (data: ISelectedFiles) => {
                setDataSourceFiles([...data.attachProperties]);
            },
            isValid: (): boolean => {
                setErrorText(undefined);
                return checkFilesForRestrictions();
            },
        }));

        const setFile = (files: IFileData[]) => {
            let newBinaryFiles = { ...binaryFiles };

            if (files && props?.model?.properties && props?.model?.categories) {
                for (var i = 0; i < files.length; i++) {
                    let f = files[i].file;

                    let fileGuid = uuidv4();

                    //записываем в словарь
                    if (newBinaryFiles && f) {
                        newBinaryFiles[fileGuid] = f;
                    }

                    let fileObj: any = {
                        _fileId: fileGuid,
                        _actType: 'add',
                        _filename: f.name,
                        _category_: props?.model?.categories[0]?.name,
                    };
                    for (var j = 0; j < props?.model?.properties?.length; j++) {
                        let col = props?.model?.properties[j];
                        let keyName = col.key;

                        switch (keyName) {
                            case 'AttachmentFileName':
                                fileObj[keyName] = f?.name;
                                break;
                            default:
                                let foundDefaultValue = props?.model?.defaultValues?.find((x) => x.attr == keyName);
                                if (foundDefaultValue) {
                                    //значение по умолчанию
                                    fileObj[keyName] = foundDefaultValue.val;
                                } else {
                                    //если поле словарное - берем первое занчение из соотвествующего словаря
                                    if (
                                        props?.model.dictionaries &&
                                        col.dictName &&
                                        props?.model.dictionaries[col.dictName]
                                    ) {
                                        if (props?.model.dictionaries[col.dictName].length > 0)
                                            fileObj[keyName] = props?.model.dictionaries[col.dictName][0].code;
                                    } else {
                                        fileObj[keyName] = undefined;
                                    }
                                }
                        }
                    }

                    if (props.model?.defaultValues) {
                        let customProps = props.model?.defaultValues.filter((x) =>
                            x.attr.startsWith('_custom_property_'),
                        );
                        customProps.forEach((customProp) => {
                            let customVal = customProp.val;

                            switch (customProp.val) {
                                case '@guid()':
                                    customVal = uuidv4();
                                    break;
                            }

                            fileObj[customProp.attr] = customVal;
                        });
                    }

                    setDataSourceFiles((dataSourceFiles) => [...dataSourceFiles, fileObj]);
                }

                setBinaryFiles(newBinaryFiles);
            }

            gridRef.current?.instance.refresh();

            if (
                !checkFilesSize(Object.values<File>(newBinaryFiles), MAX_FILE_SIZE ? MAX_FILE_SIZE * 1024 : undefined)
            ) {
                setFilesSizeExceeded(true);
            } else {
                setFilesSizeExceeded(false);
            }
        };

        const checkFilesForRestrictions = (): boolean => {
            let extErrors: string[] = [];
            if (dataSourceFiles) {
                dataSourceFiles.forEach((f) => {
                    let fCat = f._category_;
                    let fExt = f.AttachmentFileName?.split('.')?.pop()?.toLowerCase();
                    let foundCat = props?.model?.categories.find((x) => x.name == fCat);
                    if (fExt && foundCat && foundCat.allowedExtensions) {
                        if (!foundCat.allowedExtensions.split(',').some((x) => x.trim().toLowerCase() == fExt)) {
                            extErrors.push(
                                `Файл '${f.AttachmentFileName}' не может находиться в категории '${foundCat.name}'`,
                            );
                        }
                    }

                    if (props?.model?.restrictions) {
                        props?.model?.restrictions.forEach((r) => {
                            if (f[r.ifAttachAttrName] == r.ifAttachAttrValue) {
                                //если условие, применять ли проверку - сработало, начинаем саму проверку

                                //Если не совпадает или заданный атрибут или перечень расширений - ругаемся
                                if (
                                    f[r.checkAttrName] != r.checkAttrValue ||
                                    !r.checkExtensions.split(',').some((x) => x.toLowerCase() == fExt) ||
                                    (r.checkFileSizeInBytes && r.checkFileSizeInBytes < binaryFiles[f._fileId].size)
                                ) {
                                    extErrors.push(`Файл '${f.AttachmentFileName}' ${r.checkMessage}`);
                                }
                            }
                        });
                    }
                });
            }

            if (extErrors.length > 0) {
                setErrorText(extErrors.join('; '));
                return false;
            } else {
                return true;
            }
        };

        const gridRef = useRef<DataGrid>(null);
        return (
            <div data-testid={props.id ? `attach-files-block-${props.id}` : undefined}>
                {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                <div className="AttachFiles__AllowedExtensionsGroup">
                    <p className="AttachFiles__AllowedExtensions">
                        <span>Список допустимых форматов: </span>
                        {props?.model?.categories && props?.model?.categories?.length > 0 ? (
                            props?.model?.categories?.length > 1 ? (
                                <ul>
                                    {props?.model?.categories.map((cat) => (
                                        <li key={cat?.name}>
                                            <span>{cat?.name}:</span>
                                            <span>
                                                {cat?.allowedExtensions ? cat?.allowedExtensions : 'ограничений нет'}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <span>
                                    {props?.model?.categories[0].allowedExtensions
                                        ? props?.model?.categories[0].allowedExtensions
                                        : 'ограничений нет'}
                                </span>
                            )
                        ) : (
                            <span>ограничений нет</span>
                        )}
                    </p>
                </div>
                <InputFile
                    className="AttachFiles__SelectFilesInput"
                    setFile={setFile}
                    placeholder="Выбрать файл..."
                    maxSize={MAX_FILE_SIZE}
                    multiple={true}
                    showChips={false}
                    clearFilesAfterSet={true}
                    accept={
                        props?.model?.categories && props?.model?.categories?.length > 0
                            ? props?.model?.categories.find(
                                  (cat) => cat?.allowedExtensions == null || !cat?.allowedExtensions?.trim(),
                              )
                                ? '*' // если среди категорий есть категория без ограничений
                                : getAcceptExtensionString(
                                      props?.model?.categories
                                          .map((cat) => cat?.allowedExtensions)
                                          .filter((ext) => ext)
                                          .join(','), // конкатенируем допустимые категории
                                  )
                            : '*'
                    }
                />

                <DevExpressDataGrid
                    dataSource={dataSourceFiles}
                    remoteOperations={true}
                    hoverStateEnabled={true}
                    columnHidingEnabled={false}
                    showColumnHeaders={true}
                    columnAutoWidth={true}
                    allowColumnReordering={false}
                    allowColumnResizing={true}
                    columnResizingMode="widget"
                    noDataText={'Файлы не выбраны'}
                    rowAlternationEnabled={true}
                    ref={gridRef}
                    onRowUpdated={() => {
                        onRowUpdated();
                    }}
                    onRowRemoved={(e) => {
                        let removedFileId = e.key._fileId;
                        let newBinaryFiles = { ...binaryFiles };
                        if (removedFileId && uuidValidate(removedFileId) && binaryFiles[removedFileId]) {
                            delete newBinaryFiles[removedFileId];
                            setBinaryFiles(newBinaryFiles);
                        }
                        setDataSourceFiles([...dataSourceFiles]);
                        if (!uuidValidate(removedFileId)) {
                            setDeleteFiles((deleteFiles) => [...deleteFiles, e.key]);
                        }

                        if (
                            !checkFilesSize(
                                Object.values<File>(newBinaryFiles),
                                MAX_FILE_SIZE ? MAX_FILE_SIZE * 1024 : undefined,
                            )
                        ) {
                            setFilesSizeExceeded(true);
                        } else {
                            setFilesSizeExceeded(false);
                        }
                    }}
                    onCellHoverChanged={onCellHoverChanged}
                >
                    <Editing
                        mode="cell"
                        allowUpdating={true}
                        allowAdding={false}
                        allowDeleting={true}
                        confirmDelete={false}
                    />

                    {props?.model?.properties?.map((col, i) => {
                        return (
                            <Column
                                key={`col_${i}`}
                                allowFiltering={true}
                                caption={col.displayName}
                                dataField={col.key}
                                dataType={'string'}
                                visible={true}
                                allowSorting={false}
                                filterOperations={['contains']}
                                showEditorAlways={true}
                            >
                                {props?.model?.dictionaries &&
                                    col.dictName &&
                                    props?.model?.dictionaries[col.dictName] && (
                                        <Lookup
                                            dataSource={props?.model.dictionaries[col.dictName]}
                                            displayExpr="code"
                                            valueExpr="code"
                                        />
                                    )}
                            </Column>
                        );
                    })}

                    {/*Отображаем столбец с аттачами только когда категорий больше чем одна*/}
                    {(props?.model?.categories?.length ?? 0) > 1 && (
                        <Column
                            key={'col_category'}
                            allowFiltering={true}
                            caption={'Категория'}
                            dataField={'_category_'}
                            dataType={'string'}
                            visible={true}
                            allowSorting={false}
                            filterOperations={['contains']}
                            showEditorAlways={true}
                        >
                            <Lookup dataSource={props?.model?.categories} displayExpr="name" valueExpr="name" />
                        </Column>
                    )}
                </DevExpressDataGrid>
            </div>
        );
    },
);
