import React, { useCallback, useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/IActionExecutor';

import './BatchActivitiesCompleteExecutor.scss';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import Modal from '@atoms/Modal';
import { classnames } from '@utils/classnames';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import Textarea from '@atoms/Textarea';
import { ModalSize } from '@atoms/Modal/Modal';

export class BatchActivitiesCompleteExecutor extends DocBaseActionExecutor {
    private _modalProps?: IBatchActivitiesCompleteExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'm',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <BatchActivitiesCompleteExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface IBatchActivitiesCompleteExecutorProps {
    docId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const BatchActivitiesCompleteExecutorModal: React.FC<IBatchActivitiesCompleteExecutorProps> = (
    props: IBatchActivitiesCompleteExecutorProps,
) => {
    const [comment, setComment] = useState<string>();
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const service = new DocumentExecutionService(props.docId);

    const onSubmit = useCallback(() => {
        setLoading(true);

        // Сохранение данных
        service
            .batchActivitiesComplete(props.actionKey, comment)
            .then(() => {
                props.completeHandler && props.completeHandler(true);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setLoading(false);
            });
    }, [comment]);

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>
                            <span>{props.title}</span>
                        </div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props?.completeHandler) props?.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {loading ? (
                <Preloader size="m" />
            ) : (
                <div className="actForm">
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    <Textarea
                        className="action-modal-dlg-input"
                        placeholder="Комментарий"
                        rows={6}
                        name="commentText"
                        onChange={(e: any) => {
                            setComment(e.target.value);
                        }}
                    />

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button onClick={onSubmit} size="s" aria-label={props.okButtonText}>
                                {props.okButtonText}
                            </Button>
                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
