import DataGrid, { Column, Paging, Scrolling, Selection } from 'devextreme-react/data-grid';
import React, { FC, useMemo, useRef, useState } from 'react';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import CustomStore from 'devextreme/data/custom_store';
import { AddressBookService } from '@services/AddressBookService';
import { IAbookTab } from '@models/Forms/IForms';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import { InitializedEvent as GridInitializedEvent } from 'devextreme/ui/data_grid';
import { simulateMouseClick } from '@utils/helpers';
import { IAbookSettings } from '@atoms/Abookpicker/AbookpickerModal/AbookpickerModal';
import Input from '@atoms/Input';
import './SearchModeControl.scss';
import { IDebounceResult } from '@/types/projects.types';
import { onCellHoverChanged } from '@utils/dataGridUtils';

export interface ISearchModeControlProp extends Omit<IAbookSettings, 'showChips'> {
    currentTab: IAbookTab;
    searchApiUrl: string;
    onSelectedRowItems: (value: IAddressBookData[]) => void;
    onSelectedKeys: (value: number[]) => void;
    getParams: (options: any) => string;
}

const SearchModeControl: FC<ISearchModeControlProp> = (p: ISearchModeControlProp) => {
    const gridRef = useRef<DataGrid>(null);

    const [searchText, setSearchText] = useState<string>('');

    const resetGridControlState = () => {
        gridRef?.current?.instance.state({});
    };

    const resetControlsState = () => {
        resetGridControlState();
    };

    const gridStore = useMemo(() => {
        resetControlsState();
        return new CustomStore({
            key: 'key',
            load(options: any) {
                return AddressBookService.fetchExternalSearch(
                    p.searchApiUrl,
                    searchText === undefined || searchText === '' ? 'null' : searchText,
                    p.getParams(options),
                ).then((response) => {
                    return response.data;
                });
            },
        });
    }, [searchText]);

    const onGridInitialized = (e: GridInitializedEvent<IAddressBookData, string>) => {
        if (p.selected) {
            e.component?.selectRows(
                p.selected.map((key: number) => key.toString()),
                true,
            );
        }
    };

    const onGridEditorPreparing = (e: any) => {
        if (e.parentType === 'filterRow') {
            e.editorOptions.onEnterKey = function () {
                // применение фильтра по нажатию Enter
                simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
            };
        }
    };

    const onSelectionChanged = (e: any) => {
        if (!p.isMultiple) {
            if (e.currentSelectedRowKeys.length > 0) {
                let data = e.selectedRowsData.pop();
                let key = e.selectedRowKeys.pop();
                e.component.deselectRows(e.selectedRowKeys);
                p.onSelectedKeys([key]);
                p.onSelectedRowItems([data]);
            } else if (e.selectedRowKeys.length == 0) {
                p.onSelectedKeys([]);
                p.onSelectedRowItems([]);
            }
        } else {
            p.onSelectedKeys(e.selectedRowKeys!);
            p.onSelectedRowItems(e.selectedRowsData);
        }
    };
    const onRowClick = (e: any) => {
        const keys = e.component.getSelectedRowKeys();
        const index = keys.indexOf(e.key);
        if (index > -1) {
            keys.splice(index, 1);
        } else {
            keys.push(e.key);
        }
        e.component.selectRows(keys);
    };

    const search = (val: IDebounceResult) => {
        setSearchText(val.debounceString);
    };

    const gridControl = (
        <DevExpressDataGrid
            key="abooksearchGrid"
            showRowLines={true}
            columnAutoWidth={true}
            columnMinWidth={30}
            dataSource={gridStore}
            remoteOperations={true}
            hoverStateEnabled={true}
            columnHidingEnabled={false}
            showColumnHeaders={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            ref={gridRef}
            onInitialized={onGridInitialized}
            onSelectionChanged={onSelectionChanged}
            onEditorPreparing={onGridEditorPreparing}
            onRowClick={onRowClick}
            onCellHoverChanged={onCellHoverChanged}
        >
            <Selection mode="multiple" allowSelectAll={p.isMultiple} selectAllMode="page" />

            {p.currentTab.columns &&
                p.currentTab.columns.map((column, index) => {
                    return <Column caption={column.name} key={index} dataField={column.key} width={column.width} />;
                })}

            {/* <FilterRow visible={true} applyFilter={'onClick'} /> */}
            <Scrolling mode="virtual" />
            <Paging enabled={true} defaultPageSize={10} />
        </DevExpressDataGrid>
    );

    return (
        <div className="abool-container-search">
            <div className="abool-container-search-input w100">
                <Input placeholder="Введите ФИО для поиска" debounce={150} onDebounce={search} />
            </div>
            <div className="w100">{gridControl}</div>
        </div>
    );
};

export default SearchModeControl;
