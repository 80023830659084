import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IFilesService } from './IFilesService';

export class FilesService extends IFilesService {
    public downloadFileByGuid(fileGuid: string, fileName: string, completeHandler?: () => void): void {
        $api.get(`/files/download?fileGuid=${fileGuid}&fileName=${fileName}`, { responseType: 'blob' }).then(
            (file: AxiosResponse<any>) => {
                this.download(file);
                if (completeHandler) completeHandler();
            },
        );
    }

    //Скачивает файл с API
    public downloadFile(url: string): void {
        $api.get(`/${url}`, { responseType: 'blob' }).then((file: AxiosResponse<any>) => {
            this.download(file);
        });
    }

    private download(file: AxiosResponse<any>) {
        const url = window.URL.createObjectURL(
            new Blob([file.data], { type: file.headers['Content-Type']?.toString() ?? '' }),
        );
        let contentDisposition = file.headers['content-disposition'];
        let filename = this.getFileName(contentDisposition);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }

    private getFileName(disposition: string): string {
        const utf8FilenameRegex = /filename([*]?)=UTF-8''([^\";]+)/i;
        const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

        let fileName: string = '';
        if (utf8FilenameRegex.test(disposition)) {
            let match = utf8FilenameRegex.exec(disposition);
            if (match) {
                fileName = decodeURIComponent(match[2]);
            }
        } else {
            const filenameStart = disposition.toLowerCase().indexOf('filename=');
            if (filenameStart >= 0) {
                const partialDisposition = disposition.slice(filenameStart);
                const matches = asciiFilenameRegex.exec(partialDisposition);
                if (matches != null && matches[2]) {
                    fileName = matches[2];
                }
            }
        }
        return fileName;
    }
}
