import { AppDispatch } from '@/store/index';
import { DictionarySlice } from '@/store/reducers/DictionaryReducer';
import { DictionariesService } from '@services/DictionariesService';
import { FavoritesService } from '@services/FavoritesService';
import { IField } from '@models/IFormData';
import { sendErrorMsg } from '@molecules/Errors';

export const DictionaryActionCreators = {
    fetchDictionaryScheme: (dictName: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(DictionarySlice.actions.setIsLoading(true));
            const response = await DictionariesService.fetchDictionaryScheme(dictName);
            dispatch(DictionarySlice.actions.successLoadDictionaryScheme(response.data));
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
            dispatch(DictionarySlice.actions.setIsLoading(false));
        }
    },
    updateDictionaryItem:
        (dictName: string, id: string, attributes: IField[], structIds: string[], callbackFn?: () => void) =>
        async (dispatch: AppDispatch) => {
            try {
                dispatch(DictionarySlice.actions.setIsLoading(true));
                const response = await DictionariesService.updateItem(dictName, id, attributes, structIds);
                callbackFn && callbackFn();
            } catch (e) {
                console.log(e);
                let error = e as string[];
                sendErrorMsg({
                    message: error,
                });
            } finally {
                dispatch(DictionarySlice.actions.setIsLoading(false));
            }
        },

    addDictionaryItem:
        (dictName: string, attributes: IField[], structIds: string[], callbackFn?: () => void, parentId?: string) =>
        async (dispatch: AppDispatch) => {
            try {
                dispatch(DictionarySlice.actions.setIsLoading(true));
                const response = await DictionariesService.addItem(dictName, attributes, structIds, parentId);
                callbackFn && callbackFn();
            } catch (e) {
                console.log(e);
                let error = e as string[];
                sendErrorMsg({
                    message: error,
                });
            } finally {
                dispatch(DictionarySlice.actions.setIsLoading(false));
            }
        },
    removeDictFromFavorites: (rootDictionaryId?: string, templateName?: string) => async (dispatch: AppDispatch) => {
        try {
            let response;
            if (rootDictionaryId && !templateName) {
                response = await FavoritesService.removeDictionaryFromFavorites(rootDictionaryId);
            } else {
                response = await FavoritesService.removeFormDataDictionaryFromFavorites(templateName!);
            }

            dispatch(DictionarySlice.actions.setIsFavorite(false));
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
        }
    },
    addDictToFavorites: (rootDictionaryId?: string, templateName?: string) => async (dispatch: AppDispatch) => {
        try {
            let response;
            if (rootDictionaryId && !templateName) {
                response = await FavoritesService.addDictionaryToFavorites(rootDictionaryId);
            } else {
                response = await FavoritesService.addFormDataDictionaryToFavorites(templateName!);
            }

            dispatch(DictionarySlice.actions.setIsFavorite(true));
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
        }
    },

    checkDictionaryIsFavorite: (dictionaryId?: string, template?: string) => async (dispatch: AppDispatch) => {
        try {
            let response;
            if (dictionaryId) {
                response = await FavoritesService.checkIsFavoriteDictionary(dictionaryId);
            } else if (template) {
                response = await FavoritesService.checkIsFavoriteFormDataDictionary(template);
            }
            if (response && response.data) dispatch(DictionarySlice.actions.setIsFavorite(response.data as boolean));
        } catch (e) {
            console.log(e);
            let error = e as string[];
            sendErrorMsg({
                message: error,
            });
        } finally {
        }
    },
};
